<template>
  <div>
    <header class="py-8">
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class=" text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.voice')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.voiceSettings')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">
      <div class="">
        <div class="">
          <div class="rounded-md bg-blue-50 p-4 mb-2">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: solid/information-circle -->
                <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                </svg>
              </div>
              <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-sm text-blue-700">
                  {{$t('ui.voiceSettingsChangeWarning')}}
                </p>
                <p class="mt-3 text-sm md:mt-0 md:ml-6">
                </p>
              </div>
            </div>
          </div>
          <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1 ">
            {{saveError}}
          </div>
          <div class="flex justify-between items-center mb-2">
            <div class="voices text-sm font-bold">
              {{$t('ui.addBlogAvailableVoices')}}
            </div>
          </div>
          <!-- SEARCH -->
          <div>
            <div class=" pb-2">
              <label for="search" class="sr-only">Search</label>
              <div class="relative">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
    <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
  </svg>
                </div>
                <div class="voice-filter text-sm absolute right-2 pt-px top-3">
                  {{$t('ui.gender')}}:
                  <select
                    v-model="voiceFilter.gender"
                    id="language" name="language" autocomplete="language"
                    class="border-0 p-0 w-auto min-w-[80px] font-bold capitalize text-xs focus:outline-none">
                    <option v-for="gender in genderList" :key="gender" :value="gender">{{$t('ui.' + gender)}}</option>
                  </select>
                </div>
                <input id="search" v-model="searchQuery" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" placeholder="Search" type="text">
              </div>
            </div>
          </div>
          <!-- voices -->
          <div class="voices max-h-80 pb-4">
            <div class="overflow-auto max-h-80 md:-mx-6 -mx-4 px-4 md:px-6">
              <div class="voice border-2 rounded-lg border-black px-1 py-1 mt-2 text-sm flex sm:h-11" v-for="(item, index) in filteredVoices" :key="item.createdAt">
                <div class="sm:flex justify-between w-full">
                  <div class="flex items-center space-x-2">
                    <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
                      <div @click="previewAudio(item, index)" class="play-pause rounded-md h-9 w-9 flex justify-center align-middle relative items-center -ml-0.5"
                        :class="{'bg-hm-brand text-black': playing === index, 'bg-black text-white': playing !== index}">
                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="15.999" viewBox="0 0 12 15.999" v-show="playing !== index">
                          <g id="triangle-right" transform="translate(0 0)">
                            <path id="Path_43134" data-name="Path 43134" d="M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z" transform="translate(-2 0)" fill="currentColor"/>
                          </g>
                        </svg>
                        <svg id="button-pause" v-show="playing === index" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
                          <rect id="Rectangle_2499" data-name="Rectangle 2499" width="4" height="12" rx="0.5" transform="translate(8)" fill="#212121"/>
                          <rect id="Rectangle_2500" data-name="Rectangle 2500" width="4" height="12" rx="0.5" fill="currentColor"/>
                        </svg>
                      </div>
                      <div
                        class="inline-block"
                        :class="{'animate-pulse text-hm-brand': playing === index}">{{item.displayName || item.name}}
                        <span class="space-x-2">
                          <span class="text-xs font-bold bg-yellow-500 inline-block text-white py-0 px-1 rounded" v-show="item.ttsVoiceEngine === 'neural'">HQ</span>
                          <span class="text-xs font-bold inline-block text-white py-0 px-1 rounded"
                          :class="{'bg-green-300 text-green-800': item.gender === 'female', 'bg-blue-300 text-blue-800': item.gender === 'male'}">{{$t(`ui.${item.gender}`)}}</span>
                        </span>
                      </div>
                  </div>
                  <div class="isSet space-x-2 text-xs items-center flex pr-2 mt-3 sm:mt-0">
                    <div class="relative flex items-start cursor-pointer" @click="titleVoice = item">
                      <div class="flex h-5 items-center">
                        <div class="h-4 w-4 rounded border-2 border-black text-black focus:ring-black">
                          <div class="h-2 w-2 rounded-sm mt-0.5 ml-0.5 bg-black" v-show="titleVoice === item"></div>
                        </div>
                      </div>
                      <div class="ml-3 text-sm">{{$t('ui.title')}}
                      </div>
                    </div>
                    <div class="relative flex items-start cursor-pointer" @click="contentVoice = item">
                      <div class="flex h-5 items-center">
                        <div class="h-4 w-4 rounded border-2 border-black text-black focus:ring-black">
                          <div class="h-2 w-2 rounded-sm mt-0.5 ml-0.5 bg-black" v-show="contentVoice === item"></div>
                        </div>
                      </div>
                      <div class="ml-3 text-sm">{{$t('ui.content')}}
                      </div>
                    </div>
                    <div class="relative flex items-start cursor-pointer" @click="contentAlternativeVoice = item">
                      <div class="flex h-5 items-center">
                        <div class="h-4 w-4 rounded border-2 border-black text-black focus:ring-black">
                          <div class="h-2 w-2 rounded-sm mt-0.5 ml-0.5 bg-black" v-show="contentAlternativeVoice === item"></div>
                        </div>
                      </div>
                      <div class="ml-3 text-sm">{{$t('ui.alternative')}}
                      </div>
                    </div>
                    <!-- <span class="cursor-pointer" v-show="titleVoice !== item" @click="titleVoice = item">{{$t('ui.setAsTitleVoice')}}</span>
                    <span class="flex" v-show="titleVoice === item">
                      <svg class="-mt-px mr-1" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 16 16">
                        <path id="Path_43305" data-name="Path 43305" d="M16,4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4v8a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4Zm-3.749.469a.869.869,0,0,1,.137,1.246L7.9,11.429a1.486,1.486,0,0,1-.937.629H6.7a1.417,1.417,0,0,1-.891-.32L3.76,10.1A.857.857,0,0,1,4.811,8.754L6.423,9.989a.274.274,0,0,0,.434-.046L11.04,4.571a.869.869,0,0,1,1.211-.1Z"/>
                      </svg>
                      {{$t('ui.titleVoice')}}
                    </span>
                    <span class="cursor-pointer" v-show="contentVoice !== item" @click="contentVoice = item">{{$t('ui.setAsContentVoice')}}</span>
                    <span class="flex" v-show="contentVoice === item">
                      <svg class="-mt-px mr-1" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 16 16">
                        <path id="Path_43305" data-name="Path 43305" d="M16,4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4v8a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4Zm-3.749.469a.869.869,0,0,1,.137,1.246L7.9,11.429a1.486,1.486,0,0,1-.937.629H6.7a1.417,1.417,0,0,1-.891-.32L3.76,10.1A.857.857,0,0,1,4.811,8.754L6.423,9.989a.274.274,0,0,0,.434-.046L11.04,4.571a.869.869,0,0,1,1.211-.1Z"/>
                      </svg>
                      {{$t('ui.titleVoice')}}
                    </span>
                    <span class="cursor-pointer" v-show="contentAlternativeVoice !== item" @click="contentAlternativeVoice = item">{{$t('ui.setAsAlternativeVoice')}}</span>
                    <span class="flex" v-show="contentAlternativeVoice === item">
                      <svg class="-mt-px mr-1" xmlns="http://www.w3.org/2000/svg"  width="16" height="16" viewBox="0 0 16 16">
                        <path id="Path_43305" data-name="Path 43305" d="M16,4a4,4,0,0,0-4-4H4A4,4,0,0,0,0,4v8a4,4,0,0,0,4,4h8a4,4,0,0,0,4-4Zm-3.749.469a.869.869,0,0,1,.137,1.246L7.9,11.429a1.486,1.486,0,0,1-.937.629H6.7a1.417,1.417,0,0,1-.891-.32L3.76,10.1A.857.857,0,0,1,4.811,8.754L6.423,9.989a.274.274,0,0,0,.434-.046L11.04,4.571a.869.869,0,0,1,1.211-.1Z"/>
                      </svg>
                      {{$t('ui.contentAlternativeVoice')}}
                    </span> -->
                  </div>
                </div>
              </div>
            </div>

          </div>

          <!-- selected voices -->
          <div class="sm:flex sm:justify-between items-center mt-5">
            <div class="text-xs">
              <div class="flex items-center">
                <div>
                  {{$t('ui.titleVoice')}}:
                </div>
                <div
                  v-show="titleVoice.displayName"
                  @click="previewAudio(titleVoice, -1)" class="ml-2 play-pause rounded-md h-5 w-5 flex justify-center align-middle relative items-center cursor-pointer"
                  :class="{'bg-hm-brand text-black': playing === -1, 'bg-black text-white': playing !== -1}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2" width="12" height="15.999" viewBox="0 0 12 15.999">
                    <g id="triangle-right" transform="translate(0 0)">
                      <path id="Path_43134" data-name="Path 43134" d="M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z" transform="translate(-2 0)" fill="currentColor"/>
                    </g>
                  </svg>
                </div>
                <div class="ml-1 font-bold">
                  {{titleVoice.displayName || titleVoice.name || $t('ui.notSelected')}}
                </div>
              </div>
              <div class="flex items-center">
                <div>
                  {{$t('ui.contentVoice')}}:
                </div>
                <div
                  v-show="contentVoice.displayName"
                  @click="previewAudio(contentVoice, -2)" class="ml-2 play-pause rounded-md h-5 w-5 flex justify-center align-middle relative items-center cursor-pointer"
                  :class="{'bg-hm-brand text-black': playing === -2, 'bg-black text-white': playing !== -2}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2" width="12" height="15.999" viewBox="0 0 12 15.999">
                    <g id="triangle-right" transform="translate(0 0)">
                      <path id="Path_43134" data-name="Path 43134" d="M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z" transform="translate(-2 0)" fill="currentColor"/>
                    </g>
                  </svg>
                </div>
                <div class="ml-1 font-bold">
                  {{contentVoice.displayName || contentVoice.name || $t('ui.notSelected')}}
                </div>
              </div>
              <div class="flex items-center">
                <div>
                  {{$t('ui.contentAlternativeVoice')}}:
                </div>
                <div
                  v-if="contentAlternativeVoice && contentAlternativeVoice.displayName"
                  @click="previewAudio(contentAlternativeVoice, -3)" class="ml-2 play-pause rounded-md h-5 w-5 flex justify-center align-middle relative items-center cursor-pointer"
                  :class="{'bg-hm-brand text-black': playing === -3, 'bg-black text-white': playing !== -3}">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-2 h-2" width="12" height="15.999" viewBox="0 0 12 15.999">
                    <g id="triangle-right" transform="translate(0 0)">
                      <path id="Path_43134" data-name="Path 43134" d="M14,8a1,1,0,0,0-.427-.819l-10-7A1,1,0,0,0,2,1V15a1,1,0,0,0,1.573.819l10-7A1,1,0,0,0,14,8V8Z" transform="translate(-2 0)" fill="currentColor"/>
                    </g>
                  </svg>
                </div>
                <div class="ml-1 font-bold">
                  {{contentAlternativeVoice.displayName || contentAlternativeVoice.name || $t('ui.notSelected')}}
                </div>
              </div>
            </div>
            <div class="text-hm-blue cursor-pointer text-xs my-3 sm:my-0" @click="setRecomended">
              {{$t('ui.setRecomended')}}
            </div>
          </div>

          <!-- save and go to payment -->
          <div class="text-right">
            <Button
              :loading="loading"
              :disabled="disabled"
              class="ml-3 inline-flex justify-center" @click.native="save">
              {{$t('ui.save')}}
            </Button>
          </div>
        </div>
        <div class="hidden">
          <audio ref="player" @ended="clearPlayer" controls="controls" id="audioPreview" style="display:none">
            <source :src="url" type="audio/mp3" />
          </audio>
        </div>
      </div>
      <!-- <form class="space-y-8 divide-y divide-gray-200">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.titleVoice')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 justify-self-end" :class="{loading: loadingLanguages}">
                  <CustomSelect :items="sortedVoices" :selected.sync="titleVoice">
                    <span slot="button">{{$t('ui.selectVoice')}}</span>
                  </CustomSelect>
                  <p class="text-xs text-blue-700 text-right mt-1" v-show="isTitleLanguageDifferent">
                    {{$t('ui.currentVoice')}} : {{titlePart.settings.ttsVoiceName}} (<span class="uppercase">{{titlePart.settings.mainLanguage.split('-')[0]}}</span>)
                  </p>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.contentVoice')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 justify-self-end" :class="{loading: loadingLanguages}">
                  <CustomSelect :items="sortedVoices" :selected.sync="contentVoice">
                    <span slot="button">{{$t('ui.selectVoice')}}</span>
                  </CustomSelect>
                  <p class="text-xs text-blue-700 text-right mt-1" v-show="isContentLanguageDifferent">
                    {{$t('ui.currentVoice')}} : {{contentPart.settings.ttsVoiceName}} (<span class="uppercase">{{contentPart.settings.mainLanguage.split('-')[0]}}</span>)
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-5">
            <div class="flex justify-end">
              <Button
                :loading="loading"
                :disabled="disabled"
                class="ml-3 inline-flex justify-center" @click.native="save">
                {{$t('ui.save')}}
              </Button>
            </div>
          </div>
        </div>
      </form> -->
       <pre class="break-words max-w-md" v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612'">
       sad
       <details>dsd
        sd
       </details>
        isTitleLanguageDifferent {{isTitleLanguageDifferent}}
        isContentLanguageDifferent {{isContentLanguageDifferent}}
        contentVoice : {{contentVoice}}
        contentAlternativeVoice: {{contentAlternativeVoice}}
       </pre>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      items: '',
      language: 'Polish',
      titleVoice: {},
      contentVoice: {},
      contentAlternativeVoice: {},
      selectedCode: 'en-US',
      loadingLanguages: false,
      loading: false,
      saveError: null,
      settingsCopy: null,

      voiceFilter: {
        gender: 'all',
        style: 'all',
      },

      genderList: [
        'all',
        'female',
        'male',
      ],

      selected: {},
      // player
      show: false,
      url: null,
      playing: null,

      searchQuery: '',
    };
  },
  computed: {
    ...mapState({
      selectedWebsite: (state) => state.website.selectedWebsite,
      voices: (state) => state.utils.voiceList,
      currentChannel: (state) => state.website.selectedWebsitePodcast,
      templates: (state) => state.website.selectedWebsitePodcastTemplates,
      userId: (state) => state.auth.userId,
      defaultVoices: (state) => state.utils.defaultVoices,
    }),
    voiceId() {
      return (item) => {
        const kind = item.ttsVoiceKind ? `-${item.ttsVoiceKind}` : '';
        return `${item.ttsVoiceName}-${item.mainLanguage}${kind}`;
      };
    },
    voiceUrl() {
      return (voice) => {
        const url = `${this.$appConfig.samplesUrl}/${voice.sampleFilename}`;
        return url;
      };
    },
    voicesWithId() {
      if (!this.voices) return [];
      return this.voices.map((el) => ({ ...el, name: el.id }));
    },
    defaultTemplate() {
      if (!this.templates || this.templates.length === 0) return { episodeTemplatePartsByEpisodeTemplateId: {} };
      // console.log('templates', this.templates);
      return this.templates[0].episodeTemplateByEpisodeTemplateId || {};
    },
    disabled() {
      if (!this.titleVoice || !this.contentVoice || !this.contentAlternativeVoice) return true;
      return !this.voiceId(this.titleVoice) || !this.voiceId(this.contentVoice) || !this.voiceId(this.contentAlternativeVoice);
    },
    websiteId() {
      return this.selectedWebsite ? this.selectedWebsite.id : null;
    },
    templeParts() {
      return this.defaultTemplate.episodeTemplatePartsByEpisodeTemplateId.nodes || [];
    },
    titlePart() {
      return this.templeParts.find((el) => el.displayName === 'Title');
    },
    contentPart() {
      return this.templeParts.find((el) => el.displayName === 'Content');
    },
    isTitleLanguageDifferent() {
      return this.titlePart && this.selectedCode !== this.titlePart.settings.mainLanguage;
    },
    isContentLanguageDifferent() {
      return this.contentPart && this.selectedCode !== this.contentPart.settings.mainLanguage;
    },
    sortedVoices() {
      return this.voices
        .slice()
        .sort((a, b) => parseInt(a.ordinalNumber, 10) - parseInt(b.ordinalNumber, 10));
    },
    filteredVoices() {
      return this.sortedVoices
        .filter((voice) => {
          console.log('voice => ', voice);
          if (voice.mainLanguage !== this.selectedCode) return false;
          const isSameGenger = this.voiceFilter.gender === 'all' || voice.gender === this.voiceFilter.gender;
          // const isSameStyle = this.voiceFilter.style === 'all' || voice.style !== this.voiceFilter.style;
          const query = this.searchQuery ? voice.displayName.toLowerCase().includes(this.searchQuery.toLowerCase()) : true;
          if (isSameGenger && query) return true;
          return false;
        });
    },
    voiceItemById() {
      return (voiceSettings) => {
        console.log('xxx');
        return this.voices.find((el) => {
          console.log('xxx');
          // const newId = `${el.id}-${el.isHQ}`;
          // const isHQ = voiceSettings.ttsVoiceEngine === 'neural';
          // const voiceSettngsId = `${voiceSettings.ttsVoiceName}-${isHQ}`;
          return this.voiceId(el) === this.voiceId(voiceSettings);
        });
      };
    },
    voiceAlternativeItemById() {
      return (voiceSettings) => {
        console.log('xxx');
        return this.voices.find((el) => {
          console.log('xxx');
          // const newId = `${el.id}-${el.isHQ}`;
          // const isHQ = voiceSettings.ttsVoiceEngine === 'neural';
          // const voiceSettngsId = `${voiceSettings.ttsVoiceName}-${isHQ}`;
          const alternativeVoice = {
            ...voiceSettings,
            ttsVoiceName: voiceSettings.alternateTtsVoiceName,
            ttsPlatform: voiceSettings.alternateTtsPlatform,
            ttsVoiceKind: voiceSettings.alternateTtsVoiceKind,
          };
          return this.voiceId(el) === this.voiceId(alternativeVoice);
        });
      };
    },
  },
  beforeDestroy() {
    Vue.set(this, 'settingsCopy', {});
  },
  watch: {
    // eslint-disable-next-line
    currentChannel: function() {
      this.resetSettings();
    },
    // eslint-disable-next-line
    contentPart: function(newVal) {
      this.contentVoice = this.voiceItemById(newVal.settings);
      this.contentAlternativeVoice = this.voiceAlternativeItemById(newVal.settings) || {};
      // this.selectedCode = newVal.settings.mainLanguage;
      this.selectedCode = this.selectedWebsite.language;
      this.loadVoices();
    },
    // eslint-disable-next-line
    titlePart: function(newVal) {
      this.titleVoice = this.voiceItemById(newVal.settings);
      // this.selectedCode = newVal.settings.mainLanguage;
      this.selectedCode = this.selectedWebsite.language;
      this.init();
    },
  },
  mounted() {
    // console.log('mounted VOICE', this.selectedCode, this.$appConfig);
    this.selectedCode = this.selectedWebsite.language;
    // if (this.titlePart) this.selectedCode = this.titlePart.settings.mainLanguage;
    this.init();
  },
  methods: {
    ...mapActions({
      fetch: 'utils/fetchVoices',
      changeVoices: 'website/changeVoices',
      updateEpisodeTemplatePart: 'website/updateEpisodeTemplatePart',
      getWebsiteById: 'website/getWebsiteById',
    }),
    // Audio preview methods
    clearPlayer() {
      this.playing = null;
      this.url = null;
      // console.log('player cleared');
    },
    play(item) {
      this.playing = item.id;
      this.url = item.url;
      this.$refs.player.player.stop();
      this.$refs.player.player.play();
    },
    stop() {
      this.$refs.player.stop();
      this.playing = null;
      this.url = null;
    },
    previewAudio(item, index) {
      const audio = this.$refs.player;
      if (this.playing === index) {
        audio.pause(); // call this to play the song right away
        this.playing = null;
        this.url = null;
        return;
      }
      this.playing = index;
      this.url = this.voiceUrl(item);
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    async init() {
      this.loading = true;
      this.loadingLanguages = true;
      await this.fetch(this.selectedCode);
      await this.loadVoices();
      if (this.contentPart) this.contentVoice = this.voiceItemById(this.contentPart.settings);
      if (this.titlePart) this.titleVoice = this.voiceItemById(this.titlePart.settings);
      if (this.contentPart) this.contentAlternativeVoice = this.voiceAlternativeItemById(this.contentPart.settings) || {};
      this.loading = false;
      this.loadingLanguages = false;
    },
    resetSettings() {
      // const { textExtractionSettings } = settings;

      // const settingsCopy = {}; // JSON.parse(JSON.stringify(this.settings));
      // settingsCopy.textExtractionSettings = JSON.parse(JSON.stringify(textExtractionSettings));
      Vue.set(this, 'settingsCopy', JSON.parse(JSON.stringify(this.currentChannel.settings)));
      this.selectedCode = this.selectedWebsite.language;
    },
    async save() {
      this.loading = true;
      this.saveError = null;

      const input = {
        feedId: this.websiteId,
        channelId: this.currentChannel.id,
        contentVoiceId: this.contentVoice.id,
        customVoiceId: this.titleVoice.id,
        contentVoice: {
          ttsVoiceName: this.contentVoice.ttsVoiceName,
          mainLanguage: this.contentVoice.mainLanguage,
          ttsVoiceKind: this.contentVoice.ttsVoiceKind,
        },
        contentAlternateVoice: {
          ttsVoiceName: this.contentAlternativeVoice.ttsVoiceName,
          mainLanguage: this.contentAlternativeVoice.mainLanguage,
          ttsVoiceKind: this.contentAlternativeVoice.ttsVoiceKind,
        },
        // contentAlternateVoice: {
        //   ttsVoiceName: this.titleVoice.ttsVoiceName,
        //   mainLanguage: this.titleVoice.mainLanguage,
        //   ttsVoiceKind: this.titleVoice.ttsVoiceKind,
        // },
        customVoice: {
          ttsVoiceName: this.titleVoice.ttsVoiceName,
          mainLanguage: this.titleVoice.mainLanguage,
          ttsVoiceKind: this.titleVoice.ttsVoiceKind,
        },
      };
      if (!this.titleVoice.ttsVoiceKind) delete input.customVoice.ttsVoiceKind;
      if (!this.contentVoice.ttsVoiceKind) delete input.contentVoice.ttsVoiceKind;
      if (!this.contentAlternativeVoice.ttsVoiceKind) delete input.contentVoice.ttsVoiceKind;

      const result = await this.changeVoices(input);
      // const resultTitle = await this.updateEpisodeTemplatePart(inputTitle);
      // const resultContent = await this.updateEpisodeTemplatePart(inputContent);
      // console.log('websiteId', result);
      await this.getWebsiteById(this.websiteId);
      this.loading = false;
      if (!result.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
      }
    },
    async loadVoices() {
      if (!this.selectedCode) return;
      this.loadingLanguages = true;
      if (this.selectedCode === 'en-EN') this.selectedCode = 'en-US';
      await this.fetch(this.selectedCode);
      this.titleVoice = {};
      this.contentVoice = {};
      this.contentAlternativeVoice = {};
      if (this.contentPart) {
        this.contentVoice = this.voiceItemById(this.contentPart.settings);
        this.contentAlternativeVoice = this.voiceItemById(this.contentPart.settings) || {};
      }
      if (this.titlePart) this.titleVoice = this.voiceItemById(this.titlePart.settings);
      this.loadingLanguages = false;
    },
    clearSelectedVoices() {
      this.titleVoice = {};
      this.contentVoice = {};
    },

    setRecomended() {
      if (!this.defaultVoices[this.selectedCode]) {
        [this.titleVoice] = this.filteredVoices;
        [this.contentVoice] = this.filteredVoices;
        this.contentAlternativeVoice = this.contentVoice;
        return;
      }
      const [titleVoice, contentVoice] = this.defaultVoices[this.selectedCode];
      this.titleVoice = titleVoice;
      this.contentVoice = contentVoice;
      this.contencontentAlternativeVoicetVoice = contentVoice;
    },
  },
};
</script>
