<template>
  <!-- Profile dropdown -->
  <div class="ml-3 relative" v-click-outside="closeUserMenu">
    <div>
      <button
        @click="showDropdown = !showDropdown"
        v-tippy="{ content: $t('ui.notifications') }"
        class="flex mr-4 text-lg p-1 h-8 w-8 rounded-full text-gray-700 hover:text-gray-900 ring-0 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hm-brand relative">
        <span class="sr-only">View notifications</span>
        <svg
          class="text-black fill-current"
          xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
            <g>
                <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path>
            </g>
        </svg>
        <span v-show="hasUnread" class="absolute top-0 right-0 block h-2 w-2 rounded-full ring-2 ring-white bg-red-600 animate-ping"></span>
        <span v-show="hasUnread" class="absolute top-0 right-0 block h-2 w-2 rounded-full bg-red-600"></span>
      </button>
    </div>
    <transition
      name="custom-classes-transition"
      enter-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="showDropdown"
        class="dropdown origin-top-left absolute -right-16 sm:right-0  mt-2 rounded-md shadow-lg py-1 bg-white focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <div class=" p-5 ">
          <div class="border-b border-gray-200 flex pb-3 justify-center items-center align-middle">
            <div class="font-bold flex-auto text-md">
              {{$t('ui.notifications')}}
            </div>
            <div class="self-center">
              <span
                @click="markAllNotificationsAsRead"
                class="text-xs text-blue-500 hover:underline cursor-pointer" v-show="hasUnread">{{$t('ui.markAllAsRead')}}</span>
            </div>
          </div>
          <div class="text-center p-7 text-gray-400 pt-10" v-show="items.length === 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="70.741" height="93.304" viewBox="0 0 70.741 93.304" class="m-auto">
              <g id="Group_5626" data-name="Group 5626" transform="translate(7665.08 2292)">
                <path id="Path_44145" data-name="Path 44145" d="M168.8,216.514C179.065,223.575,191.358,228,203.887,228c12.242,0,23.461-4.414,33.657-10.971-19.428-7.774-4.686-59-31.468-59C178.9,158.035,201.11,188.624,168.8,216.514Z" transform="translate(-7832.883 -2449.035)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44146" data-name="Path 44146" d="M177.987,195.981c10.595,1.842,12.948,6.659,31.664,6.659,12.732,0,14.162-2.748,16.143-3.243" transform="translate(-7830.558 -2439.425)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44147" data-name="Path 44147" d="M190.14,213.306c2.341,4.448,6.934,8.458,11.709,8.458a7.687,7.687,0,0,0,7.808-7.486.712.712,0,0,0-.074-.325" transform="translate(-7827.48 -2435.038)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44148" data-name="Path 44148" d="M176.952,228.055c1.62-.973,2.593-2.593,3.889-3.889" transform="translate(-7830.82 -2432.287)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44149" data-name="Path 44149" d="M189.691,225.976c0,1.945-1.3,3.564-1.62,5.185" transform="translate(-7828.004 -2431.829)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44150" data-name="Path 44150" d="M199.19,227.528v4.212" transform="translate(-7825.188 -2431.436)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44151" data-name="Path 44151" d="M210.05,225.459a9.2,9.2,0,0,0,1.3,3.889" transform="translate(-7822.438 -2431.96)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                <path id="Path_44152" data-name="Path 44152" d="M216.256,218.219c.973.648,1.945,1.944,3.241,2.268" transform="translate(-7820.866 -2433.793)" fill="none" stroke="#9ca3af" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
              </g>
            </svg>
            <div>{{$t('ui.noNotifications')}}</div>
          </div>
          <div class="md:min-w-[360px]">
            <ul class="divide-y divide-gray-200 divide-solid">
              <li v-for="item in sortedItems" :key="item.id" class="hover:text-black py-3 cursor-pointer">
                <div class="relative">
                  <div class="relative flex space-x-3"
                    @click="markAsRead(item)">
                    <div class="mt-1">
                      <span
                        :class="{'bg-red-500': !item.readAt, 'bg-gray-400': item.readAt}"
                        class="h-3 w-3 rounded-full  flex items-center justify-center ring-8 ring-white">
                      </span>
                    </div>
                    <div class="flex-auto flex justify-between space-x-4 text-sm">
                      <div class="flex-auto">
                        <div class="w-full"
                          :class="{'font-bold': !item.readAt}">
                          {{item.text}}
                        </div>
                        <div class="text-xs text-gray-400 mt-0">
                          <time datetime="2020-09-20" v-show="item.createdAt">{{moment.utc(item.createdAt).fromNow()}}</time>
                        </div>
                        <p
                          class="text-sm text-gray-400"
                          ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment from 'moment';

export default {
  name: 'Notifications',
  props: ['items'],
  data() {
    return {
      moment,
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      libraryId: null,
      notificationsModel: [
        {
          id: 1,
          text: 'Lorem ipsum sit dolot',
          customerId: 'adrian@utter.one',
          userId: 'sssss',
          createdAt: new Date(),
          readAt: null,
        },
        {
          id: 2,
          text: 'Lorem ipsum sit dolot',
          customerId: 'adrian@utter.one',
          userId: 'sssss',
          createdAt: new Date(),
          readAt: new Date(),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      isSuperAdmin: (state) => state.auth.isSuperAdmin,
    }),
    notifications() {
      return this.items || [];
    },
    sortedItems() {
      return this.notifications
        .slice(0)
        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
    },
    hasUnread() {
      if (this.notifications.length > 0) return this.notifications.some((el) => el.readAt === null);
      return false;
    },
  },
  methods: {
    ...mapActions({
      markNotificationsAsRead: 'utils/markNotificationsAsRead',
      fetchNotifications: 'utils/fetchNotifications',
    }),
    async markAllNotificationsAsRead() {
      await this.markNotificationsAsRead();
      await this.fetchNotifications();
    },
    toggleMainMenu() {
      // console.log('toggleMainMenu');
      this.showMobileNav = !this.showMobileNav;
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
  },
};
</script>
