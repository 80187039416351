/* eslint-disable */
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../../graphql';
import SelectionUtils from './selection';
import $ from './dom';
// import * as _ from './utils';

import './alias-inline.css';

require('./alias-inline.css');
/**
 * Link Tool
 *
 * Inline Toolbar Tool
 *
 * Wrap selected text with <a> tag
 */
export default class AliasInlineTool {
  /**
   * @param {API} api - Editor.js API
   */
  constructor({ api, config }) {
    /**
     * Native Document's commands for link/unlink
     */
    this.commandLink = 'createLink';
    this.commandUnlink = 'unlink';
    this.config = config;
    /**
     * Enter key code
     */
    this.ENTER_KEY = 13;
    /**
     * Styles
     */
    this.CSS = {
      button: 'ce-inline-tool',
      buttonActive: 'ce-inline-tool--active',
      buttonModifier: 'ce-inline-tool--alias',
      buttonUnlink: 'ce-inline-tool--unalias',
      input: 'ce-inline-tool-input',
      inputShowed: 'ce-inline-tool-input--showed',
    };
    /**
     * Elements
     */
    this.nodes = {
      button: null,
      input: null,
      wrapper: null,
      enter: null,
    };
    /**
     * Input opening state
     */
    this.listeners = api.listeners;
    this.inputOpened = false;
    this.toolbar = api.toolbar;
    this.inlineToolbar = api.inlineToolbar;
    this.notifier = api.notifier;
    this.i18n = api.i18n;
    this.selection = new SelectionUtils();
  }

  /**
   * Sanitizer Rule
   * Leave <a> tags
   *
   * @returns {object}
   */
  static get sanitize() {
    return {
      a: {
        href: true,
        title: false,
        dataset: false,
        'data-alias': false,
        target: '_blank',
        rel: 'nofollow',
      },
    };
  }

  /**
   * Create button for Inline Toolbar
   */
  render() {
    const text = document.createElement('span');
    text.innerText = this.i18n.t('Say as');
    this.nodes.button = document.createElement('button');
    this.nodes.button.type = 'button';
    this.nodes.button.classList.add(this.CSS.button, this.CSS.buttonModifier);
    this.nodes.button.appendChild(text);
    this.nodes.button.appendChild($.svg('cross', 11, 11));
    return this.nodes.button;
  }

  /**
   * Input for the link
   */
  renderActions() {
    this.nodes.wrapper = document.createElement('div');
    this.nodes.enter = document.createElement('button');
    this.nodes.wrapper.classList = 'relative';
    this.nodes.enter.classList = 'text-white absolute top-0 right-0 cursor-pointer hover:text-hm-brand p-2';
    this.nodes.input = document.createElement('input');
    this.nodes.input.placeholder = this.i18n.t('Enter alias');
    this.nodes.input.classList.add(this.CSS.input);
    this.nodes.input.addEventListener('keydown', (event) => {
      if (event.keyCode === this.ENTER_KEY) {
        this.enterPressed(event);
      }
    });
    this.nodes.input.addEventListener('keydown', (event) => {
      if (event.keyCode === this.ENTER_KEY) {
        this.enterPressed(event);
      }
    });
    this.listeners.on(this.nodes.enter, 'click', async (event) => {
      await this.enterPressed(event);
    }, false);
    const template = document.createElement('template');
    const enterSign = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none"><path d="M19 7v4H5.83l3.58-3.59L8 6l-6 6 6 6 1.41-1.41L5.83 13H21V7h-2z" fill="currentColor"></path></g></svg>';
    template.innerHTML = enterSign;
    this.nodes.enter.appendChild(template.content.firstChild);
    this.nodes.wrapper.appendChild(this.nodes.input);
    this.nodes.wrapper.appendChild(this.nodes.enter);
    return this.nodes.wrapper;
  }

  /**
   * Handle clicks on the Inline Toolbar icon
   *
   * @param {Range} range - range to wrap with link
   */
  surround(range) {
    // console.log('surround');
    /**
     * Range will be null when user makes second click on the 'link icon' to close opened input
     */
    if (range) {
      /**
       * Save selection before change focus to the input
       */
      if (!this.inputOpened) {
        /** Create blue background instead of selection */
        this.selection.setFakeBackground();
        this.selection.save();
      } else {
        this.selection.restore();
        this.selection.removeFakeBackground();
      }
      const parentAnchor = this.selection.findParentTag('A');
      /**
       * Unlink icon pressed
       */
      if (parentAnchor) {
        this.selection.expandToTag(parentAnchor);
        this.removeLocalAlias(parentAnchor);
        this.unlink();
        this.closeActions();
        this.checkState();
        this.toolbar.close();
        return;
      }
    }
    this.toggleActions();
  }

  async removeLocalAlias(parentAnchor) {
    const language = document.querySelector('#article-edit').dataset.language;
    const link = parentAnchor.getAttribute('href');
    const payload = {
      originalText: parentAnchor.text,
      alias: link,
      language: language,
      feedItemId: this.config.feedItemId,
      textType: this.config.textType,
    };
    console.log('add local alias start', this.config, payload, parentAnchor, link);
    const response = await API.graphql(
      graphqlOperation(
        GQL.RemoveLocalAlias,
        { input: payload },
      ),
    );
  }

  /**
   * Check selection and set activated state to button if there are <a> tag
   *
   * @param {Selection} selection - selection to check
   */
  checkState(selection) {
    // console.log('checkState', selection);
    const anchorTag = this.selection.findParentTag('A');
    if (anchorTag) {
      this.nodes.button.classList.add(this.CSS.buttonUnlink);
      this.nodes.button.classList.add(this.CSS.buttonActive);
      this.openActions();
      /**
       * Fill input value with link href
       */
      const hrefAttr = anchorTag.getAttribute('href');
      // const data = anchorTag.dataset.alias;
      // this.nodes.input.value = data !== 'null' ? data : '';
      this.nodes.input.value = hrefAttr !== 'null' ? hrefAttr : '';
      this.selection.save();
    } else {
      this.nodes.button.classList.remove(this.CSS.buttonUnlink);
      this.nodes.button.classList.remove(this.CSS.buttonActive);
    }
    return !!anchorTag;
  }

  /**
   * Function called with Inline Toolbar closing
   */
  clear() {
    // console.log('clear');
    this.closeActions();
  }
  /**
   * Set a shortcut
   */

  get shortcut() {
    return 'CMD+K';
  }

  /**
   * Show/close link input
   */
  toggleActions() {
    if (!this.inputOpened) {
      this.openActions(true);
    } else {
      this.closeActions(false);
    }
  }

  /**
   * @param {boolean} needFocus - on link creation we need to focus input. On editing - nope.
   */
  openActions(needFocus = false) {
    this.nodes.input.classList.add(this.CSS.inputShowed);
    if (needFocus) {
      this.nodes.input.focus();
    }
    this.inputOpened = true;
  }

  /**
   * Close input
   *
   * @param {boolean} clearSavedSelection — we don't need to clear saved selection
   *                                        on toggle-clicks on the icon of opened Toolbar
   */
  closeActions(clearSavedSelection = true) {
    // console.log('close', clearSavedSelection);
    if (this.selection.isFakeBackgroundEnabled) {
      // if actions is broken by other selection We need to save new selection
      const currentSelection = new SelectionUtils();
      currentSelection.save();
      this.selection.restore();
      this.selection.removeFakeBackground();
      // and recover new selection after removing fake background
      currentSelection.restore();
    }
    this.nodes.input.classList.remove(this.CSS.inputShowed);
    this.nodes.input.value = '';
    if (clearSavedSelection) {
      this.selection.clearSaved();
    }
    this.inputOpened = false;
  }

  /**
   * Enter pressed on input
   *
   * @param {KeyboardEvent} event - enter keydown event
   */
  enterPressed(event) {
    // console.log('enterPressed', event, this.nodes);
    let value = this.nodes.input.value || '';
    if (value === '') {
      this.selection.restore();
      const parentAnchor = this.selection.findParentTag('A');
      if (parentAnchor) {
        // found closest 'A' tag that wraps current selection
        this.selection.expandToTag(parentAnchor);
      }
      this.removeLocalAlias(parentAnchor);
      this.unlink();
      return;
    }
    if (!value.trim()) {
      this.selection.restore();
      this.unlink();
      event.preventDefault();
      this.closeActions();
      return;
    }
    // if (!this.validateURL(value)) {
    //   this.notifier.show({
    //     message: 'Pasted link is not valid.',
    //     style: 'error',
    //   });
    //   _.log('Incorrect Link pasted', 'warn', value);
    //   return;
    // }
    value = this.prepareLink(value);
    this.selection.restore();
    this.selection.removeFakeBackground();
    this.insertLink(value);
    /**
     * Preventing events that will be able to happen
     */
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();
    this.selection.collapseToEnd();
    this.inlineToolbar.close();
  }

  /**
   * Detects if passed string is URL
   *
   * @param {string} str - string to validate
   * @returns {boolean}
   */
  validateURL(str) {
    /**
     * Don't allow spaces
     */
    return !/\s/.test(str);
  }

  /**
   * Process link before injection
   * - sanitize
   * - add protocol for links like 'google.com'
   *
   * @param {string} link - raw user input
   */
  prepareLink(link) {
    link = link.trim();
    // link = this.addProtocol(link);
    return link;
  }

  /**
   * Inserts <a> tag with "href"
   *
   * @param {string} link - "href" value
   */
  async insertLink(link) {
    /**
     * Edit all link, not selected part
     */
    const anchorTag = this.selection.findParentTag('A');
    // console.log('anchor tag', anchorTag, this.selection);
    if (anchorTag) {
      this.selection.expandToTag(anchorTag);
    }
    document.execCommand(this.commandLink, false, link);
    const aTag = this.selection.findParentTag('A');
    // aTag.dataset.alias = link;
    aTag.setAttribute('href', link);
    const language = document.querySelector('#article-edit').dataset.language;
    const payload = {
      originalText: aTag.text,
      alias: link,
      language: language,
      feedItemId: this.config.feedItemId,
      textType: this.config.textType,
    };
    console.log('add local alias start', this.config, payload, aTag, link);
    const response = await API.graphql(
      graphqlOperation(
        GQL.AddLocalAlias,
        { input: payload },
      ),
    );
    console.log('add local alias', response);
    // aTag.title = link;
    // aTag.removeAttribute('href');
  }

  /**
   * Removes <a> tag
   */
  unlink() {
    document.execCommand(this.commandUnlink, false, false);
  }

  static title = 'Link';
}
/**
 * Specifies Tool as Inline Toolbar Tool
 *
 * @returns {boolean}
 */
AliasInlineTool.isInline = true;
/**
 * Title for hover-tooltip
 */
// AliasInlineTool.title = 'Alias';
