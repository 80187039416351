<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.articles')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.articles')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <Button size="sm2" v-show="isProject" @click.native="saveNewItem" :loading="loadingDraft">{{$t('ui.addFeedItem')}}</Button>
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-0">
      <PaymentStatusAlert></PaymentStatusAlert>
      <!-- Search and Filter -->
      <div class="w-full mt-5">
        <div class="relative text-gray-400 focus-within:text-gray-500">
          <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <input id="search"  v-on:keyup.enter="refresh" v-model="searchQuery" class="block w-full bg-white py-3 pl-10 pr-3 border border-gray-300 rounded leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-hm-secondary focus:border-hm-secondary focus:placeholder-gray-500 sm:text-sm" :placeholder="$t('ui.searchArticleByTitle')" type="search" name="search">
        </div>
      </div>
      <div class="filter" v-if="items.length !== 0 || searchQuery">
        <div class="flex  justify-between mb-2" >
          <div class="h-12 flex flex-wrap content-center  text-gray-400 text-sm">{{$t('ui.listShowing', [totalCount])}} </div>
          <div class="h-12 flex flex-wrap content-center text-sm text-gray-400">
            <div class="relative inline-block text-gray-800"  v-click-outside="() => {showFilter = false}">
              <button
                @click="showFilter = !showFilter"
                type="button" class="bg-white relative w-full text-gray-600 border border-none  pl-3 pr-8 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-white focus:border-white focus:text-hm-secondary sm:text-sm" aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label">
                <span class="block truncate">
                  <svg
                    class="text-hm-brand inline-block h-5 w-5 -mt-1 mr-3"
                    xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                  </svg>
                  <span class="text-gray-500">{{$t('ui.sortBy')}}</span>
                  {{selectedOrder.replace('_', ' ')}}
                </span>
                <span class="absolute inset-y-0 right-0 flex items-center pr-0 pointer-events-none">
                  <!-- Heroicon name: solid/selector -->
                  <svg class="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                  </svg>
                </span>
              </button>
              <transition
                leave-active-class="transition ease-in duration-100"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0">
                <ul
                  v-show="showFilter"
                  class="absolute mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm z-50" tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-option-3">
                  <li
                    @click="orderBy('PUBLISHED_AT')"
                    class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 hover:text-gray-900" id="listbox-option-0" role="option">
                    <span class="font-normal block truncate">
                      PUBLISHED AT
                    </span>
                    <span class="text-hm-secondary absolute inset-y-0 right-0 flex items-center pr-4"
                      v-show="selectedOrder === 'PUBLISHED_AT'">
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="!orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </li>
                  <li
                    @click="orderBy('UPDATED_AT')"
                    class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 hover:text-gray-900" id="listbox-option-0" role="option">
                    <span class="font-normal block truncate">
                      UPDATED AT
                    </span>
                    <span class="text-hm-secondary absolute inset-y-0 right-0 flex items-center pr-4"
                      v-show="selectedOrder === 'UPDATED_AT'">
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="!orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </li>
                  <li
                    @click="orderBy('CREATED_AT')"
                    class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 hover:text-gray-900" id="listbox-option-0" role="option">
                    <span class="font-normal block">
                      ADDED
                    </span>
                    <span class="text-hm-secondary absolute inset-y-0 right-0 flex items-center pr-4"
                      v-show="selectedOrder === 'CREATED_AT'">
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="!orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </li>
                  <li
                    @click="orderBy('TITLE')"
                    class="text-gray-900 cursor-default select-none relative py-2 pl-3 pr-9 hover:bg-gray-100 hover:text-gray-900" id="listbox-option-0" role="option">
                    <span class="font-normal block">
                      TITLE
                    </span>
                    <span class="text-hm-secondary absolute inset-y-0 right-0 flex items-center pr-4"
                      v-show="selectedOrder === 'TITLE'">
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="!orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                      </svg>
                      <svg xmlns="http://www.w3.org/2000/svg" v-show="orderDir" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                      </svg>
                    </span>
                  </li>
                  <!-- More items... -->
                </ul>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-red-50 px-4 py-2 mt-3 mb-3 rounded-md" v-if="items.length !== 0 && (selectedWebsite.status === 'MISSING' || selectedWebsite.status === 'ERROR')">
        <div class="flex">
          <div class="flex-shrink-0">
            <!-- Heroicon name: solid/exclamation -->
            <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
            </svg>
          </div>
          <div class="ml-3">
            <p class="text-sm text-red-800">
              {{$t('backend.' + statusReason) || $t('ui.somethingWentWrong')}}
            </p>
          </div>
        </div>
      </div>
      <div class ="no-articles flex justify-center items-center space-x-4 min-h-half max-w-md m-auto"
        v-if="selectedWebsite.type === 'RSS' && items.length === 0 && !loading && !(selectedWebsite.status === 'MISSING' || selectedWebsite.status === 'ERROR') && !searchQuery">
       <div class="text-center">
          <svg class="spinner m-auto" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
              </svg>
              <h1 class="font-bold text-4xl mb-3 mt-4">{{$t('ui.noArticlesYet')}}</h1>
              <p class=" text-1xl text-gray-400">{{$t('ui.rssNoArticlesYet')}}</p>
        </div>
      </div>
      <transition
        name="fade-fast"
        mode="out-in">
        <ul class="divide-y bg-white divide-gray-200 min-h-[50vh]" v-if="loading" key="loading-list">
          <li v-for="i in [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25, 26]" :key="i">
            <div class="py-4">
              <div class="flex items-center justify-between w-full">
                <div class="w-1/ h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/2': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                <div class="ml-2 w-1/2 flex-shrink-0 flex justify-end">
                  <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse self-end" :class="{'w-1/6': i % 2 > 0, 'w-1/7': i % 2 === 0 }"></div>
                </div>
              </div>
              <div class="mt-2 sm:flex sm:justify-between w-full">
                <div class="sm:flex w-full md:w-1/2 md:space-x-2 space-y-2 md:space-y-0">
                  <div class="w-1/4 h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/6': i % 2 > 0, 'w-1/3': i % 2 === 0 }"></div>
                  <div class="w-1/5 h-4 rounded-sm bg-gray-300 animate-pulse"></div>
                </div>
                <div class="mt-2 w-1/2 flex items-center text-sm text-gray-500 sm:mt-0 md:justify-end">
                  <div class="w-1/5 h-4 rounded-sm bg-gray-300 animate-pulse" :class="{'w-1/5': i % 2 > 0, 'w-1/6': i % 2 === 0 }"></div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div class="bg-white transition-all" v-else key="article-list">
          <div class=" my-4" v-if="items.length === 0 && searchQuery">
            <h1 class="text-center font-bold text-4xl">{{$t('ui.articlesNotFound')}}</h1>
          </div>
          <div class="no-articles flex justify-center items-center space-x-4 min-h-half max-w-md m-auto" v-if="items.length === 0 && !loading && !searchQuery">
            <div class="text-center">
              <div class="error-rss-no-articles" v-show="selectedWebsite.type === 'RSS' && selectedWebsite.status === 'NEW'">
                <svg class="spinner m-auto" width="65px" height="65px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                  <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
                </svg>
                <h1 class="font-bold text-4xl mb-3 mt-4">{{$t('ui.noArticlesYet')}}</h1>
                <p class=" text-1xl text-gray-400">{{$t('ui.rssNoArticlesYet')}}</p>
              </div>
              <div  v-show="selectedWebsite.status === 'MISSING' && !searchQuery">
                <h1 class="font-bold text-4xl mb-3 mt-4">{{$t('ui.noArticlesYet')}}</h1>
                <div class="bg-yellow-50 p-4 mt-7">
                  <div class="flex">
                    <div class="flex-shrink-0">
                      <!-- Heroicon name: solid/exclamation -->
                      <svg class="h-5 w-5 text-yellow-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                      </svg>
                    </div>
                    <div class="ml-3">
                      <p class="text-sm text-yellow-700">
                        {{$t('ui.noArticlesError')}}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center" v-if="isProject && items.length === 0">
              <h1 class="font-bold text-4xl mb-3 mt-16">{{$t('ui.noProjectItems')}}</h1>
              <p class=" text-1xl text-gray-400">{{$t('ui.noProjectItemsDescription')}}</p>
              <div>
                <Button size="md" class="block w-full mt-4" v-show="isProject" @click.native="saveNewItem" :loading="loadingDraft">{{$t('ui.addFeedItem')}}</Button>
              </div>
            </div>
            <div class="text-center" v-if="selectedWebsite.type === 'WORDPRESS' && selectedWebsite.status === 'NEW'">
              <svg
                class="mx-auto mt-4"
                xmlns="http://www.w3.org/2000/svg" width="94.861" height="98.304" viewBox="0 0 94.861 98.304">
                <g id="plugin-icon" transform="translate(7779 2298)">
                  <path id="Subtraction_5" data-name="Subtraction 5" d="M8.3,20.938a3.279,3.279,0,0,1-1.228-.179c-.674-.278-.778-.994-.863-1.57-.051-.346-.094-.644-.259-.729A8.949,8.949,0,0,1,4.209,17.08,6.255,6.255,0,0,0,3,16.1a.988.988,0,0,0-.41-.09A1.514,1.514,0,0,0,2,16.155l-.063.026a1.1,1.1,0,0,1-.394.118.336.336,0,0,1-.3-.2C.41,14.687.16,11.756.085,10.135-.251,9.281.452,8.794,1.2,8.278c.6-.417,1.224-.848,1.272-1.494A4.5,4.5,0,0,0,2.017,4.9c-.307-.769-.573-1.433-.078-1.874C2.9,2.173,5.141.177,6.809.008A1.422,1.422,0,0,1,6.958,0c.686,0,.936.554,1.177,1.089a1.686,1.686,0,0,0,.686.931,3.273,3.273,0,0,0,1.355.227c.4,0,.835-.036,1.182-.065l.051,0c.373-.032.724-.062,1.036-.062a1.749,1.749,0,0,1,.855.156.253.253,0,0,0,.124.038c.21,0,.305-.379.365-.71.014-.076.026-.153.038-.225.043-.26.079-.485.146-.485a.053.053,0,0,1,.036.019,12.826,12.826,0,0,0,1.445,1.057c1.178.791,2.513,1.688,2.843,2.543.254.654-.256,1.43-.665,2.053a3.212,3.212,0,0,0-.394.7,3.352,3.352,0,0,0,.092,2.03,2.72,2.72,0,0,1,.158.845c-.011.341.861.6,1.562.816.49.148.914.276.888.389a12.5,12.5,0,0,0-.139,2.275c-.01,1.575-.021,3.2-.761,3.915a.381.381,0,0,1-.275.1,2.823,2.823,0,0,1-1.081-.4l-.142-.075a2.4,2.4,0,0,0-.764-.323.145.145,0,0,0-.067.014c-.428.231-.8.443-1.16.649A9.983,9.983,0,0,1,12.579,18.8a.226.226,0,0,0-.153.107c-.115.2,0,.6.108.948.077.26.143.485.092.56a.059.059,0,0,1-.047.026c-.344.033-.8.1-1.283.177A21.223,21.223,0,0,1,8.3,20.938Zm.972-13.8a3.5,3.5,0,1,0,3.5,3.5A3.5,3.5,0,0,0,9.274,7.142Z" transform="translate(-7721.274 -2229.642)" fill="#fab327" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
                  <g id="Group_5614" data-name="Group 5614" transform="translate(-8016.948 -2612.877)">
                    <g id="Group_5608" data-name="Group 5608">
                      <path id="Path_44131" data-name="Path 44131" d="M278.155,413.181c-.762,0-1.528-.026-2.3-.093a35.961,35.961,0,0,1-9.493-2.6c-.789-.3-1.579-.594-2.375-.876l-4.694-1.666a4.223,4.223,0,0,1-1.451-.76c-1.635-1.435-1.83-4.69-1.906-8.481-.015-.776-.028-1.447-.071-1.911-.348-3.8-.638-7.666-.861-11.492-.447-7.668-.641-15.447-.578-23.129-1.017-.98-2.049-2-3.094-3.024-3.018-2.967-6.137-6.036-9.245-8.607q-1.239-1.026-2.488-2.04l-.08-.065a2.715,2.715,0,0,0-.8-.523,1.061,1.061,0,0,1,.12-2.07,89.619,89.619,0,0,0,12.79-3.335,1.062,1.062,0,0,1,1.012.2c6.512,5.568,12.974,11.694,19.211,18.212,14.373.22,30.927-.441,49.209-1.974,1.577-1.74,3.214-3.617,4.8-5.436,1.684-1.931,3.425-3.928,5.1-5.766a1.061,1.061,0,1,1,1.569,1.43c-1.659,1.82-3.392,3.808-5.068,5.731s-3.432,3.936-5.11,5.778a1.061,1.061,0,0,1-.7.343c-18.707,1.58-35.625,2.255-50.282,2.009a1.064,1.064,0,0,1-.75-.329c-6.139-6.436-12.5-12.49-18.912-18-3.106.988-6.566,2.037-10.017,2.763q.876.714,1.746,1.434c3.178,2.629,6.331,5.73,9.38,8.729,1.152,1.133,2.289,2.251,3.407,3.323a1.062,1.062,0,0,1,.326.776c-.075,7.783.116,15.671.57,23.444.221,3.8.51,7.645.856,11.422.049.539.063,1.245.08,2.061.041,2.1.119,5.994,1.183,6.929a2.6,2.6,0,0,0,.76.355l4.694,1.665c.809.287,1.612.589,2.413.89a33.85,33.85,0,0,0,8.931,2.471,40.536,40.536,0,0,0,5.683-.047l36.108-1.985c1.782-.1,3.064-.553,3.517-1.248a2.325,2.325,0,0,0-.046-2.082,1.061,1.061,0,1,1,1.987-.746,4.275,4.275,0,0,1-.163,3.988c-.864,1.323-2.606,2.066-5.178,2.207l-36.108,1.985C280.635,413.113,279.4,413.181,278.155,413.181Z" fill="#1f2532"/>
                    </g>
                    <g id="Group_5609" data-name="Group 5609">
                      <path id="Path_44132" data-name="Path 44132" d="M322.39,409.413a1.061,1.061,0,0,1-1.061-1.037l-1.068-48.392a1.061,1.061,0,0,1,2.122-.047l1.068,48.392A1.065,1.065,0,0,1,322.39,409.413Z" fill="#1f2532"/>
                    </g>
                    <g id="Group_5610" data-name="Group 5610">
                      <path id="Path_44133" data-name="Path 44133" d="M273.493,408.1a1.06,1.06,0,0,1-1.056-.972c-1.246-14.816-1.881-29.867-1.886-44.736a1.061,1.061,0,1,1,2.122,0c.005,14.809.637,29.8,1.878,44.558a1.061,1.061,0,0,1-.968,1.147Z" fill="#1f2532"/>
                    </g>
                    <g id="Group_5611" data-name="Group 5611">
                      <path id="Path_44134" data-name="Path 44134" d="M255.75,362.478a1.061,1.061,0,0,1-.087-2.119,88.9,88.9,0,0,1,14.757,0,1.061,1.061,0,0,1-.177,2.115,86.915,86.915,0,0,0-14.4,0C255.809,362.477,255.779,362.478,255.75,362.478Z" fill="#1f2532"/>
                    </g>
                    <g id="Group_5612" data-name="Group 5612">
                      <path id="Path_44135" data-name="Path 44135" d="M302.959,361.614a1.061,1.061,0,0,1-.736-1.825c3.78-3.642,7.682-7.266,11.6-10.77a1.036,1.036,0,0,1,.719-.271,134.279,134.279,0,0,0,15.871-1.153l1.01-.113a1.061,1.061,0,0,1,.235,2.109l-1.008.113a138.447,138.447,0,0,1-15.716,1.169c-3.794,3.4-7.573,6.915-11.236,10.444A1.061,1.061,0,0,1,302.959,361.614Z" fill="#1f2532"/>
                    </g>
                    <g id="Group_5613" data-name="Group 5613">
                      <path id="Path_44136" data-name="Path 44136" d="M289.6,351.941a1.058,1.058,0,0,1-.683-.249,59.566,59.566,0,0,0-5.571-4.148c-2.394-1.573-4.952-3-7.425-4.382l-8.461-4.723a1.061,1.061,0,0,1,.379-1.979,54.811,54.811,0,0,1,5.636-.346c1.779-.048,3.609-.1,5.359-.321a117.652,117.652,0,0,0-1.887-18.4,1.063,1.063,0,0,1,1-1.237c2.9-.116,5.969-.381,8.938-.638,2.995-.259,6.093-.527,9.042-.644a1.09,1.09,0,0,1,.812.329,1.064,1.064,0,0,1,.287.828,135.989,135.989,0,0,0-.339,20.832c.67-.112,1.365-.245,2.085-.382a32.727,32.727,0,0,1,6.3-.789,1.061,1.061,0,0,1,.649,1.887,167.08,167.08,0,0,0-15.361,14.034A1.055,1.055,0,0,1,289.6,351.941ZM271.564,338.3l5.394,3.012c2.507,1.4,5.1,2.846,7.556,4.461a61.564,61.564,0,0,1,5.009,3.668c3.817-3.913,7.917-7.712,12.23-11.333-.853.134-1.721.3-2.577.464-.949.181-1.924.367-2.856.5a1.244,1.244,0,0,1-.235.159,1.154,1.154,0,0,1-1.264-.141,1.179,1.179,0,0,1-.4-1.239,1.05,1.05,0,0,1,.184-.352,137.252,137.252,0,0,1,.2-20.447c-2.537.133-5.176.361-7.738.583-2.59.224-5.256.454-7.836.588a113,113,0,0,1,1.724,18.488,1.062,1.062,0,0,1-.886,1.044,47.243,47.243,0,0,1-6.542.48C272.883,338.253,272.223,338.271,271.564,338.3Z" fill="#1f2532"/>
                    </g>
                    <path id="Path_44137" data-name="Path 44137" d="M304.617,405.554a16.47,16.47,0,0,1-2.6-.218.921.921,0,0,1-.772-.833c-.067-.793-.11-1.65-.132-2.656a7.264,7.264,0,0,1-2.695-2.033c-.2.1-.4.207-.613.316-.3.154-.589.307-.886.452a.922.922,0,0,1-1.242-.445,20.628,20.628,0,0,1-1.866-6.8.924.924,0,0,1,.537-.918,7.182,7.182,0,0,1,1.964-.505,9.48,9.48,0,0,1,1.207-3.688A11.783,11.783,0,0,1,296.279,387a.921.921,0,0,1,.045-1.24,12.774,12.774,0,0,1,6.412-3.665.92.92,0,0,1,1.01.476c.327.628.642,1.283,1,2.07a10.129,10.129,0,0,1,2.913.42q.176-.319.348-.639c.229-.423.458-.846.705-1.269a.919.919,0,0,1,1.142-.391,11.032,11.032,0,0,1,5.886,5.051.922.922,0,0,1-.3,1.17l-.423.288c-.459.312-.9.612-1.307.934a14.62,14.62,0,0,1,.565,2.729,16.254,16.254,0,0,1,1.7.333.921.921,0,0,1,.7.835c.033.5.049.979.049,1.434a11.924,11.924,0,0,1-1.291,5.868.922.922,0,0,1-1.055.442,12.775,12.775,0,0,1-2.06-.837,13.378,13.378,0,0,1-2.8,1.541l.233,1.052a.921.921,0,0,1-.526,1.042A11.31,11.31,0,0,1,304.617,405.554Zm-1.592-1.939a10.948,10.948,0,0,0,4.752-.384l-.235-1.06a.92.92,0,0,1,.6-1.068,11.652,11.652,0,0,0,3.487-1.883.922.922,0,0,1,.969-.118l.47.223c.372.178.732.35,1.087.495a11.23,11.23,0,0,0,.729-4.282c0-.2,0-.414-.011-.63a11.042,11.042,0,0,0-1.524-.213.923.923,0,0,1-.862-.891,12.547,12.547,0,0,0-.716-3.606.921.921,0,0,1,.254-.979,15.313,15.313,0,0,1,1.665-1.273A9.5,9.5,0,0,0,309.9,384.8q-.138.252-.273.5c-.239.44-.477.88-.734,1.319a.919.919,0,0,1-1.079.411,9.058,9.058,0,0,0-3.581-.524.928.928,0,0,1-.919-.547c-.319-.726-.6-1.333-.873-1.889a10.905,10.905,0,0,0-4.153,2.364c.252.238.521.47.8.709l.235.2a.921.921,0,0,1,.158,1.214,7.646,7.646,0,0,0-1.371,4.166.924.924,0,0,1-.767.894c-.2.034-.4.063-.6.092-.357.052-.7.1-1.02.179a18.7,18.7,0,0,0,1.246,4.6c.447-.234.909-.474,1.385-.684a.92.92,0,0,1,1.169.379,5.233,5.233,0,0,0,2.8,2.166.921.921,0,0,1,.616.857C302.954,402.12,302.981,402.9,303.025,403.615Z" fill="#1f2532"/>
                    <path id="Path_44138" data-name="Path 44138" d="M305.2,398.535a4.8,4.8,0,0,1-.826-9.531,5.523,5.523,0,0,1,.957-.072,4.69,4.69,0,0,1,4.733,4.387,5.125,5.125,0,0,1-1.38,3.753A4.687,4.687,0,0,1,305.2,398.535Zm-.524-7.713a3.047,3.047,0,0,0-2.481,3.018,3.007,3.007,0,0,0,2.987,2.852,2.908,2.908,0,0,0,2.166-.89,3.255,3.255,0,0,0,.876-2.38,2.829,2.829,0,0,0-2.919-2.647A3.334,3.334,0,0,0,304.673,390.822Z" fill="#1f2532"/>
                  </g>
                </g>
              </svg>
              <h1 class="font-bold text-4xl mb-3 mt-4">{{$t('ui.noArticlesYet')}}</h1>
              <p class=" text-1xl text-gray-400">{{$t('ui.installWPPluginDescriptionArticleList')}}</p>
              <div>
                <div class="rounded-md bg-blue-50 p-4 mt-4">
                  <div class="flex">
                    <div class="flex-shrink-0">
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                      <p class="text-sm text-blue-700 font-bold">
                        {{wpToken}}
                      </p>
                      <p class="mt-3 text-sm md:mt-0 md:ml-6">
                        <a href="#" @click="copyCode(wpToken)" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">{{$t('ui.copyToClipboard')}}</a>
                      </p>
                    </div>
                  </div>
                </div>
                <a :href="pluginLink" target="_blank" class="text-white bg-black hover:bg-blue-700 items-center justify-center border border-transparent px-6 py-3 text-sm font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out mt-8 block">{{$t('ui.downloadPlugin')}}</a>
                <Button type="default" @click.native="showGuideModal = true" class="block w-full mt-8">{{$t('ui.guideButton')}}</Button>
              </div>
            </div>
          </div>
          <ul class="divide-y divide-gray-200">
            <li v-for="item in items" :key="item.id">
              <router-link tag="a" class="block rounded" :to="{path: `/articles/${websiteId}/edit/${item.id.replaceAll('/', '|').replaceAll('?', ']')}`}">
                <div class="px-4 py-4 sm:px-6 -mx-4">
                  <div class="flex items-center justify-between">
                    <p
                      class="text-sm font-medium text-hm-secondary truncate"
                      :class="{'font-bold' : articlesById[item.id].status === 'published' && item.status === 'DRAFT'}"
                      v-tippy="{
                        content: articlesById[item.id].status === 'published' && item.status === 'DRAFT' ? $t('ui.unpublishedChanges') : '',
                        trigger : articlesById[item.id].status === 'published' && item.status === 'DRAFT' ? 'mouseenter focus' : 'click'
                        }">
                      <!-- {{item.title}}<br> -->
                      {{stripTag(item.title)}}
                      <span v-show="articlesById[item.id].status === 'published' && item.status === 'DRAFT'">*</span>
                    </p>
                    <!-- <p
                      v-if="item.status === 'DRAFT' && item.acceptedVersionId"
                      class="text-sm font-bold text-hm-secondary truncate"
                      v-tippy="{content: $t('ui.unpublishedChanges')}">
                      {{stripTag(item.title)}}
                      <span >*</span>
                    </p> -->
                    <div class="ml-2 flex-shrink-0 flex space-x-2">
                      <p class="px-2 py-1 inline-flex text-xs leading-4 h-6 font-semibold rounded-full uppercase"
                        :class="articlesById[item.id].status">
                        <span>{{$t('ui.' + articlesById[item.id].status)}}</span>
                      </p>
                      <p v-tippy="{content: $t('ui.unpublish')}"  @click.prevent="unpublish(item.id)"
                        v-show="articlesById[item.id].status === 'published'"
                        class="p-1 ml-2 rounded-full bg-gray-100 text-gray-700 hover:bg-red-500 hover:text-white ring-0 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hm-brand relative">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M24 1.5C11.5736 1.5 1.5 11.5736 1.5 24C1.5 36.4264 11.5736 46.5 24 46.5C36.4264 46.5 46.5 36.4264 46.5 24C46.5 11.5736 36.4264 1.5 24 1.5ZM27.0618 20.6981C24.5121 18.1484 20.493 14.3455 17.4102 11.4559C19.3783 10.4198 21.62 9.83333 24 9.83333C31.824 9.83333 38.1667 16.176 38.1667 24C38.1667 26.3817 37.5793 28.6249 36.5418 30.594C33.5747 27.4321 29.64 23.2763 27.0618 20.6981ZM9.83333 24C9.83333 21.6202 10.4198 19.3785 11.4558 17.4105C14.3453 20.4933 18.1482 24.5123 20.6979 27.062C23.2761 29.6402 27.4318 33.5749 30.5937 36.542C28.6247 37.5794 26.3816 38.1667 24 38.1667C16.176 38.1667 9.83333 31.824 9.83333 24Z" clip-rule="evenodd"></path></svg>
                      </p>
                      <a-popconfirm :title="$t('ui.areYouSureDeleteArticle')" @confirm="deleteArticle(item.id)" :okText="$t('ui.confirmDeleteButton')" :cancelText="$t('ui.cancel')">
                        <p
                          v-tippy="{content: $t('ui.deleteItem')}"
                          :class="{loading: deleteLoading === item.id}"
                          class="p-1 ml-2 rounded-full bg-gray-100 text-gray-700 hover:bg-red-500 hover:text-white ring-0 ring-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-hm-brand relative">
                          <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                        </p>
                      </a-popconfirm>
                    </div>
                  </div>
                  <div class="mt-2 sm:flex sm:justify-between">
                    <div class="sm:flex">
                      <p class="flex items-center text-sm text-gray-500">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                        </svg>
                        {{item.authorName}}
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
                        v-tippy="{content: $t('ui.articlePublished') + ' ' + moment.utc(item.publishedAt).local().format('DD-MM-YYYY HH:mm')}">
                        <!-- Heroicon name: solid/location-marker -->
                        <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd" />
                        </svg>
                        <timeago :datetime="moment.utc(item.publishedAt)"></timeago>
                      </p>
                      <p class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6"
                        v-tippy="{content: $t('ui.statsTotal') + ' ' + item.playsCount.total + '<br> ' + $t('ui.statsMonth') + ' ' + item.playsCount.month + '<br>' + $t('ui.statsSeven') + ' ' + item.playsCount.sevenDays}">
                        <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M5 3a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2V5a2 2 0 00-2-2H5zm9 4a1 1 0 10-2 0v6a1 1 0 102 0V7zm-3 2a1 1 0 10-2 0v4a1 1 0 102 0V9zm-3 3a1 1 0 10-2 0v1a1 1 0 102 0v-1z" clip-rule="evenodd" />
                        </svg>
                        {{item.playsCount.total}}
                      </p>
                      <div class="mt-2 flex items-center text-sm text-yellow-500 sm:mt-0 sm:ml-6" v-if="isPodcastPublished(item)" v-tippy="{content: $t('ui.podcastPublished')}">
                        <PodcastIcon class="h-4 w-4"></PodcastIcon>
                      </div>
                    </div>
                    <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                      <p>
                        <span class="mr-1">{{$t('ui.updatedAt')}}</span>
                        <timeago :datetime="moment.utc(item.updatedAt)"></timeago>
                      </p>
                    </div>
                  </div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </transition>
      <transition
        name="fade-fast"
        mode="out-in">
      </transition>
    </div>
    <!-- PAGINA -->
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-0 divide-y divide-gray-300"  v-if="items.length !== 0">
      <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
    </div>
    <div v-if="selectedWebsite">
      <Modal :show.sync="showGuideModal" :width="'2xl'">
        <div slot="header"></div>
        <Guide :token="wpToken" :pluginUrl="pluginLink"></Guide>
      </Modal>
    </div>
    <!-- <Modal :show.sync="firstTime"> -->
    <div v-if="firstTime">
      <Modal :show.sync="firstTime">
        <div slot="header"></div>
        <div class="text-center space-y-4 mt-4 p-5 pb-0">
          <div class="font-bold text-4xl">{{$t('ui.welcome')}}</div>
          <div class="text-sm text-gray-500">
            {{$t('ui.firstTimeMessage')}}
          </div>
        </div>
        <div class="text-center">
          <Button type="brand" @click.native="skip" class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.letsGo')}}</Button>
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapMutations, mapActions } from 'vuex';
import PaginationTemplate from '../components/PaginationTemplate.vue';
import Guide from '../components/PluginGuide.vue';

export default {
  components: {
    Guide,
  },
  data() {
    return {
      moment,
      showGuideModal: false,
      firstTime: false,
      showFilter: false,
      loading: false,
      loadingDraft: false,
      deleteLoading: null,
      searchQuery: null,
      selectedOrder: 'PUBLISHED_AT',
      // orderDirString: 'DESC',
      orderDir: true, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      refreshInterval: null,
      paymentStatus: null,
    };
  },
  computed: {
    ...mapState({
      selectedWebsite: (state) => state.website.selectedWebsite,
      items: (state) => state.website.articles,
      articlesById: (state) => state.website.articlesById,
      totalCount: (state) => state.website.count,
      savedWebsiteId: (state) => state.website.savedWebsiteId,
      hasNextPage: (state) => state.website.hasNextPage,
      isSuperAdmin: (state) => state.auth.isSuperAdmin,
      userId: (state) => state.auth.userId,
      userSession: (state) => state.auth.userSession,
      pluginLink: (state) => state.settings.pluginLink,
      customerId: (state) => state.auth.customerId,
    }),
    statusReason() {
      if (!this.selectedWebsite || !this.selectedWebsite.statusReason) return '';
      const [status] = this.selectedWebsite.statusReason.split(' ');
      return status;
    },
    isPodcastPublished() {
      return (item) => {
        if (!item.episodesByFeedItemId || !item.episodesByFeedItemId.nodes) return false;
        const podcastEpisode = item.episodesByFeedItemId.nodes
          .find((el) => el.channelByChannelId.type === 'PODCAST') || {};
        return podcastEpisode && podcastEpisode.status === 'PUBLISHED';
      };
    },
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      localStorage.setItem('selectedOrder', this.selectedOrder);
      localStorage.setItem('orderDirString', this.orderDirString);

      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    stripTag() {
      return (unsafe) => {
        const doc = new DOMParser().parseFromString(unsafe, 'text/html');
        const str = doc.documentElement.textContent;
        return str.replace(/(<([^>]+)>)/gi, '');
      };
    },
    wpToken() {
      return this.selectedWebsite.settings && this.selectedWebsite.settings['wp-token'] ? this.selectedWebsite.settings['wp-token'] : '';
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
        },
      };

      if (this.selectedWebsite.id) {
        input.condition.feedId = this.selectedWebsite.id;
      }
      // if saved in localstorage
      if (this.websiteId) {
        input.condition.feedId = this.websiteId;
      }
      if (this.searchQuery) {
        input.customFilter = {
          titleIncludes: this.searchQuery,
        };
      }

      return input;
    },
    websiteId() {
      return this.$route.params.websiteId;
    },
  },
  watch: {
    // eslint-disable-next-line
    searchQuery: function () {
      // console.log('sdasdsad', this.searchQuery);
      const refresh = _.debounce(this.refresh, 1150);
      refresh();
    },
    // eslint-disable-next-line
    userId: function (newVal, oldVal) {
      // const savedWebsite = localStorage.getItem(`w_${newVal}`);
      // // console.log('savedWebsite', `w_${newVal}`, oldVal, savedWebsite);
      // if (savedWebsite) {
      //   this.setSavedWebsiteId(savedWebsite);
      // }
    },
    // eslint-disable-next-line
    websiteId: function (newVal) {
      // console.log('asadasdasdasd', newVal);
      this.loadData(1);
    },
  },
  async created() {
    this.paymentStatus = this.$route.params.state;
    this.loading = true;
    if (this.$route.params.websiteId === 'none' || this.$route.params.websiteId === 'undefined') {
      setTimeout(async () => {
        const savedWebsite = localStorage.getItem(`w_${this.userId}`);

        // console.log('%c articlesList.vue none', 'color: orange', this.$route.params.websiteId, savedWebsite);
        if (savedWebsite) {
          this.$router.push({ path: `/articles/${savedWebsite}/list` });
          this.loading = false;
          return;
        }
        console.log('fetchWebsite $$$$$$$', this.customerId);
        const websites = await this.fetchWebsites(this.customerId);

        console.log('fetchWebsite $$$$$$$', this.customerId, websites);
        this.loading = false;
        this.$router.push({ path: `/articles/${websites[0].id}/list` });
      }, 500);
    }
    const selectedOrder = localStorage.getItem('selectedOrder');
    const orderDirString = localStorage.getItem('orderDirString');
    const firstTime = localStorage.getItem('firstTime');

    if (selectedOrder && orderDirString) {
      // console.log('list order', selectedOrder, orderDirString);
      this.selectedOrder = selectedOrder;
      this.orderDir = orderDirString === 'DESC';
    }
    // const savedWebsite = localStorage.getItem(`w_${this.userId}`);
    if (firstTime === 'firstTime') this.firstTime = true;
    this.loading = true;
  },
  beforeMount() {
    this.setSidebarVisibility(true);
  },
  beforeDestroy() {
    clearInterval(this.refreshInterval);
  },
  async mounted() {
    const useAsModeData = JSON.parse(localStorage.getItem('useAs'));
    // const { sub } = this.userSession.payload;
    // console.log('%cARTICLE LIST MOUNTED', 'color: lime; font-size: 16px;', useAsModeData, this.userId, this.userSession);
    const websiteId = (this.isSuperAdmin && useAsModeData) ? useAsModeData.id : null;
    const savedWebsite = localStorage.getItem(`w_${this.userId}`);

    // If Is in Use As mode get selected website
    if (websiteId) {
      await this.getWebsiteById(websiteId);
    }

    // If user has Saved website
    if (savedWebsite) {
      await this.getWebsiteById(savedWebsite);
    }

    this.loadData(1);
    this.refreshInterval = window.setInterval(this.refresh, 20000);
  },
  methods: {
    ...mapActions({
      fetchWebsites: 'website/fetchWebsites',
      fetch: 'website/fetchArticles',
      getWebsiteById: 'website/getWebsiteById',
      createProjectFeedItem: 'website/createProjectFeedItem',
      deleteArticleById: 'website/deleteArticleById',
      unpublishFeedItem: 'website/unpublishFeedItem',
    }),
    ...mapMutations({
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    skip() {
      localStorage.removeItem('firstTime');
      this.firstTime = false;
    },
    async deleteArticle(id) {
      this.deleteLoading = id;
      const result = await this.deleteArticleById(id);

      if (!result.ok) {
        // this.saveError = this.$t('ui.somethingWentWrong');
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.deleteLoading = null;
        return;
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.itemDeleted'),
      });
      this.refresh();
      this.deleteLoading = null;
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },
    async loadData(page, enableLoading = true) {
      this.loading = enableLoading;
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch(this.queryParams);
      // console.log('Article List PAGE', result, page, this.queryParams);
      this.loading = false;
    },
    async refresh() {
      this.loadData(this.page, false);
    },
    async unpublish(feedItemId) {
      console.log('unpublish');
      const result = await this.unpublishFeedItem({ feedItemId });

      if (!result.ok) {
        // this.saveError = this.$t('ui.somethingWentWrong');
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      await this.refresh();
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.articleUnpublished'),
      });
    },
    async saveNewItem() {
      this.loadingDraft = true;
      const input = {
        feedId: this.websiteId,
        title: '',
        content: [
          {
            body: '',
            id: 'p1',
            language: '',
            type: 'paragraph',
          },
        ],
        // publishEpisode: false,
      };

      const saveResult = await this.createProjectFeedItem(input);
      if (!saveResult.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        return;
      }
      // this.goToArticles(saveResult.feedItemId);
      // if (this.items.length === 0) {
      // wait for backend to process new item
      await this.delay(2000);
      // }
      this.loadingDraft = false;
      this.goTo(`/articles/${this.websiteId}/edit/${saveResult.feedItemId}`);
    },
    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.tokenCopied'),
      });
    },
    goTo(path) {
      this.$router.push({ path });
    },
  },
};
</script>
