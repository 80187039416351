<template>
  <div>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="divide-y divide-gray-300">
          <div class="py-7">
            <div>
              <div class="font-bold">{{$t('ui.wpPluginTitle')}}</div>
              <div class="text-[14px]">{{$t('ui.installWPPluginDescription')}}</div>
            </div>
            <div class="mt-4">
              <div class="rounded-md bg-blue-50 p-4">
                <div class="flex">
                  <div class="flex-shrink-0">
                  </div>
                  <div class="ml-3 flex-1 md:flex md:justify-between">
                    <p class="text-sm text-blue-700 font-bold">
                      <span class="select-none">{{$t('ui.apikey')}}: </span>
                      <span>{{jsonSettings['wp-token']}}</span>
                    </p>
                    <p class="mt-3 text-sm md:mt-0 md:ml-6">
                      <a href="#" @click="copyCode(jsonSettings['wp-token'])" class="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">{{$t('ui.copyToClipboard')}}</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div class="py-8 pt-4 space-x-4">
              <div class="md:space-x-3">
                <a :href="pluginLink" target="_blank" class="inline-flex text-white bg-black hover:bg-blue-700 items-center justify-center border border-transparent px-6 py-3 text-sm font-medium rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out">{{$t('ui.downloadPlugin')}}</a>
                <Button type="default" @click.native="showGuideModal = true">{{$t('ui.guideButton')}}</Button>
              </div>
            </div>
          </div>
        </div>
    </div>
    <Modal :show.sync="showDeleteModal">
      <div slot="header">{{$t('ui.deleteWebsiteTitle')}}</div>
      <div class="text-sm text-gray-600 mb-3" v-html="$t('ui.deleteWebsiteDesc')">{{}}</div>
        <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 block w-full" v-model="deleteConfirmation">
      <div class="text-right space-x-4">
        <Button @click.native="showDeleteModal = false"
          type="default"
          class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.cancel')}}</Button>
        <Button @click.native="deleteWebsite"
          :loading="loadingDelete"
          :disabled="deleteConfirmation !== 'delete'"
          type="danger"
          class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.confirmDeleteButton')}}</Button>
      </div>
    </Modal>
    <Modal :show.sync="showGuideModal" :width="'2xl'">
      <div slot="header"></div>
      <Guide :token="jsonSettings['wp-token']" :pluginUrl="pluginLink"></Guide>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';
import Guide from '../components/PluginGuide.vue';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    Guide,
  },
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      loadingDelete: false,
      confirmPassword: null,
      password: null,
      saveError: null,
      oldPassword: null,
      errorMessage: null,
      showGuideModal: false,
      showDeleteModal: false,
      deleteConfirmation: '',
      feedId: null,
      watermarkTemplate: false,
      feedPatch: {
        displayName: '',
        description: '',
        language: '',
        url: '',
        explicitContent: '',
      },
      jsonSettings: {},
      languages: [
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],

      audioPreviewUrl: null,
      playButton: null,
      activeTab: 'general',
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      customers: (state) => state.customer.list,
      selectedWebsite: (state) => state.website.selectedWebsite,
      websiteList: (state) => state.website.websiteList,
      userId: (state) => state.auth.userId,
      languageList: (state) => state.utils.languageList,
      pluginLink: (state) => state.settings.pluginLink,
      subscriptionStatus: (state) => state.billing.subscriptionStatus,
      selectedWebsiteAPTemplates: (state) => state.website.selectedWebsiteAPTemplates,
      selectedWebsiteAP: (state) => state.website.selectedWebsiteAP,
      selectedWebsiteAPTemplateOutro: (state) => state.website.selectedWebsiteAPTemplateOutro,
    }),
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
    isProject() {
      return this.selectedWebsite.type === 'PROJECT';
    },
    isEnterprise() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Enterprise';
    },
    isPremium() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Premium';
    },
    channelId() {
      return this.selectedWebsiteAP.id;
    },
    channelOutroTemplate() {
      if (!this.selectedWebsiteAPTemplates.outro) return {};
      return this.selectedWebsiteAPTemplates.outro;
    },
  },

  watch: {
    selectedWebsite(val) {
      // console.log('no website details', val, oldVal, this.feedPatch.displayName);
      if (this.feedPatch.displayName === '' || this.feedId !== val.id) {
        // console.log('no website details', val);
        this.init();
      }
    },
  },
  validators: {
    oldPassword: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  mounted() {
    this.deleteConfirmation = '';
    this.init();
  },
  methods: {
    ...mapMutations({
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      getWebsiteById: 'website/getWebsiteById',
      updateWebsite: 'website/updateWebsite',
      fetchWebsites: 'website/fetchWebsites',
      deleteWebsiteById: 'website/deleteWebsiteById',
      generateTextPreview: 'website/generateTextPreview',
      switchTemplateStatus: 'website/switchTemplateStatus',
    }),

    async init() {
      if (!this.selectedWebsite.id) return;
      const result = await this.getWebsiteById(this.selectedWebsite.id);
      // console.log('result', result);
      this.jsonSettings = result.settings;
      this.feedId = this.selectedWebsite.id;
      this.feedPatch = {
        displayName: result.displayName,
        description: result.description,
        language: result.language,
        url: result.mainSiteUrl,
        // explicitContent: result.explicitContent,
      };
      this.$nextTick();
      this.watermarkTemplate = this.selectedWebsiteAPTemplateOutro.isUsed;
      console.log('isUsed', this.selectedWebsiteAPTemplateOutro, this.selectedWebsiteAPTemplateOutro.isUsed, this.channelOutroTemplate, result);
      this.$refs.titleEditor.refreshContent(this.feedPatch.displayName);
    },

    async deleteWebsite() {
      this.loadingDelete = true;
      const result = await this.deleteWebsiteById(this.selectedWebsite.id);

      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.loadingDelete = false;
        return;
      }

      await this.fetchWebsites(this.customer);
      const websiteCount = this.websiteList.length;
      this.loadingDelete = false;
      if (websiteCount === 0) {
        this.goTo('/add-project');
        return;
      }

      const { id } = this.websiteList[0];
      localStorage.setItem(`w_${this.userId}`, id);
      await this.getWebsiteById(id);
      this.$router.push({ path: `/articles/${this.websiteList[0].id}/list` });
    },

    async save() {
      this.saveError = null;
      this.loading = true;
      const input = {
        ...this.feedPatch,
        id: this.selectedWebsite.id,
      };
      // console.log('update website', this.feedPatch);
      const result = await this.updateWebsite(input);
      this.loading = false;
      if (!result.ok) {
        // console.log('result', result);
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        this.saveError = this.$t('ui.somethingWentWrong');
        return;
      }
      await this.fetchWebsites(this.customer);
      const website = await this.getWebsiteById(this.selectedWebsite.id);

      this.feedPatch = {
        displayName: website.displayName,
        description: website.description,
        language: website.language,
        url: website.mainSiteUrl,
        // explicitContent: website.explicitContent,
      };
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.websiteUpdated'),
      });
      this.loading = false;
    },

    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.tokenCopied'),
      });
    },
    clearPlayer() {
      const audio = this.$refs.player;
      // const index = this.paragraphUrls.indexOf(this.audioPreviewUrl);
      this.playing += 1;
      if (this.paragraphUrls.length > 1 && this.playing < this.paragraphUrls.length) {
        this.audioPreviewUrl = this.paragraphUrls[this.playing];
        audio.load(); // call this to just preload the audio without playing
        audio.play();
        // alert(`sdasdasd ${index}`);
        return;
      }
      this.playing = 0;
      this.audioPreviewUrl = null;
      this.playButton.classList.remove('playing');
      // console.log('player cleared', this.playButton);
      this.clearButtons();
    },
    clearButtons() {
      this.$refs.titleEditor.clearPlay();
    },
    async playTitle(data) {
      const input = {
        feedId: this.selectedWebsite.id,
        text: this.feedPatch.displayName,
      };
      this.clearButtons();
      this.playButton = data.button;
      const result = await this.generateTextPreview(input);
      data.button.classList.remove('loading');
      data.button.classList.add('playing');
      // console.log('%cPLAY TITLE', 'color: red', data, result);
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }

      this.playUrl([result.url]);
    },
    async stopTitle() {
      const audio = this.$refs.player;
      // console.log('handleStopParagraph', audio, data);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.pause(); // call this to play the song right away
      this.clearButtons();
    },
    playUrl(urls) {
      this.playing = 0;
      this.paragraphUrls = urls;
      [this.audioPreviewUrl] = urls;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    goTo(path) {
      this.$router.push({ path });
    },
    async updateWatermarkTemplate() {
      if (!this.isPremium) return;
      this.watermarkTemplate = !this.watermarkTemplate;
      const result = await this.switchTemplateStatus({
        channelId: this.channelId,
        outro: {
          enabled: this.watermarkTemplate,
        },
      });
      console.log(result);
      // await this.init();
    },
  },
};
</script>
