<template>
  <button :type="nativeType" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out"
  :class="{
    'px-6 py-3 text-sm': size === '',
    'px-4 py-1 text-xs': size === 'sm',
    'px-4 py-2 text-xs': size === 'sm2',
    'px-6 py-3 text-base': size === 'md',
    'text-white bg-black hover:bg-blue-700': type === '',
    'text-white bg-green-500 hover:bg-green-700': type === 'secondary',
    'text-black bg-hm-brand hover:bg-yellow-300': type === 'brand',
    'text-white bg-red-500 hover:bg-red-800': type === 'danger',
    'text-gray-700 bg-gray-100 hover:bg-gray-200': type === 'default',
    'loading': loading,
    'pointer-events-none opacity-50': disabled,
    }"><slot></slot></button>
</template>
<script>

export default {
  name: 'Button',
  props: {
    nativeType: {
      type: String,
      default: 'button',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      default: '',
      type: String,
    },
    type: {
      default: '',
      type: String,
    },
  },
};
</script>
