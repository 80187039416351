<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.coupons')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="mt-5"><h2 class="text-lg font-medium text-gray-900">{{$t('ui.generateCoupon')}}</h2></div>
      <div class="p-4 rounded border-2 border-gray-300 border-dashed space-y-4">
        <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1 ">
          {{saveError}}
        </div>
        <div class="flex -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2 flex-auto">
            <label class="block mb-1" for="formGridCode_month">{{$t('ui.amount')}}</label>
            <input
              v-model="model.amount"
              class="w-full h-10 px-3 py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" type="text" id="formGridCode_month"/>
          </div>
          <div class="w-full px-2  flex-auto ">
            <label class="block mb-1" for="formGridCode_year">{{$t('ui.expiryDate')}}</label>
            <div class="flex">
              <div class="relative flex items-stretch flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                </div>
                <vue-ctk-date-time-picker
                  :onlyDate="true"
                  :autoClose="true"
                  :noHeader="true"
                  :minDate="(new Date()).toISOString()"
                  :formatted="'YYYY-MM-DD'"
                  v-model="model.expiryDate" format="YYYY-MM-DD">
                  <input type="text" v-model="model.expiryDate" class="focus:ring-blue-500 focus:border-blue-500 flex-grow min-w-0 block w-full rounded-none rounded-l-md border-gray-300" />
                </vue-ctk-date-time-picker>
              </div>
              <Button
                @click.native="addDays(30)"
                class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                <span>+30d</span>
              </Button>
              <Button
                @click.native="addDays(60)"
                class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-md text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500">
                <span>+60d</span>
              </Button>
            </div>
          </div>
          <div class="w-full px-2 flex-1 ">
            <label class="mb-1 text-white w-auto inline-block" for="formGridCode_cvc">&nbsp;_ </label>
            <Button
              @click.native="generate()"
              :loading="loadingBtn"
              size="sm"
              class="h-10 border-gray-800">{{$t('ui.generate')}}</Button>
          </div>
        </div>
        <!-- GENERATED CODE -->
        <div class="rounded-md bg-blue-50 p-4"  v-show="code">
          <div class="flex">
            <div class="flex-shrink-0">
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-sm text-blue-700 font-bold">
                {{code}}
              </p>
              <p class="mt-3 text-sm md:mt-0 md:ml-6">
                <Button
                  @click.native="copyCode(code)" class="whitespace-nowrap font-medium text-blue-700 hover:text-white">{{$t('ui.copyToClipboard')}}</Button>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5"><h2 class="text-lg font-medium text-gray-900">{{$t('ui.couponList')}}</h2></div>
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.created')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.expiresAt')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.amount')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.code')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.usedBy')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.used')}}
                    </th> -->
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="item in items" :key="item.id">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      {{moment.utc(item.createdAt).local().format('DD-MM-YYYY HH:mm')}}
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span
                        v-show="!moment(item.expiresAt).isBefore(new Date())"
                        v-tippy="{ content: moment.utc(item.expiresAt).local().format('DD-MM-YYYY HH:mm') }">
                        {{moment.utc(item.expiresAt).fromNow()}}
                      </span>
                      <span
                        v-show="moment(item.expiresAt).isBefore(new Date())"
                        v-tippy="{ content: moment.utc(item.expiresAt).format('DD-MM-YYYY HH:mm') }"
                        class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        expired
                      </span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm uppercase font-bold">
                      <span class="cursor-pointer">{{item.amount | intFormat}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm font-bold">
                      <span class="cursor-pointer" @click="copyCode(item.id)">{{item.id}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="users[item.redeemedBy]">{{users[item.redeemedBy].email}}</span>
                      <span
                        class="px-6 pl-0 whitespace-nowrap text-sm text-gray-400 block"
                        v-show="item.redeemedAt">{{moment.utc(item.redeemedAt).local().format('DD-MM-YYYY HH:mm')}}</span>
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span
                        class="px-6 pl-0 whitespace-nowrap text-sm text-gray-400 block"
                        v-show="item.redeemedAt">{{moment(item.redeemedAt).format('DD-MM-YYYY hh:mm')}}</span>
                    </td> -->
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-0 divide-y divide-gray-300">
        <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      code: null,
      moment,
      saveError: null,
      model: {
        expiryDate: moment().format('YYYY-MM-DD'),
        amount: 100,
      },
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      loading: false,
      loadingBtn: false,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.admin.couponList,
      users: (state) => state.admin.userListById,
      totalCount: (state) => state.admin.totalCount,
      hasNextPage: (state) => state.admin.hasNextPage,
    }),
    queryParams() {
      const input = {
        first: 25,
        orderBy: 'CREATED_AT_DESC',
        offset: this.offset,
        condition: {
          // isDeleted: false,
        },
      };
      return input;
    },
  },
  created() {
    this.setUseAsMode(false);
  },
  mounted() {
    this.fetchUsers();
    this.loadData(1);
  },
  methods: {
    ...mapActions({
      generateCoupon: 'admin/generateCoupon',
      fetch: 'admin/fetchCoupons',
      fetchUsers: 'admin/fetchUsers',
    }),
    ...mapMutations({
      setUseAsMode: 'ui/setUseAsMode',
    }),
    addDays(days) {
      this.model.expiryDate = moment.utc(this.model.expiryDate).add(days, 'days').format('YYYY-MM-DD');
      this.$forceUpdate();
    },
    async generate() {
      this.loadingBtn = true;
      const input = {
        amount: parseFloat(this.model.amount),
        expiryDate: moment(this.model.expiryDate).endOf('day').toISOString(),
      };
      const result = await this.generateCoupon(input);
      // console.log('generateResult', result);
      this.$forceUpdate();
      this.loadingBtn = false;
      if (!result.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        return;
      }
      this.refresh();
      this.code = result.couponId;
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('sysadmin.codeGenerated'),
      });
    },
    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.codeCopied'),
      });
    },
    async refresh() {
      this.loadData(this.page);
    },
    async loadData(page) {
      this.loading = true;
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch(this.queryParams);
      this.loading = false;
    },
  },
};
</script>
