<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.coupons')}} NEW
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <Button size="sm" @click.native="showAddCoupon = true">{{$t('ui.addCoupon')}}</Button>
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="p-4 rounded-md border-2 border-black space-y-4 mb-5" v-show="showAddCoupon">
        <div class="flex justify-between items-center">
          <h2 class="text-md font-bold text-gblack">{{$t('ui.addCoupon')}}</h2>
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="32.126" height="32.138" viewBox="0 0 32.126 32.138"
              class="hover:text-hm-blue cursor-pointer" @click="showAddCoupon = false">
              <g id="Group_5436" data-name="Group 5436" transform="translate(-1623.816 -238.04)">
                <path id="Path_266" data-name="Path 266" d="M19.563,3.5a27.326,27.326,0,0,1,7.306.821,11.292,11.292,0,0,1,5.106,2.83,11.292,11.292,0,0,1,2.83,5.106,27.326,27.326,0,0,1,.821,7.306,27.345,27.345,0,0,1-.821,7.308,11.309,11.309,0,0,1-2.829,5.11,11.294,11.294,0,0,1-5.106,2.834,27.272,27.272,0,0,1-7.307.823,27.272,27.272,0,0,1-7.307-.823A11.294,11.294,0,0,1,7.15,31.981a11.309,11.309,0,0,1-2.829-5.11A27.345,27.345,0,0,1,3.5,19.563a27.329,27.329,0,0,1,.821-7.306A11.292,11.292,0,0,1,7.15,7.151a11.292,11.292,0,0,1,5.106-2.83A27.328,27.328,0,0,1,19.563,3.5Z" transform="translate(1620.316 234.54)" fill="currentColor"/>
                <g id="Group_5388" data-name="Group 5388" transform="translate(1640 242.687) rotate(45)">
                  <path id="Path_43306" data-name="Path 43306" d="M14.8,6.8H10A.8.8,0,0,1,9.2,6V1.2a1.2,1.2,0,1,0-2.4,0V6a.8.8,0,0,1-.8.8H1.2a1.2,1.2,0,1,0,0,2.4H6a.8.8,0,0,1,.8.8v4.8a1.2,1.2,0,1,0,2.4,0V10a.8.8,0,0,1,.8-.8h4.8a1.2,1.2,0,0,0,0-2.4Z" fill="#fff"/>
                </g>
              </g>
            </svg>

          </div>
        </div>
        <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1 ">
          {{saveError}}
        </div>
        <div class="flex -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2 flex-auto">
            <label class="block mb-1" for="formGridCode_month">Enter coupon code</label>
            <div class="relative">
              <input
              v-model="model.name"
              class="w-full h-10 px-3 py-3 border-2 focus:border-blue-500 flex-grow block min-w-0 rounded-md sm:text-sm border-black ocus:border-2 " type="text" id="formGridCode_month"/>
              <div class="absolute right-2 cursor-pointer top-0 py-3 px-3 -mt-px text-hm-blue text-sm" @click="makeCode(5)">
                Generate
              </div>
            </div>
          </div>
          <!-- <div class="px-2 flex-1">
            <label class="block mb-1" for="formGridCode_year">Activation Date</label>
            <div class="flex w-48">
              <div class="relative items-stretch flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 items-center pointer-events-none">
                </div>
                <div class="relative">
                  <vue-ctk-date-time-picker
                    :onlyDate="true"
                    :autoClose="true"
                    :noHeader="true"
                    :minDate="(new Date()).toISOString()"
                    :formatted="'YYYY-MM-DD'"
                    v-model="activate_at" format="YYYY-MM-DD">
                    <input type="text" v-model="activate_at" class="focus:ring-blue-500 px-3 py-2 focus:border-blue-500 focus:border-2 sm:text-sm flex-grow border-2 min-w-0 block w-full rounded-md border-black" />
                  </vue-ctk-date-time-picker>

                  <div class="absolute right-3 top-3 pointer-events-none">
                     <svg xmlns="http://www.w3.org/2000/svg" width="17.629" height="18" viewBox="0 0 17.629 18">
                      <g id="Group_5427" data-name="Group 5427" transform="translate(-0.185)">
                        <path id="Path_43345" data-name="Path 43345" d="M6.112.964V4.229" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.929"/>
                        <path id="Path_43346" data-name="Path 43346" d="M11.88.964V4.229" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.929"/>
                        <path id="Path_43347" data-name="Path 43347" d="M1.4,13.532a3.818,3.818,0,0,0,3.572,3.41c1.286.062,2.6.094,4.024.094s2.739-.032,4.024-.094a3.818,3.818,0,0,0,3.572-3.41,34.293,34.293,0,0,0,.254-3.826A34.291,34.291,0,0,0,16.6,5.881a3.818,3.818,0,0,0-3.572-3.41c-1.286-.062-2.6-.094-4.024-.094s-2.739.032-4.024.094A3.818,3.818,0,0,0,1.4,5.881,34.277,34.277,0,0,0,1.15,9.706,34.279,34.279,0,0,0,1.4,13.532Z" stroke="#000" stroke-width="1.929"/>
                        <path id="Path_43348" data-name="Path 43348" d="M5.319,7.915a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43349" data-name="Path 43349" d="M5.319,7.915a.321.321,0,0,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43350" data-name="Path 43350" d="M5.319,12.114a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43351" data-name="Path 43351" d="M5.319,12.114a.321.321,0,1,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43352" data-name="Path 43352" d="M9,7.915a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43353" data-name="Path 43353" d="M9,7.915a.321.321,0,1,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43354" data-name="Path 43354" d="M12.681,7.915a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43355" data-name="Path 43355" d="M12.681,7.915a.321.321,0,0,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                      </g>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
          <div class="px-2">
            <label class="block mb-1" for="formGridCode_year">{{$t('ui.expiryDate')}}</label>
            <div class="flex w-48">
              <div class="relative items-stretch flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                </div>
                <div class="relative">
                  <vue-ctk-date-time-picker
                    :onlyDate="true"
                    :autoClose="true"
                    :noHeader="true"
                    :minDate="(new Date()).toISOString()"
                    :formatted="'YYYY-MM-DD'"
                    v-model="model.redeemBy" format="YYYY-MM-DD">
                    <input type="text" v-model="model.redeemBy" class="focus:ring-blue-500 px-3 py-2 focus:border-blue-500 sm:text-sm flex-grow border-2 min-w-0 block w-full rounded-md border-black" />
                  </vue-ctk-date-time-picker>

                  <div class="absolute right-3 top-3 pointer-events-none">
                     <svg xmlns="http://www.w3.org/2000/svg" width="17.629" height="18" viewBox="0 0 17.629 18">
                      <g id="Group_5427" data-name="Group 5427" transform="translate(-0.185)">
                        <path id="Path_43345" data-name="Path 43345" d="M6.112.964V4.229" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.929"/>
                        <path id="Path_43346" data-name="Path 43346" d="M11.88.964V4.229" fill="none" stroke="#000" stroke-linecap="round" stroke-width="1.929"/>
                        <path id="Path_43347" data-name="Path 43347" d="M1.4,13.532a3.818,3.818,0,0,0,3.572,3.41c1.286.062,2.6.094,4.024.094s2.739-.032,4.024-.094a3.818,3.818,0,0,0,3.572-3.41,34.293,34.293,0,0,0,.254-3.826A34.291,34.291,0,0,0,16.6,5.881a3.818,3.818,0,0,0-3.572-3.41c-1.286-.062-2.6-.094-4.024-.094s-2.739.032-4.024.094A3.818,3.818,0,0,0,1.4,5.881,34.277,34.277,0,0,0,1.15,9.706,34.279,34.279,0,0,0,1.4,13.532Z" stroke="#000" stroke-width="1.929"/>
                        <path id="Path_43348" data-name="Path 43348" d="M5.319,7.915a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43349" data-name="Path 43349" d="M5.319,7.915a.321.321,0,0,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43350" data-name="Path 43350" d="M5.319,12.114a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43351" data-name="Path 43351" d="M5.319,12.114a.321.321,0,1,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43352" data-name="Path 43352" d="M9,7.915a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43353" data-name="Path 43353" d="M9,7.915a.321.321,0,1,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43354" data-name="Path 43354" d="M12.681,7.915a.321.321,0,0,1,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                        <path id="Path_43355" data-name="Path 43355" d="M12.681,7.915a.321.321,0,0,0,0-.643" fill="none" stroke="#fff" stroke-width="1.929"/>
                      </g>
                    </svg>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="px-2">
            <label class="block mb-1" for="formGridCode_year">{{$t('ui.discount')}} (%)</label>
            <div class="flex w-36">
              <div class="relative items-stretch flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                </div>
                <div class="relative">
                  <input
                    v-model="model.percentOff"
                    class="w-full h-10 px-3 py-3 border-2 focus:border-blue-500 flex-grow block min-w-0 rounded-md sm:text-sm border-black ocus:border-2 " type="text" id="formGridCode_month"/>
                </div>
              </div>
            </div>
          </div>
          <div class="px-2">
            <label class="block mb-1" for="formGridCode_year">Use Limit <span class="opacity-50 text-xs">0 - no limit</span></label>
            <div class="flex w-36">
              <div class="relative items-stretch flex-grow focus-within:z-10">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                </div>
                <div class="relative">
                  <input
                    v-model="model.maxRedemptions"
                    class="w-full h-10 px-3 py-3 border-2 focus:border-blue-500 flex-grow block min-w-0 rounded-md sm:text-sm border-black ocus:border-2 " type="number" id="formGridCode_month"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="flex space-y-4 md:space-y-0">
          <div>
            <label class="block mb-1" for="formGridCode_year">Products</label>
            <div class="flex space-x-4">
              <!-- {{packages}} -->
              <div v-for="item in packages" :key="item.id"
                class="py-1 px-3 border-2 border-black rounded-md text-sm hover:bg-black hover:text-white cursor-pointer select-none"
                :class="{'border-white ring-3 ring-black bg-black text-white rounted-lg': isProductActive(item.product.id)}"
                @click="toggleProduct(model.appliesTo, item.product.id)">
                {{item.product.name}} <span class="font-bold"><span v-show="item.recurring.interval === 'month'">M</span><span v-show="item.recurring.interval === 'year'">Y</span></span>
              </div>
            </div>
          </div>
        </div>
        <div class="flex items-center justify-between">
          <div>
            <span class="error text-hm-red text-sm">
              {{saveError}}
            </span>
            <span class="text-hm-green text-sm" v-show="code">
              Coupon <strong>{{code}}</strong> added
            </span>
          </div>
          <Button
            @click.native="generate()"
            :loading="loadingBtn"
            size="sm"
            class="h-10 border-gray-800">{{$t('ui.save')}}</Button>
        </div>
      </div>
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8" :class="{loading}">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="">
              <table class="min-w-full">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-4 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.code')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.discount')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.plans')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.used')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.expiresAt')}}
                    </th>
                    <th scope="col" class="text-right px-6 pl-0 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.actions')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.used')}}
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in items" :key="item.id" class="hover:bg-gray-50">
                    <td class="px-6 pl-4 py-3 whitespace-nowrap text-sm">
                      {{item.code}}
                      <span class="bg-green-100 text-green-700 px-1 text-xs rounded-sm" v-if="item.active">active</span>
                    </td>
                    <td class="px-6 pl-0 py-3 whitespace-nowrap text-sm">
                      -{{item.coupon.percent_off}}%
                    </td>
                    <td class="px-6 pl-0 py-3 whitespace-nowrap text-sm uppercase font-bold">
                      <div v-if="item.metadata && item.metadata.productId">{{item.metadata.productName}}</div>
                      <div v-for="product in planName(item.metadata)" :key="product.id">
                        {{product.metadata.plan}} ({{product.metadata.interval}})
                      </div>
                    </td>
                    <td class="px-6 pl-0 py-3 whitespace-nowrap text-sm">
                      {{item.times_redeemed}}/{{item.max_redemptions}}
                    </td>
                    <td class="px-6 pl-0 py-3 whitespace-nowrap text-sm">
                      <div v-show="item.expires_at">
                        {{moment.unix(item.expires_at).local().format('YYYY-MM-DD')}}
                      </div>
                      <div v-show="!item.expires_at">---</div>
                    </td>
                    <td class="px-6 pl-0 py-3 whitespace-nowrap text-sm text-right">
                      <span class="text-red-500 cursor-pointer" @click="deleteCoupon(item)">{{$t('ui.delete')}}</span>
                    </td>
                    <!-- <td class="px-6 pl-0 py-3 whitespace-nowrap text-sm text-gray-500">
                      <span
                        class="px-6 pl-0 whitespace-nowrap text-sm text-gray-400 block"
                        v-show="item.redeemedAt">{{moment(item.redeemedAt).format('DD-MM-YYYY hh:mm')}}</span>
                    </td> -->
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  data() {
    return {
      showAddCoupon: false,
      code: null,
      moment,
      saveError: null,
      model: {
        percentOff: null,
        appliesTo: [],
        maxRedemptions: null,
        redeemBy: moment().format('YYYY-MM-DD'),
        name: '',
      },
      loading: false,
      loadingBtn: false,
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.admin.couponStripeList,
      users: (state) => state.admin.userListById,
      subscriptions: (state) => state.admin.subscriptions,
      packages: (state) => state.admin.packages,
      prices: (state) => state.admin.prices,
    }),
    isProductActive() {
      return (id) => this.model.appliesTo.indexOf(id) > -1;
    },
    planName() {
      return (metadata) => {
        const productIds = Object.keys(metadata);
        if (!this.packages) return [];
        const products = this.packages
          .filter((item) => productIds.indexOf(item.product.id) !== -1)
          .map((price) => price.product);
        return products;
      };
    },
  },
  created() {
    this.setUseAsMode(false);
  },
  mounted() {
    console.log('mounted new coupons');
    this.fetchUsers();
    this.loadData();
    this.fetchPrices();
  },
  methods: {
    ...mapActions({
      generateCoupon: 'admin/generateCoupon',
      fetchStripeCoupons: 'admin/fetchStripeCoupons',
      fetchUsers: 'admin/fetchUsers',
      fetchPrices: 'admin/fetchPrices',
      addStripeCoupon: 'admin/addStripeCoupon',
      deleteStripeCoupon: 'admin/deleteStripeCoupon',
    }),
    ...mapMutations({
      setUseAsMode: 'ui/setUseAsMode',
    }),
    addDays(days) {
      this.model.expiryDate = moment.utc(this.model.expiryDate).add(days, 'days').format('YYYY-MM-DD');
      this.$forceUpdate();
    },
    async generate() {
      this.loadingBtn = true;
      const input = {
        ...this.model,
        percentOff: parseFloat(this.model.percentOff),
        maxRedemptions: parseInt(this.model.maxRedemptions, 10),
        redeemBy: moment(this.model.redeemBy).endOf('day').format(),
      };
      const result = await this.addStripeCoupon(input);
      // console.log('generateResult', result);
      this.$forceUpdate();
      this.loadingBtn = false;
      if (!result.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        return;
      }
      this.refresh();
      this.code = result.code;
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.codeGenerated'),
      });
    },
    copyCode(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.codeCopied'),
      });
    },
    async deleteCoupon(item) {
      this.loading = true;
      console.log('delete', item);
      await this.deleteStripeCoupon(item.coupon.id);
      await this.refresh();
      this.loading = false;
    },
    async refresh() {
      this.loadData();
    },
    makeCode(length) {
      this.model.name = (Math.random() + 1).toString(36).substring(length);
    },
    toggleProduct(array, value) {
      const index = array.indexOf(value);

      if (index === -1) {
        array.push(value);
      } else {
        array.splice(index, 1);
      }
    },
    async loadData() {
      this.loading = true;
      const result = await this.fetchStripeCoupons();
      console.log('coupons result', result);
      this.loading = false;
    },
  },
};
</script>
