const awsconfig = {
  aws_project_region: 'eu-west-1',
  aws_cognito_region: process.env.VUE_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.VUE_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.VUE_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  aws_user_files_s3_bucket: 'hearme-ai-dev-audio-episodes',
  aws_user_files_s3_bucket_region: 'eu-west-1',
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: 'eu-west-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  audioUrl: 'xxxxxx',
  API: {
    graphql_headers: async () => ({
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    }),
    endpoints: [
      {
        name: 'mainApi',
        endpoint: process.env.VUE_APP_AWS_APPSYNC_SEARCH,
      },
      {
        name: 'test',
        endpoint: '',
      },
    ],
  },
};

export default awsconfig;
