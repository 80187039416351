import Vue from 'vue';
import VueRouter from 'vue-router';
import a404 from '../views/404.vue';
import a401 from '../views/401.vue';
// import Home from '../views/Home.vue';
import Wrapper from '../views/Main.vue';

// AUTH
import Login from '../views/AuthLogin.vue';
import Register from '../views/AuthRegister.vue';
import Reset from '../views/AuthReset.vue';

import Credits from '../views/Credits.vue';
import Alias from '../views/Alias.vue';
import Articles from '../views/Articles.vue';
import ArticlesList from '../views/ArticlesList.vue';
import ArticlesEdit from '../views/ArticlesEdit.vue';
import ArticlesStatsList from '../views/ArticlesStatsList.vue';
import Billing from '../views/Billing.vue';
import Voice from '../views/Voice.vue';
import Player from '../views/Player.vue';
import WebsiteSettings from '../views/WebsiteSettings.vue';
import WebsiteSettingsGeneral from '../views/WebsiteSettingsGeneral.vue';
import WebsiteSettingsPodcast from '../views/WebsiteSettingsPodcast.vue';
import WebsiteSettingsWordpress from '../views/WebsiteSettingsWordpress.vue';

import SysAdmin from '../views/SysAdmin.vue';
import SysAdminCustomers from '../views/SysAdminCustomers.vue';
import SysAdminAlias from '../views/SysAdminAlias.vue';
import SysAdminCustomer from '../views/SysAdminCustomer.vue';
import SysAdminCoupons from '../views/SysAdminCoupons.vue';
import SysAdminCouponsNew from '../views/SysAdminCouponsNew.vue';
import SysAdminCustomerArticles from '../views/SysAdminCustomerArticles.vue';
import SysAdminSystemSettings from '../views/SysAdminSystemSettings.vue';

import AccountProfile from '../views/AccountProfile.vue';

import isAuthenticated from '../guards/isAuthenticated';
import isAdmin from '../guards/isSU';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Wrapper,
    children: [
      {
        path: '/',
        redirect: '/sys-admin/list',
      },
      {
        path: 'add-blog',
        redirect: '/sys-admin/list',
      },
      {
        path: 'articles/:websiteId',
        component: Articles,
        beforeEnter: isAuthenticated,
        children: [
          {
            path: '',
            redirect: 'list',
          },
          {
            path: 'list',
            name: 'articlesList',
            component: ArticlesList,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'stats',
            name: 'stats',
            component: ArticlesStatsList,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'edit/:id',
            name: 'articlesEdit',
            component: ArticlesEdit,
            beforeEnter: isAuthenticated,
          },
        ],
      },
      {
        path: 'sys-admin',
        component: SysAdmin,
        beforeEnter: isAdmin,
        children: [
          {
            path: '',
            redirect: '/sys-admin/list',
          },
          {
            path: 'settings',
            name: 'settings',
            component: SysAdminSystemSettings,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'list',
            name: 'customers',
            component: SysAdminCustomers,
            beforeEnter: isAuthenticated,
          },
          {
            path: ':id/customer/details',
            name: 'customerDetails',
            component: SysAdminCustomer,
            beforeEnter: isAuthenticated,
          },
          {
            path: ':id/customer/articles/:websiteId',
            name: 'customerArticles',
            component: SysAdminCustomerArticles,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'coupons',
            name: 'coupons',
            component: SysAdminCoupons,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'newcoupons',
            name: 'newcoupons',
            component: SysAdminCouponsNew,
            beforeEnter: isAuthenticated,
          },
          {
            path: 'pronunciation',
            name: 'pronunciation',
            component: SysAdminAlias,
            beforeEnter: isAuthenticated,
          },
        ],
      },
      {
        path: 'credits',
        name: 'credits',
        component: Credits,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'pronunciation',
        name: 'pronunciation',
        component: Alias,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'player',
        name: 'player',
        component: Player,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'voice',
        name: 'voice',
        component: Voice,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'website-settings',
        name: 'website-settings',
        component: WebsiteSettings,
        children: [
          {
            path: '',
            redirect: 'general',
          },
          {
            path: 'general',
            name: 'settingsGeneral',
            component: WebsiteSettingsGeneral,
          },
          {
            path: 'podcast',
            name: 'settingsPodcast',
            component: WebsiteSettingsPodcast,
          },
          {
            path: 'wordpress',
            name: 'settingsWordpress',
            component: WebsiteSettingsWordpress,
          },
        ],
        beforeEnter: isAuthenticated,
      },
      {
        path: 'credits',
        name: 'billing',
        component: Billing,
        beforeEnter: isAuthenticated,
      },
      {
        path: 'account-profile',
        name: 'profile',
        component: AccountProfile,
        beforeEnter: isAuthenticated,
      },
    ],
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/resetpassword',
    name: 'Reset',
    component: Reset,
  },
  {
    path: '/401',
    name: '401',
    component: a401,
  },
  {
    path: '*',
    name: '404',
    component: a404,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
