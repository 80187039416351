<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate capitalize">
            {{customer.displayName}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <div class="mt-2 flex items-center text-sm text-gray-500"
            v-tippy="{ content: $t('ui.charactersTooltip', [formattedInt(baseCredits), formattedInt(additionalCredits)]) }">
                <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>coins</title><g ><path  d="M17,16c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,15.361,19.951,16,17,16z"></path> <path  d="M17,21c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,20.361,19.951,21,17,21z"></path> <ellipse  cx="17" cy="11" rx="7" ry="3"></ellipse> <ellipse cx="7" cy="3" rx="7" ry="3"></ellipse> <path d="M8,17.973C7.673,17.989,7.341,18,7,18c-2.951,0-5.403-0.639-7-1.712C0,17.034,0,17.526,0,18 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V17.973z"></path> <path d="M8,12.973C7.673,12.989,7.341,13,7,13c-2.951,0-5.403-0.639-7-1.712C0,12.034,0,12.526,0,13 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V12.973z"></path> <path d="M9.92,7.766C9.018,7.916,8.042,8,7,8C4.049,8,1.597,7.361,0,6.288C0,7.034,0,7.526,0,8 c0,1.657,3.134,3,7,3c0.341,0,0.674-0.014,1.003-0.034C8.015,9.703,8.71,8.606,9.92,7.766z"></path></g></svg>
                <span class="mr-1"></span>{{credits}}
                <!-- <span class="ml-2 inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-blue-100 text-blue-800 font-bold" v-if="subscriptionStatus.plan">{{subscriptionStatus.plan}}</span> -->
                <span class="ml-2 inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-blue-100 text-blue-800 font-bold relative z-10" v-if="subscriptionStatus">{{subscriptionStatus.plan}}</span>
                <span class="inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-gray-100 text-gray-800 font-bold -ml-1 -z-0 relative" v-if="subscriptionStatus && subscriptionStatus.interval">{{subscriptionStatus.interval}}</span>
                <span class="inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-gray-100 text-gray-800 font-bold -ml-1 -z-0 relative" v-if="subscriptionStatus && subscriptionStatus.endDate">{{subscriptionStatus.endDate.split(' ')[0]}}</span>
            </div>
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <div v-if="customer.metadata" class="flex items-center space-x-4">
            <div class="flex items-center space-x-2">
              <span class="text-sm">Preview</span>
              <button type="button"
                @click="disableEnablePPreview(customer)"
                :class="{'bg-gray-200': !isPreviewDisabled, 'bg-blue-600': isPreviewDisabled}"
                class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false">
                <span class="sr-only"></span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true"
                  :class="{'translate-x-0': !isPreviewDisabled, 'translate-x-5': isPreviewDisabled}"
                  class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
            <div class="flex items-center space-x-2">
              <span class="text-sm">Disabled</span>
              <button type="button"
                @click="disableEnableCustomer(customer)"
                :class="{'bg-gray-200': !isDisabled, 'bg-blue-600': isDisabled}"
                class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false">
                <span class="sr-only"></span>
                <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                <span aria-hidden="true"
                  :class="{'translate-x-0': !isDisabled, 'translate-x-5': isDisabled}"
                  class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">
      <!-- Websites -->
      <div class="flex flex-col">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 mt-6">
          <div class="px-4 sm:px-6 lg:px-8">
            <h2 class="text-lg font-medium text-gray-900">{{$t('ui.websites')}}</h2>
          </div>
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.dateAdded')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.website')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="item in websites" :key="item.id">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <!-- {{item}} -->
                      {{moment.utc(item.createdAt).local().format('DD-MM-YYYY HH:mm')}}
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900">
                        {{item.displayName}}
                        <div><a :href="item.url" target="_blank" class="text-hm-blue underline">{{item.url}}</a></div>
                      </div>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500 text-right">
                      <!-- TODO: Change to /articles/list -->
                      <button @click="goTo(`/sys-admin/${customerId}/customer/articles/${item.id}`)" type="button" class="btn-default btn-sm">{{$t('ui.useAs')}}</button>
                    </td>
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
            </div>
            <div class="text-center py-7" v-show="websites.length === 0">
              <svg
                class="m-auto mb-4"
                xmlns="http://www.w3.org/2000/svg" width="86.619" height="96.518" viewBox="0 0 86.619 96.518">
                <g id="Group_5645" data-name="Group 5645" transform="translate(8154.906 2288.151)">
                  <path id="Path_44179" data-name="Path 44179" d="M323.4,83.665a6.843,6.843,0,0,0-4.848-3.729,3.484,3.484,0,0,0,.813-1.512,4.056,4.056,0,0,0-2.774-4.883l-3.611-1.126a3.962,3.962,0,0,0-4.831,2.743,4.215,4.215,0,0,0-.005,1.858,5.62,5.62,0,0,0-4.354.973c-13.024,10.27-16.927,28.412-15.914,44.24a8.084,8.084,0,0,0,7.875,7.681,4.091,4.091,0,0,0,2,1.435,14.349,14.349,0,0,0,11.555-.878,3.661,3.661,0,0,0,1.456-1.451c.476.894.973,1.781,1.51,2.657,3.769,6.151,12.424,4.526,14.681-1.919C332.356,114.311,330.749,98.154,323.4,83.665Zm-15,10.3a7.974,7.974,0,0,0-1.334,1.09c.436-1.266.9-2.537,1.37-3.81Q308.414,92.6,308.4,93.964Z" transform="translate(-8418.152 -2340.232)" fill="#fab327"/>
                  <g id="Group_5644" data-name="Group 5644" transform="translate(-8427.423 -2344.887)">
                    <path id="Path_44168" data-name="Path 44168" d="M288.254,130.771c-.419-6.905-1.142-14.4-1.142-21.785,0-27.312,13.354-38.41,22.262-38.41,20.224,0,20.674,36.151,23.3,58.7" transform="translate(9.459 8.913)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44169" data-name="Path 44169" d="M345.08,104.758c.135,3.364.392,6.742.773,10.106-21.19.977-42.341,1.949-63.233,4.462-.252-4.021-.283-8.051-.38-12.066C303.286,105.933,324.025,106.135,345.08,104.758Z" transform="translate(6.036 32.927)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44170" data-name="Path 44170" d="M293.648,92.346c0-2.4,2.167-4.747,4.169-4.747,1.136,0,5.116,3.64,8.242,3.64,3.79,0,3.313-3.492,5.962-3.492,1.153,0,3.458,1.415,3.458,3.146" transform="translate(14.05 20.873)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44171" data-name="Path 44171" d="M273.648,88.09a72.179,72.179,0,0,0,9.364-1.7" transform="translate(0 20.022)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44172" data-name="Path 44172" d="M275.148,74.387c2.554,0,4.682,1.277,7.236,2.128" transform="translate(1.054 11.591)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44173" data-name="Path 44173" d="M283.148,62.887c1.7,1.277,2.979,3.405,4.682,5.108" transform="translate(6.674 3.513)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44174" data-name="Path 44174" d="M295.9,57.887a41.3,41.3,0,0,1,.426,7.236" transform="translate(15.631)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44175" data-name="Path 44175" d="M311.175,57.887a17.079,17.079,0,0,1-1.277,6.384" transform="translate(25.466)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44176" data-name="Path 44176" d="M314.9,70.691c2.554,0,4.682-1.7,6.81-2.554" transform="translate(28.979 7.201)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44177" data-name="Path 44177" d="M317.4,78.563a18.284,18.284,0,0,1,2.258-.128c1.316,0,2.593.1,3.889.1a14.721,14.721,0,0,0,3.643-.4" transform="translate(30.735 14.226)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                    <path id="Path_44178" data-name="Path 44178" d="M318.9,87.121c1.418,0,3.025-.567,4.571-.567a4.233,4.233,0,0,1,2.239.567" transform="translate(31.789 20.139)" fill="none" stroke="#1f2532" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                  </g>
                </g>
              </svg>

              <div class="text-xl text-gray-400">{{$t('ui.userNoWebsites')}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="p-4 mt-5 rounded border-2 border-gray-300 border-dashed space-y-4">
        <div class="flex -mx-2 space-y-4 md:space-y-0">
          <div class="w-full px-2 flex-auto items-center flex">
            <label class="font-bold self-center block" for="amount">{{$t('ui.addRemoveCredits')}}</label>
          </div>
          <div class="w-full px-2  flex-auto ">
            <div class="flex">
              <div class="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  v-model="creditsModel.amount"
                  class="w-full h-10 px-3 py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" type="text" id="amount"/>
              </div>
            </div>
          </div>
          <div class="w-full px-2  flex-auto ">
            <div class="flex">
              <div class="relative flex items-stretch flex-grow focus-within:z-10">
                <input
                  v-model="creditsModel.reason"
                  placeholder="Enter reason"
                  class="w-full h-10 px-3 py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" type="text" id="reason"/>
              </div>
            </div>
          </div>
          <div class="w-full px-2 flex-1">
            <Button
              @click.native="applyCredits()"
              :loading="loadingBtn"
              size="sm"
              class="h-10 border-gray-800">{{$t('ui.apply')}}</Button>
          </div>
        </div>
      </div>
      <!-- Billing history -->
      <div class="flex flex-col mt-8">
        <div class="overflow-x-auto sm:-mx-6 lg:-mx-8 ">
          <div class="px-4 sm:px-6 lg:px-8">
            <h2 class="text-lg font-medium text-gray-900">{{$t('ui.billingHistory')}}</h2>
          </div>
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="hidden md:block x-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.date')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.operation')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.details')}}
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.amount')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.balance')}}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="item in items" :key="item.billedAt">
                    <td class="px-6 pl-0 py-4 text-sm hidden md:block">
                      {{moment(item.billedAt).format('DD-MM-YYYY HH:mm')}}
                    </td>
                    <td class="px-6 pl-0 py-4 ">
                      <div class="md:hidden block text-sm">
                        {{moment(item.billedAt).format('DD-MM-YYYY HH:mm')}}
                      </div>
                      <div class="text-sm text-gray-900 font-bold">{{$t('ui.' + item.operationType)}}</div>
                      <div class="text-gray-500 text-sm min-w-[400px] ">{{operationDetails(item)}}</div>
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap">
                      <div class="text-sm text-gray-900 uppercase">{{item.operationDetails}}</div>
                    </td> -->
                    <td class="px-6 pl-0 py-4 whitespace-nowrap  text-sm"
                      :class="{ 'text-red-500': isNegative(item) < 0, 'text-green-600': isNegative(item) > 0 }">
                      <span v-show="isNegative(item) < 0">-</span>{{item.amount | intFormat}}
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500"
                      v-tippy="{ content: $t('ui.charactersAmounBillingTooltip', [formattedInt(item.creditsAfter), formattedInt(item.additionalCreditsAfter)]) }">
                      {{(parseInt(item.creditsAfter) + parseInt(item.additionalCreditsAfter))  | intFormat}}
                    </td>
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="max-w-7xl mx-auto pt-0 divide-y divide-gray-300">
        <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions, mapMutations } from 'vuex';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      moment,
      creditsModel: {
        customerId: this.customerId,
        amount: 100,
        reason: '',
      },
      loadingBtn: false,
      customerId: null,
      isDisabled: true,
      isPreviewDisabled: false,
      // customer: {
      //   date: new Date(),
      //   name: 'John Doe',
      //   email: 'john@mail.com',
      //   amount: 100,
      //   balance: 100,
      //   websites: [
      //     {
      //       id: 1,
      //       name: 'Website name',
      //       url: 'www.website.com',
      //       date: new Date(),
      //     },
      //     {
      //       id: 2,
      //       name: 'Website name',
      //       url: 'www.website.com',
      //       date: new Date(),
      //     },
      //   ],
      // },
      // items: [
      //   {
      //     date: new Date(),
      //     operation: 'Credits added',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     operation: 'Article generated',
      //     amount: -12,
      //     balance: 88,
      //   },
      // ],
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      selectedOrder: 'BILLED_AT',
      orderDirString: 'DESC',
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
    };
  },
  computed: {
    ...mapState({
      websites: (state) => state.admin.customerWebsiteList,
      customerList: (state) => state.admin.customerList,
      totalCount: (state) => state.billing.count,
      hasNextPage: (state) => state.billing.hasNextPage,
      items: (state) => state.billing.billingHistory,
      customer: (state) => state.admin.userCustomer,
      combinedCredits: (state) => state.admin.combinedCredits,
      additionalCredits: (state) => state.admin.additionalCredits,
      subscriptionStatus: (state) => state.admin.subscriptionStatus,
      baseCredits: (state) => state.admin.credits,
    }),
    credits() {
      const baseCredits = this.baseCredits || 0;
      const additionalCredits = this.additionalCredits || 0;
      return parseInt(baseCredits, 10) + parseInt(additionalCredits, 10);
    },
    formattedInt() {
      return (str) => {
        if (!str) return 0;
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      };
    },
    metadata() {
      return this.customer.metadata;
    },
    operationDetails() {
      return (item) => {
        const type = item.operationType;
        let details = '';
        switch (type) {
          case 'block_credits_for_article_generation':
            details = item.operationDetails.episodeTitle;
            break;
          case 'give_credits_back_after_fail':
            details = `${item.operationDetails.episodeTitle} for ${item.operationDetails.episodeTitle}`;
            break;
          case 'coupon_redeem':
            details = item.operationDetails.couponCode;
            break;
          default:
            details = '';
            break;
        }
        return details;
      };
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    isNegative() {
      return (item) => item.creditsAfter - item.creditsBefore;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          // isDeleted: false,
        },
      };

      if (this.customerId) {
        input.condition.customerId = this.customerId;
      }

      return input;
    },
    websiteQueryParams() {
      const input = {
        first: 25,
        // orderBy: this.orderByString,
        offset: 0,
        condition: {
          // isDeleted: false,
        },
      };

      if (this.customerId) {
        input.condition.customerId = this.customerId;
      }

      return input;
    },
  },
  beforeMount() {
    this.setSidebarVisibility(true);
  },
  created() {
    this.customerId = this.$route.params.id;
    // console.log('params ', this.$route.params);
  },
  watch: {
    // eslint-disable-next-line
    queryParams: function () {
      // this.loadData(1);
    },
    // eslint-disable-next-line
    customerId: async function () {
      await this.fetchCustomer(this.customerId);
      const { metadata } = this.customer;
      console.log('hero.hasOwnProperty();', Object.prototype.hasOwnProperty.call(metadata, 'disabled'), metadata, this.metadata);
      this.isDisabled = metadata.disabled;
      this.isPreviewDisabled = metadata.blockPreviewGenerating;
      // if (this.customer && )
      await this.loadData(1);
      await this.loadWebsites();
    },
    // eslint-disable-next-line
    metadata: function () {
      console.log('metadata', this.metadata, this.customer);
    },
  },
  async mounted() {
    this.setUseAsMode(false);
    // await this.fetchCustomer(this.customer.id);
  },
  methods: {
    ...mapActions({
      fetchCustomer: 'admin/fetchCustomer',
      fetch: 'billing/fetchHistory',
      fetchWebsites: 'admin/fetchCustomerWebsites',
      getCustomerSubscriptionStatus: 'admin/getCustomerSubscriptionStatus',
      addCredits: 'customer/addCredits',
      removeCredits: 'customer/removeCredits',
      disableEnableUser: 'admin/disableEnableUser',
      disableEnablePreview: 'admin/disableEnablePreview',
    }),
    ...mapMutations({
      setUseAsMode: 'ui/setUseAsMode',
      setSidebarVisibility: 'ui/setSidebarVisibility',
    }),
    async disableEnableCustomer(customer) {
      console.log('disable user', customer, this.isDisabled);
      // const shouldDisable = Object
      const input = {
        customerId: customer.id,
        enabled: this.isDisabled,
      };
      await this.disableEnableUser(input);
      this.isDisabled = !this.isDisabled;
      await this.fetchCustomer(this.customerId);
    },
    async disableEnablePPreview(customer) {
      console.log('disable user preview', customer, this.isPreviewDisabled);
      // const shouldDisable = Object
      const input = {
        customerId: customer.id,
        enabled: !this.isPreviewDisabled,
      };
      await this.disableEnablePreview(input);
      this.isPreviewDisabled = !this.isPreviewDisabled;
      await this.fetchCustomer(this.customerId);
    },
    async applyCredits() {
      this.loadingBtn = true;
      this.creditsModel.customerId = this.customerId;
      // console.log('applyCredits', this.creditsModel);
      const { amount } = this.creditsModel;
      const response = amount > 0 ? await this.addCredits({
        ...this.creditsModel,
        amount: parseFloat(amount),
      }) : await this.removeCredits({
        ...this.creditsModel,
        amount: Math.abs(this.creditsModel.amount),
      });

      await this.loadData(this.page);
      this.loadingBtn = false;
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('sysadmin.creditsAdded'),
      });
      console.log('response', response);
    },
    goTo(path) {
      this.$router.push({ path });
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },

    // Customer Websites
    async loadWebsites() {
      await this.fetchWebsites({
        ...this.websiteQueryParams,
        first: 100,
        offset: 0,
      });
    },

    // Billing History
    async loadData(page) {
      this.loading = true;
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch(this.queryParams);
      this.loading = false;
      // console.log('=====', result);
    },
  },
};
</script>
