<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.customers')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56" :class="{loading: loading}">
      <div class="-mt-6 w-full mb-1">
        <div class="relative text-gray-400 focus-within:text-gray-500">
          <div class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd" />
            </svg>
          </div>
          <input id="search"  v-on:keyup.enter="refresh" v-model="searchQuery" class="block w-full bg-white py-3 pl-10 pr-3 border border-gray-300 rounded leading-5 text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-1 focus:ring-hm-secondary focus:border-hm-secondary focus:placeholder-gray-500 sm:text-sm" :placeholder="'Search by Name'" type="search" name="search">
        </div>
      </div>
      <!-- This example requires Tailwind CSS v2.0+ -->
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer flex justify-between items-center" @click="orderBy('CREATED_AT')">
                      <div>{{$t('ui.dateAdded')}}</div>
                      <div v-if="selectedOrder === 'CREATED_AT'">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" v-if="orderDirString === 'ASC'">
                          <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" v-if="orderDirString === 'DESC'">
                          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                      </div>
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer" @click="orderBy('DISPLAY_NAME')">
                      <div class="flex justify-between items-center">
                        <div>{{$t('ui.name')}}</div>
                          <div v-if="selectedOrder === 'DISPLAY_NAME'">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" v-if="orderDirString === 'ASC'">
                              <path fill-rule="evenodd" d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z" clip-rule="evenodd" />
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" viewBox="0 0 20 20" fill="currentColor" v-if="orderDirString === 'DESC'">
                              <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                            </svg>
                          </div>
                      </div>
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.balance')}}
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    @click="goTo(`/sys-admin/${item.id}/customer/details`)"
                    v-for="item in items" :key="item.id"
                    class="hover:text-hm-blue cursor-pointer hover:f">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      {{moment.utc(item.createdAt).local().format('DD-MM-YYYY HH:mm')}}
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap">
                      <div class="text-sm">{{item.displayName}}
                        <span class="inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-blue-100 text-blue-800 font-bold relative z-20" v-if="item.metadata && item.metadata.subscriptionStatus">{{item.metadata.subscriptionStatus.plan}}</span>
                        <span class="inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-gray-300 text-gray-800 font-bold -ml-1 z-10 relative" v-if="item.metadata && item.metadata.subscriptionStatus && item.metadata.subscriptionStatus.interval">{{item.metadata.subscriptionStatus.interval}}</span>
                        <span class="inline-flex items-center uppercase px-2.5 py-px rounded text-[10px] bg-gray-100 text-gray-800 font-bold -ml-1 -z-0 relative" v-if="item.metadata && item.metadata.subscriptionStatus && item.metadata.subscriptionStatus.endDate">{{item.metadata.subscriptionStatus.endDate.split(' ')[0]}}</span>
                        <span class="inline-flex relative top-1.5 left-2" v-if="item.metadata.blockPreviewGenerating">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="h-5 w-5"><path fill="#ff0000" fill-rule="evenodd" d="M24 46.5C11.5736 46.5 1.5 36.4264 1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5ZM20.5096 14.2213C18.0739 13.251 15.7864 14.717 15.4339 17.315C15.1952 19.0749 15 21.3301 15 24C15 26.6699 15.1952 28.9251 15.4339 30.685C15.7864 33.283 18.0739 34.749 20.5096 33.7787C22.0437 33.1676 24.0575 32.2507 26.6471 30.875C29.093 29.5756 31.0253 28.4126 32.5249 27.429C35.1777 25.689 35.1777 22.311 32.5249 20.571C31.0253 19.5874 29.093 18.4244 26.6471 17.125C24.0575 15.7493 22.0437 14.8324 20.5096 14.2213Z" clip-rule="evenodd"></path></svg></span>
                        <span  class="inline-flex relative top-2 left-4" v-if="item.metadata.disabled">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="h-5 w-5"><path fill="#000001" fill-rule="evenodd" d="M34.5008 22.0498C27.6248 22.0498 22.0508 27.6239 22.0508 34.4998C22.0508 41.3757 27.6248 46.9498 34.5008 46.9498C41.3767 46.9498 46.9508 41.3757 46.9508 34.4998C46.9508 27.6239 41.3767 22.0498 34.5008 22.0498ZM30.1094 27.2791C31.3892 26.4991 32.8925 26.0498 34.5008 26.0498C39.1676 26.0498 42.9508 29.833 42.9508 34.4998C42.9508 36.1081 42.5015 37.6114 41.7215 38.8912L30.1094 27.2791ZM27.2808 30.1072C26.5004 31.3872 26.0508 32.891 26.0508 34.4998C26.0508 39.1666 29.834 42.9498 34.5008 42.9498C36.1096 42.9498 37.6134 42.5002 38.8933 41.7198L27.2808 30.1072Z" clip-rule="evenodd"></path><path fill="#000001" d="M5.5 13C5.5 6.37258 10.8726 1 17.5 1C24.1274 1 29.5 6.37258 29.5 13C29.5 17.2173 27.3244 20.9266 24.0344 23.0666C20.9402 25.9004 19 29.9736 19 34.5C19 36.0613 19.2309 37.5688 19.6603 38.99C18.9762 38.9965 18.2568 39 17.5001 39C10.937 39 7.17431 38.7399 5.01836 38.461C3.91101 38.3177 2.97256 37.7837 2.37153 36.947C1.77153 36.1117 1.56375 35.053 1.76717 33.962C2.69379 28.9927 5.9535 24.8399 10.3743 22.6562C7.4174 20.4706 5.5 16.9592 5.5 13Z"></path></svg>
                        </span>
                        <div class="text-gray-400" v-if="item.usersByCustomerId.nodes[0]">{{item.usersByCustomerId.nodes[0].email}}</div>
                      </div>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500"
                     v-tippy="{ content: $t('ui.charactersTooltip', [formattedInt(item.credits), formattedInt(item.additionalCredits)]) }">
                      {{(parseInt(item.credits) + parseInt(item.additionalCredits)) | intFormat}}
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- PAGINA -->
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-0 divide-y divide-gray-300">
      <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      moment,
      // items: [
      //   {
      //     date: new Date(),
      //     name: 'John Doe',
      //     email: 'john@mail.com',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     name: 'Joseph Well',
      //     email: 'josephWell@gmail.com',
      //     balance: 88,
      //   },
      // ],
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      loading: false,
      selectedOrder: 'CREATED_AT',
      searchQuery: '',
      // orderDirString: 'DESC',
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.admin.customerList,
      customerList: (state) => state.admin.customerList,
      totalCount: (state) => state.admin.totalCount,
      hasNextPage: (state) => state.admin.hasNextPage,
    }),
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    formattedInt() {
      return (str) => str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          // isDeleted: false,
        },
      };

      if (this.libraryId) {
        input.condition.libraryId = this.libraryId;
      }

      if (this.searchQuery) {
        input.filter = {
          displayName: {
            includes: this.searchQuery,
          },
        };
      }

      return input;
    },
  },
  watch: {
    // eslint-disable-next-line
    searchQuery: function () {
      // console.log('sdasdsad', this.searchQuery);
      const refresh = _.debounce(this.loadData(1), 1150);
      refresh();
    },
  },
  created() {
    this.setUseAsMode(false);
  },
  mounted() {
    this.loadData(1);
  },
  methods: {
    ...mapActions({
      fetch: 'admin/fetchCustomers',
      disableEnableUser: 'admin/disableEnableUser',
    }),
    ...mapMutations({
      setUseAsMode: 'ui/setUseAsMode',
    }),
    goTo(path) {
      this.$router.push({ path });
    },
    async disableEnableCustomer(customer) {
      console.log('disable user', customer);
      // const shouldDisable = Object
      const input = {
        customerId: customer.id,
        enabled: customer.metadata.enabled,
      };
      await this.disableEnableUser(input);
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },
    async refresh() {
      this.loadData(this.page);
    },
    async loadData(page) {
      this.loading = true;
      this.offset = page * this.pageSize - this.pageSize;
      await this.fetch({ payload: this.queryParams, loadMore: false });
      this.loading = false;
    },
  },
};
</script>
