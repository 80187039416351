<template>
  <!-- Profile dropdown -->
  <div class="ml-3 relative" v-click-outside="closeUserMenu">
    <div>
      <button
        @click="showDropdown = !showDropdown"
        class="flex text-sm rounded-full text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-hm-gray-light focus:ring-hm-brand" id="user-menu" aria-haspopup="true">
        <span class="sr-only">Open user menu</span>
        <!-- <img class="h-8 w-8 rounded-full" :src="avatar" alt=""> -->
        <avatar :username="userProfile.displayName || userEmail" :size="36"></avatar>
      </button>
    </div>
    <transition
      name="custom-classes-transition"
      enter-class="transform opacity-0 scale-95"
      enter-active-class="transition ease-out duration-100"
      enter-to-class="transform opacity-100 scale-100"
      leave-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-show="showDropdown"
        class="origin-top-right absolute right-0 mt-2 rounded-md shadow-lg pt-0 pb-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
        <div class="">
          <div class="sm:flex sm:space-x-2 bg-gray-100 py-2 px-3 mb-1  mt-0 rounded-md">
            <div class="flex-shrink-0">
              <!-- <img class="mx-auto h-20 w-20 rounded-full" :src="userProfile.imageUrl" alt="" /> -->
              <!-- <avatar :username="userProfile.displayName || userEmail" :size="44"></avatar> -->
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:text-left pr-5">
              <p class="text-md font-bold text-gray-900 sm:text-md">{{ userProfile.displayName }}</p>
              <p class="text-xs font-medium text-gray-600">{{ userProfile.email }}</p>
            </div>
          </div>
          <div class="px-1">
            <router-link tag="a" :to="'/account-profile'"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
              <fa :icon="['far', 'user']" class="ml-1 mr-2"></fa>
              {{$t('ui.profile')}}
            </router-link>
            <router-link tag="a" :to="'/credits'"
              v-show="!isSuperAdmin"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
              <fa :icon="['far', 'receipt']" class="ml-1 mr-2"></fa>
              {{$t('ui.creditsUsage')}}
            </router-link>
            <router-link tag="a" :to="'/sys-admin'"
              v-show="isSuperAdmin"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md">
              <fa :icon="['far', 'tools']" class="ml-1 mr-2"></fa>
              {{$t('ui.sysAdmin')}}
            </router-link>
            <a href="#"
              @click="logOut"
              class="flex items-center block p-2 transition duration-300 ease-in-out hover:bg-gray-100 dark:hover:bg-dark-3 rounded-md pl-2">
              <fa :icon="['far', 'sign-out']" class="ml-1 mr-2"></fa>
              <span>{{$t('ui.logout')}}</span>
            </a>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import Avatar from 'vue-avatar';
import { mapState } from 'vuex';

export default {
  name: 'TopNavUserDropdown',
  components: {
    Avatar,
  },
  props: {
    name: {
      type: String,
      default: 'John Doe',
    },
    email: {
      type: String,
      default: '....',
    },
  },
  data() {
    return {
      credits: 2512,
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      libraryId: null,
      notifications: [
        {
          time: new Date(),
          title: 'Article added',
          message: 'Lorem ipsum sit dolor',
          type: 'info',
        },
      ],
      logoutChannel: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userProfile: (state) => state.auth.userProfile,
      isSuperAdmin: (state) => state.auth.isSuperAdmin,
    }),
    queryParams() {
      const input = {
        first: 125,
        orderBy: 'DISPLAY_NAME_ASC',
        offset: 0,
        condition: {
          isDeleted: false,
        },
      };
      return input;
    },
    avatar() {
      return 'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixqx=94Y6oZJI7Y&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80';
      // return `https://avatars.dicebear.com/api/initials/${this.userEmail}.svg`;
    },
  },
  watch: {
    userEmail() {
      console.log('%c nav dropdown watch', 'color: lime', this.userEmail);
    },
  },
  mounted() {
    console.log('%c nav dropdown mounted', 'color: lime', this.userEmail);
    if (this.userEmail) this.createLogoutChannel(this.userEmail);
  },
  methods: {
    toggleMainMenu() {
      // console.log('toggleMainMenu');
      this.showMobileNav = !this.showMobileNav;
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
    createLogoutChannel(email) {
      console.log('%c nav dropdown createLogoutChannel', 'color: lime', email);
      this.logoutChannel = new BroadcastChannel('hm-logout-channel');
      this.logoutChannel.onmessage = (e) => {
        console.log('%c nav dropdown on message', 'color: lime');
        if (e.data.cmd === 'logout') {
          this.lougOut();
        }
      };
    },
    async logOut() {
      await this.$Amplify.Auth.signOut();
      // this.setIsLoggedIn(false);
      this.logoutChannel.postMessage({ cmd: 'logout', user: 'Joseph Well' });
      this.$router.push({ path: '/login' });
    },
  },
};
</script>
