<template>
  <div>
    <header class="py-8">
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class=" text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.profile')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.yourProfile')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-sm font-medium text-center text-black border-b border-gray-200">
        <ul class="flex flex-wrap -mb-px">
            <li class="mr-2">
                <a @click="activeTab = 'profile'" :class="{'border-b-2 border-black font-bold active': isActive('profile')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500">{{$t('ui.profile')}}</a>
            </li>
            <li class="mr-2">
                <a @click="activeTab = 'subscription'" :class="{'border-b-2 border-black font-bold active': isActive('subscription')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500" aria-current="page">{{$t('ui.subscription')}}</a>
            </li>
            <li class="mr-2">
                <a @click="activeTab = 'billing'" :class="{'border-b-2 border-black font-bold active': isActive('billing')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500">{{$t('ui.billing')}}</a>
            </li>
        </ul>
      </div>
    </div>

    <!-- PROFILE -->
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 divide-y divide-gray-300" v-show="isActive('profile')">
      <div class="">
        <div class="bg-white space-y-6 py-8">
          <div>
            <h3 class="font-bold">{{$t('ui.profileDetailsTitle')}}</h3>
            <p class="text-[14px]">{{$t('ui.profileDetailsDesc')}}</p>
          </div>

          <div class="flex flex-col space-y-4">
            <div class="col-span-3 sm:col-span-2">
              <label for="name" class="block text-sm font-medium text-gray-700">
                {{$t('ui.nameLabel')}}
              </label>
              <div class="mt-1 rounded-md flex">
                <input type="text" name="name" id="name"
                v-model="userProfile.displayName"
                class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
              </div>
            </div>

            <div class="col-span-3">
              <label for="about" class="block text-sm font-medium text-gray-700">
                {{$t('ui.emailLabel')}}
              </label>
              <div class="mt-1">
                <input :value="userEmail" type="email" name="email" id="email" autocomplete="email" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 disabled w-full" disabled>
              </div>
            </div>
            <div>
              <div class="relative flex items-start">
                <div class="flex items-center h-5">
                  <input v-model="newsletterOptIn" id="newsletter" aria-describedby="newsletter-description" name="newsletter" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-black-300 rounded">
                </div>
                <div class="ml-3 text-sm">
                  <label for="newsletter" class="font-medium">
                  <span id="newsletter-description">{{$t('ui.newsletterOptIn')}} </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-8 text-right">
          <button type="submit" class="bg-hm-secondary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            @click="save" :class="{loading}">
            {{$t('ui.save')}}
          </button>
        </div>
      </div>
      <div class="">
        <div class="bg-white py-6 space-y-6">
          <div>
            <h3 class="font-bold">{{$t('ui.userPasswordChangeTitle')}}</h3>
            <p class="text-[14px]">{{$t('ui.userPasswordChangeDesc')}}</p>
          </div>

          <div class="message text-red-500 mt-1" v-if="errorMessage">
            {{$t('ui.incorrectPassword')}}
          </div>
          <div class="flex flex-col space-y-4">
            <div class="col-span-3 sm:col-span-2">
              <label for="password"
                class="block text-sm font-medium">
                {{$t('ui.oldPasswordLabel')}}
              </label>
              <div class="mt-1 relative">
                <input v-model="oldPassword"
                id="oldPassword" name="oldPassword" :type="showOldPassword ? 'text' : 'password'" autocomplete="current-password" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow min-w-0 rounded-lg sm:text-sm border-gray-300 appearance-none block w-full"
                :class="{'ring-2 ring-green-500 border-green-500': oldPassword && validation.isPassed('oldPassword'), 'border-red-500' : oldPassword && validation.hasError('oldPassword')}">
                <div class="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none"
                  v-if="oldPassword && validation.hasError('oldPassword')">
                  <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  @click="showOldPassword = !showOldPassword"
                  v-tippy="{content: $t('ui.showPassword')}">
                  <svg xmlns="http://www.w3.org/2000/svg" v-show="!showOldPassword" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" v-show="showOldPassword" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                </div>
              </div>
              <div class="message text-red-500 text-xs mt-1">
                {{ validation.firstError('oldPassword') }}
              </div>
            </div>
            <div class="">
              <label for="password"
                class="block text-sm font-medium">
                {{$t('ui.newPasswordLabel')}}
              </label>
              <div class="mt-1 relative">
                <input v-model="password"
                id="password" name="password" :type="showNewPassword ? 'text' : 'password'"  autocomplete="current-password" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 w-full"
                :class="{'ring-2 ring-green-500 border-green-500': password && validation.isPassed('password'), 'border-red-500' : password && validation.hasError('password')}">
                <div class="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none"
                  v-if="password && validation.hasError('password') && validation.isTouched('password')">
                  <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  @click="showNewPassword = !showNewPassword"
                  v-tippy="{content: $t('ui.showPassword')}">
                  <svg xmlns="http://www.w3.org/2000/svg" v-show="!showNewPassword" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" v-show="showNewPassword" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                </div>
              </div>
              <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
                {{ validation.firstError('password') }}
              </div>
            </div>
            <div class="">
              <label for="password"
                class="block text-sm font-medium">
                {{$t('ui.confirmPasswordLabel')}}
              </label>
              <div class="mt-1 relative">
                <input
                v-model="confirmPassword"
                id="confirmPassword" name="confirmPassword" :type="showConfirmPassword ? 'text' : 'password'"  autocomplete="current-password" required class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 w-full"
                :class="{'ring-2 ring-green-500 border-green-500': confirmPassword && validation.isPassed('confirmPassword'), 'border-red-500' : confirmPassword && validation.hasError('confirmPassword')}">
                <div class="absolute inset-y-0 right-0 pr-9 flex items-center pointer-events-none" v-if="confirmPassword && validation.hasError('confirmPassword') && validation.isTouched('confirmPassword')">
                  <svg class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                  </svg>
                </div>
                <div class="absolute inset-y-0 right-0 pr-3 flex items-center"
                  @click="showConfirmPassword = !showConfirmPassword"
                  v-tippy="{content: $t('ui.showPassword')}">
                  <svg xmlns="http://www.w3.org/2000/svg" v-show="!showConfirmPassword" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                      <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" v-show="showConfirmPassword" class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                      <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                    </svg>
                </div>
              </div>
              <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('confirmPassword')">
                {{ validation.firstError('confirmPassword') }}
              </div>
            </div>
          </div>
        </div>
        <div class="py-3 text-right">
          <button type="submit" class="bg-hm-secondary border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            @click="resetPassword" :class="{loading: isBtnLoading, 'opacity-50': !oldPassword || !password || !confirmPassword}" :disabled="!oldPassword || !password || !confirmPassword">
            {{$t('ui.changePassword')}}
          </button>
        </div>
      </div>
    </div>

    <!-- SUB -->
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 divide-y-2 divide-gray-300" v-show="isActive('subscription')">
      <div class="sub-info py-7">
        <div class="flex justify-between mb-3">
          <div class="font-bold">{{$t('ui.subscription')}}</div>
          <div class="text-[14px]" v-if="subscriptionStatus.endDate">
            <strong>
              {{displayPrice(currentSubPrice.unit_amount)}} {{$t('ui.per')}} {{$t(`ui.${subscriptionStatus.interval}`)}}
            </strong>
            {{$t('ui.settingsRenewSub')}} <span>{{subDate | dateFormat('MMMM D YYYY') }}</span>
          </div>
        </div>
        <div class="flex justify-between space-x-4 items-center">
          <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product">
            <img :src="currentSubPrice.product.images[0]" v-if="currentSubPrice.product.images[0]" alt="" srcset="" class="h-11 w-11">
            <div>
              <div>{{currentSubPrice.product.name}}</div>
              <div class="opacity-50 text-[14px]">Everything in Business,  1 000 000 characters, customizations. </div>
            </div>
          </div>
          <div class="flex justify-between space-x-4 items-center">
            <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product.images">
              <img :src="currentSubPrice.product.images[0]" v-if="currentSubPrice.product.images[0]" alt="" srcset="" class="h-11 w-11">
              <div>
                <div>{{currentSubPrice.product.name}}
                  <span class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">{{$t('ui.inactive')}}</span>
                </div>
                <div class="opacity-50 text-[14px]">{{$t(`ui.settingsPackage${subscriptionPlan}Desc`)}}</div>
              </div>
            </div>
            <div>
              <router-link to="/subscribe" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-4 py-1 text-xs">{{$t('ui.subscribeAgain')}}</router-link>
            </div>
          </div>
        </div>
        <!-- Trial Sub -->
        <div class="sub-info py-7" v-if="isTrial">
          <div class="flex justify-between mb-3">
            <div class="font-bold">{{$t('ui.subscription')}}</div>
            <div class="text-[14px] font-bold" v-if="subscriptionStatus.endDate && subDate">
              {{$t('ui.settingsTrialEnds')}} <span>{{moment(subDate).format('MMMM D YYYY')   }}</span>
            </div>
          </div>
          <div class="flex justify-between space-x-4 items-center">
            <div class="sub flex space-x-4 items-center">
              <div>
                <div>{{currentSubPrice.product.name}}</div>
                <div class="opacity-50 text-[14px]">{{$t(`ui.settingsPackageTrialDesc`)}}</div>
              </div>
            </div>
            <div>
              <router-link to="/subscribe" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-4 py-1 text-xs">{{$t('ui.settingsUpgrade')}}</router-link>
            </div>
          </div>
        </div>
        <!-- SUB Active -->
        <div class="sub-info py-7" v-if="!isTrial && isSubscriptionActive">
          <div class="flex justify-between mb-3">
            <div class="font-bold">{{$t('ui.subscription')}}</div>
            <div class="text-[14px]" v-if="subscriptionStatus.endDate">
              <strong v-if="currentSubPrice && currentSubPrice.unit_amount !== 0">
                {{displayPrice(currentSubPrice.unit_amount)}} {{$t('ui.per')}} {{$t(`ui.${subscriptionStatus.interval}`)}}
              </strong>
              <span v-if="!subscriptionStatus.canceled">
              {{$t('ui.settingsRenewSub')}} <span>{{moment(subscriptionStatus.endDate).format('MMMM D YYYY') }}</span></span>
              <span v-if="subscriptionStatus.canceled && subscriptionStatus.endDate">
              {{$t('ui.settingsEndSub')}} <span>{{moment(subscriptionStatus.endDate).format('MMMM D YYYY') }}</span></span>
            </div>
          </div>
          <div class="flex justify-between space-x-4 items-center">
            <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product.images">
              <img :src="currentSubPrice.product.images[0]" v-if="currentSubPrice.product.images[0]" alt="" srcset="" class="h-11 w-11">
              <div>
                <div>{{currentSubPrice.product.name}} <span v-if="subscriptionStatus.canceled" class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800">{{$t('ui.canceled')}}</span></div>
                <div class="text-[14px]">{{$t(`ui.settingsPackage${subscriptionPlan}Desc`)}}</div>
                <div class="text-sm mt-4 -mb-4 flex items-center" v-if="pendingPlanUpdate && pendingPlanUpdate.scheduled ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 inline-block mr-3" width="24.706" height="24.149" viewBox="0 0 24.706 24.149">
                    <g id="Group_5442" data-name="Group 5442" transform="translate(0.544 0.192)">
                      <g id="Group_5441" data-name="Group 5441" transform="translate(0.05 0.323)">
                        <path id="Path_43362" data-name="Path 43362" d="M23.578,10.285A9.738,9.738,0,0,0,21.7,5.564,12.813,12.813,0,0,0,12.7.39a10.97,10.97,0,0,0-9.61,3.854A12.734,12.734,0,0,0,.693,16.494c1.36,3.647,4.751,6.5,10.142,6.9a16.144,16.144,0,0,0,2.848,0,12.172,12.172,0,0,0,2.8-.6.365.365,0,0,0,.217-.444.345.345,0,0,0-.434-.217,11.088,11.088,0,0,1-2.651.522,14.705,14.705,0,0,1-2.71,0c-4.928-.493-8.072-3.124-9.274-6.5A11.7,11.7,0,0,1,3.955,5.012a9.856,9.856,0,0,1,8.594-3.43,11.945,11.945,0,0,1,8.3,4.632A8.8,8.8,0,0,1,22.682,10.4a12.014,12.014,0,0,1-.3,4.7,12.2,12.2,0,0,1-1.4,3.351,9.274,9.274,0,0,1-2.474,2.622.286.286,0,0,0-.079.414.306.306,0,0,0,.424.089,9.748,9.748,0,0,0,2.74-2.77,12.813,12.813,0,0,0,1.557-3.519A12.813,12.813,0,0,0,23.578,10.285Z" transform="translate(-0.05 -0.323)" fill="#008aff" stroke="#008aff" stroke-width="1"/>
                        <path id="Path_43363" data-name="Path 43363" d="M14.8,5.125a.306.306,0,0,0-.118-.591c-1.429.158-1.823.986-1.774,1.656a1.853,1.853,0,0,0,.67,1.232,2.3,2.3,0,0,0,1.222.532,1.971,1.971,0,0,0,2.02-1.074,1.7,1.7,0,0,0-.463-2.139.365.365,0,0,0-.493,0,.355.355,0,0,0,0,.493.867.867,0,0,1,.079,1.133.986.986,0,0,1-1.035.394,1.311,1.311,0,0,1-.877-.473c-.118-.158-.246-.306-.207-.483A1.383,1.383,0,0,1,14.8,5.125Z" transform="translate(-3.401 -1.421)"/>
                        <path id="Path_43364" data-name="Path 43364" d="M13.261,20.489a.759.759,0,0,0,.2.424,3.814,3.814,0,0,0,1.971.542,8.713,8.713,0,0,0,2.76-.177.857.857,0,0,0,.424-.483,2.789,2.789,0,0,0,.128-.69l.108-.66a.986.986,0,0,0-.394-.986,2.888,2.888,0,0,0-1.262-.394,1.106,1.106,0,0,1-.542-.089,1.084,1.084,0,0,1,0-.177c0-1.035,0-3.361-.069-4.829a5.243,5.243,0,0,0-.2-1.321.562.562,0,0,0-.286-.325,5.638,5.638,0,0,0-1.892-.483,7.54,7.54,0,0,0-1.9.049.355.355,0,0,0-.306.384.345.345,0,0,0,.384.3,7.993,7.993,0,0,1,1.745,0c1.971.266,1.547.522,1.567,1.291,0,1.39-.079,3.657-.108,4.78v.65a.582.582,0,0,0,.168.4,1.488,1.488,0,0,0,.739.355c.256.059.562.1.838.158s.286.158.375.168l-.1.591v.315a8.87,8.87,0,0,1-1.646.049,8.388,8.388,0,0,1-1.34-.138H14.4a2.316,2.316,0,0,0,0-.256c-.089-1.242-.089-4.11-.1-5.411V13.9a.493.493,0,0,0-.138-.335,1.616,1.616,0,0,0-.867-.434c-.493-.089-.986.069-1.636-.079,0-.059-.089-.177-.1-.286a3.519,3.519,0,0,1,0-.848h0v-.049h0a.246.246,0,0,0,0-.187.3.3,0,0,0-.325-.256.306.306,0,0,0-.227.118,1.212,1.212,0,0,0-.128.473,3.578,3.578,0,0,0,.089,1.262.66.66,0,0,0,.375.414,11.078,11.078,0,0,0,1.774.2.986.986,0,0,1,.3.128,3.4,3.4,0,0,1,0,.375c0,1.045-.168,3.371-.177,4.859a9.58,9.58,0,0,0,.02,1.242Z" transform="translate(-2.87 -3.056)"/>
                      </g>
                    </g>
                  </svg>
                  <span v-html="$t('ui.planUpdated', [pendingPlanUpdate.planName, pendingPlanUpdate.interval + 'ly'])"></span>
                </div>
              </div>
            </div>
            <div>
              <router-link to="/change-plan" v-if="!subscriptionStatus.canceled && !(pendingPlanUpdate && pendingPlanUpdate.scheduled)" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-4 py-1 text-xs text-center whitespace-nowrap">{{$t('ui.changePlan')}}</router-link>
               <router-link to="/subscribe" v-if="subscriptionStatus.canceled" tag="a" class="inline-flex font-bold items-center justify-center border border-transparent rounded-lg shadow-sm focus:outline-none focus:ring-current cursor-pointer transition duration-600 ease-in-out text-white bg-black hover:bg-blue-700 px-4 py-1 text-xs">{{$t('ui.subscribeAgain')}}</router-link>
            </div>
          </div>
        </div>

        <div class="additional-packages py-7" v-show="!isTrial && isSubscriptionActive">
          <div class="flex justify-between mb-3">
            <div class="font-bold">{{$t('ui.availableAddons')}}</div>
            <div class="text-[14px]">
            </div>
          </div>
          <div class="flex justify-between space-x-4 items-center">
            <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product">
              <div>
                <div>{{$t('ui.additionalCharacters')}}</div>
                <div class="opacity-50 text-[14px]" v-html="$t('ui.additioanlCharactersPackageDesc', ['10 000', displayPrice(subscriptionAddOn.unit_amount)])">
                </div>
              </div>
            </div>
            <div :class="{loading}">
              <Button
                  size="sm"
                  @click.native="buyPackage(subscriptionAddOn)"
                  class="border-black text-black border-2 bg-white hover:bg-black hover:text-white"
                  >{{$t('ui.buy')}}</Button>
            </div>
          </div>
        </div>

        <div class="characters-info py-7" v-show="isSubscriptionActive">
          <div class="flex justify-between mb-3">
            <div class="font-bold">{{$t('ui.additionalCharacters')}}</div>
            <div class="text-[14px]">
            </div>
          </div>
          <div class="flex justify-between space-x-4 items-center">
            <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product">
              <div>
                <div class="text-[14px]" v-html="$t('ui.additionalCharactersDesc', [formattedCredits(usedCredits), formattedCredits(startingCredits), formattedCredits(subStartingCredits), formattedCredits(additionalCredits)])">
                </div>
                <div class="text-[14px]">{{$t('ui.additionalCharactersRenewDesc', [subDay])}}</div>
              </div>
            </div>
          </div>
          <div>
            <div class="progress border-2 border-black rounded-md h-[22px] p-[3px] mt-3 mb-2">
              <div class="progress-bar bg-black h-[12px] rounded-[4px]" :style="{width: usedCreditsPercentage + '%'}"></div>
            </div>
            <div class="flex items-center">
              <div class="bg-black h-3 w-3 rounded mr-1"></div>
              <span class="text-[14px]">{{charLeft | intFormat}} {{$t('ui.characterUsed')}}</span>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <!-- Billing -->
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pt-4 divide-y divide-gray-300" v-show="isActive('billing')">
      <div class="additional-packages py-7">
        <div class="flex justify-between mb-3">
          <div class="font-bold">{{$t('ui.invoiceDetails')}}</div>
          <div class="text-[14px]">
          </div>
        </div>
        <div class="flex justify-between space-x-4 items-center">
          <div class="sub flex space-x-4 items-center" v-if="currentSubPrice.product">
            <div>
              <div class="text-[14px]" v-html="$t('ui.invoiceDetailsDesc')">
              </div>
              <div class="text-[14px] italic"  v-if="billingDetails && billingDetails.address">
                {{billingDetails.name}}
                <span v-show="billingDetails.address.line1">, {{billingDetails.address.line1}}</span>
                <span v-show="billingDetails.address.postal_code || billingDetails.address.city">, {{billingDetails.address.postal_code}} {{billingDetails.address.city}}</span>
                <span v-if="billingDetails.taxIds[billingDetails.taxIds.length - 1]">, NIP: {{billingDetails.taxIds[billingDetails.taxIds.length - 1].value}}</span>
              </div>
            </div>
          </div>
          <div>
            <!-- <Button
                size="sm"
                @click.native="update"
                class="border-black text-black border-2 bg-white hover:bg-black hover:text-white"
                >{{$t('ui.update')}}</Button> -->
          </div>
        </div>
      </div>
      <div class="characters-info py-7">
        <div class="flex justify-between mb-3">
          <div class="font-bold">{{$t('ui.billingHistory')}}</div>
          <div class="text-[14px]">
          </div>
        </div>
        <div class="flex justify-between space-x-4 items-center">
          <div class="sub flex space-x-4 items-center">
            <div>
              <div class="text-[14px]" v-html="$t('ui.billingHistoryDesc')">
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="flex flex-col">
            <div class="overflow-x-auto">
              <div class="align-middle inline-block min-w-full mt-3">
                <div class="overflow-hidden g">
                  <div>
                  </div>
                  <table class="min-w-full table-auto">
                    <thead class="">
                      <tr>
                        <th scope="col" class="hidden md:block x-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{$t('ui.date')}}
                        </th>
                        <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{$t('ui.description')}}
                        </th>
                        <th></th>
                        <th scope="col" class="px-6 pr-0 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {{$t('ui.amount')}}
                        </th>
                      </tr>
                    </thead>
                    <tbody class="bg-white">
                      <tr
                        v-for="(item, index) in invoices" :key="item.paid_date + index">
                        <td class="px-6 pl-0 py-4 text-sm hidden md:block">
                          {{moment(item.paid_date).format('DD-MM-YYYY')}}
                        </td>
                        <td class="px-6 pl-0 py-4 ">
                          <div class="text-sm text-gray-900 font-bold">{{item.product_cache}}</div>
                          <div class="text-gray-500 text-sm min-w-[400px] ">{{operationDetails(item)}}</div>
                          <!-- {{item.operationDetails}} -->
                        </td>
                        <td>
                          <a :href="item.view_url + '.pdf'" download class="text-hm-blue hover:underline cursor-pointer">{{$t('ui.invoice')}}</a>
                        </td>
                        <td class="px-6 pr-0 py-4 whitespace-nowrap text-sm text-right"
                          :class="{ 'text-red-500': isNegative(item) < 0, 'text-green-600': isNegative(item.paid) > 0 }">
                          {{$t(`currency.${item.currency}`)}}<span v-show="isNegative(item.paid) < 0">-</span>{{item.paid | intFormat}}
                        </td>
                      </tr>

                      <!-- More items... -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div></div>
    </div>
    <pre class="break-words max-w-md" v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612'">
      {{newsletterOptIn}}
      ------------------
      {{invoices}}
      {{subscriptionPlan}}
      ------------------------
      {{subscriptionStatus}}
      ------------------------
      {{subscriptionAddOn}}
      PRICE ------------------------
    </pre>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';
import moment from 'moment';
import PaginationTemplate from '../components/PaginationTemplate.vue';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      isMonthly: 'month',
      showChangePlan: false,
      moment,
      paymentStatus: null,
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      loading: false,
      confirmPassword: null,
      password: null,
      oldPassword: null,
      isBtnLoading: false,
      errorMessage: null,
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      activeTab: 'profile',

      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      selectedOrder: 'BILLED_AT',
      orderDirString: 'DESC',
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      newsletterOptIn: false,
    };
  },
  computed: {
    ...mapState({
      customerId: (state) => state.auth.customerId,
      isLoggedIn: (state) => state.auth.isLoggedIn,
      selectedWebsite: (state) => state.website.selectedWebsite,
      userEmail: (state) => state.auth.userEmail,
      baseCredits: (state) => state.customer.credits,
      additionalCredits: (state) => state.customer.additionalCredits,
      customerMetadata: (state) => state.customer.customerMetadata,
      billingDetails: (state) => state.customer.billingDetails,
      userProfile: (state) => state.auth.userProfile,
      customer: (state) => state.auth.customer,
      userRole: (state) => state.auth.userRole,
      subscriptions: (state) => state.billing.subscriptions,
      subscriptionStatus: (state) => state.billing.subscriptionStatus,
      subscriptionAddOns: (state) => state.billing.subscriptionAddOns,
      customers: (state) => state.customer.list,
      userId: (state) => state.auth.userId,
      newsletter: (state) => state.auth.newsletter,

      totalCount: (state) => state.billing.count,
      hasNextPage: (state) => state.billing.hasNextPage,
      items: (state) => state.billing.billingHistory,
      invoices: (state) => state.billing.invoices,
    }),
    credits() {
      return parseInt(this.baseCredits, 10) + parseInt(this.additionalCredits, 10);
    },
    stripTag() {
      return (str) => str.replace(/(<([^>]+)>)/gi, '');
    },
    pendingPlanUpdate() {
      if (!this.subscriptionStatus || !this.subscriptionStatus.params) return {};
      return this.subscriptionStatus.params.pendingUpdate;
    },
    subscriptionPlan() {
      return this.subscriptionStatus.plan;
    },
    subscriptionPrice() {
      if (!this.subscriptions[this.subscriptionPlan]) return {};
      return this.subscriptions[this.subscriptionPlan][this.subscriptionStatus.interval];
    },
    subscriptionAddOn() {
      return this.subscriptionAddOns[this.subscriptionPlan] ? this.subscriptionAddOns[this.subscriptionPlan] : {};
    },
    isSubscriptionActive() {
      return this.subscriptionStatus && this.subscriptionStatus.active;
    },
    operationDetails() {
      return (item) => {
        const type = item.operationType;
        let details = '';
        switch (type) {
          case 'block_credits_for_article_generation':
            details = item.operationDetails.episodeTitle;
            break;
          case 'coupon_redeem':
            details = item.operationDetails.couponCode;
            break;
          case 'give_credits_back_after_fail':
            details = `${item.operationDetails.reason} for ${item.operationDetails.episodeTitle}`;
            break;
          case 'ad_hoc_credits_add':
            details = `${item.operationDetails.reason}`;
            break;
          default:
            details = '';
            break;
        }
        return this.stripTag(details);
      };
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          visibilityLevel: 'hm_admin',
          creditsType: 'normal',
          // isDeleted: false,
        },
      };
      if (this.customer) {
        input.condition.customerId = this.customer;
      }

      return input;
    },
    isNegative() {
      return (item) => item.creditsAfter - item.creditsBefore;
    },
    isTrial() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Trial';
    },
    avatar() {
      return `https://avatars.dicebear.com/api/bottts/${this.userEmail}.svg`;
    },
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    currentSubPrice() {
      if (!this.subscriptionStatus.plan) return { currency: 'usd', product: { name: '', metadata: { chars_monthly: '' } } };
      if (this.subscriptionStatus.plan === 'Trial') return { currency: 'free', product: { name: 'Trial', metadata: { chars_monthly: '20 000' } } };
      const subName = this.subscriptionStatus.plan;
      const subInterval = this.subscriptionStatus.interval;
      return this.subscriptions[subName][subInterval];
    },
    subDate() {
      return new Date(this.subscriptionStatus.endDate);
    },
    subDay() {
      if (!this.subDate || !this.subscriptionStatus.endDate) return 'xxx';
      const [datePart] = this.subscriptionStatus.endDate.split(' ');
      const [year, month, day] = datePart.split('-');
      console.log('year, month', year, month, day, datePart);
      return day;
    },

    symbol() {
      return this.$t(`currency.${this.currentSubPrice.currency}`);
    },
    usedCredits() {
      if (this.isTrial) return 20000 - this.credits;
      const used = (this.currentSubPrice.product.metadata.chars_monthly) - this.credits + parseInt(this.additionalCredits, 10);
      if (used > this.startingCredits) return -0;
      return used;
    },
    usedCreditsPercentage() {
      return 100 - (100 / this.startingCredits) * this.usedCredits;
    },
    charLeft() {
      return this.startingCredits - this.usedCredits;
    },
    startingCredits() {
      if (!this.subscriptionStatus.params) return 1;
      return parseInt(this.subscriptionStatus.params.charsAvailable, 10);
    },
    subStartingCredits() {
      if (this.isTrial) return 0;
      return parseInt(this.currentSubPrice.product.metadata.chars_monthly, 10);
    },
    formattedCredits() {
      return (credits) => credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    displayPrice() {
      return (unitAmount) => {
        const price = unitAmount / 100;
        const isInt = Number.isInteger(price);
        const priceString = isInt ? `${this.symbol}${price}.00` : `${this.symbol}${price}`;
        return priceString;
      };
    },
  },
  validators: {
    oldPassword: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    password: (value) => Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, 'Invalid password'),
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  watch: {
    newsletter() {
      this.newsletterOptIn = this.newsletter;
    },
  },
  async mounted() {
    this.paymentStatus = this.$route.params.state;
    if (this.paymentStatus) this.activeTab = 'subscription';
    console.log('mounted', this.paymentStatus);
    await this.getSubscriptionStatus();
    this.fetch();
    // this.loadData(1);
    this.listInvoices();
  },
  methods: {
    ...mapMutations({
      setSession: 'auth/setSession',
      setIsLoggedIn: 'auth/setIsLoggedIn',
    }),
    ...mapActions({
      fetch: 'customer/fetchCustomer',
      fetchBilling: 'billing/fetchHistory',
      updateUser: 'auth/updateUser',
      getPaymentLink: 'billing/getPaymentLink',
      listInvoices: 'billing/listInvoices',
      getSubscriptionStatus: 'billing/getSubscriptionStatus',
    }),

    async save() {
      this.loading = true;
      // userProfile.displayName
      const user = await this.$Amplify.Auth.currentAuthenticatedUser();
      const input = {
        userPatch: {
          displayName: this.userProfile.displayName,
        },
        id: this.userProfile.id,
      };

      // console.log('update user', input);
      const result = await this.updateUser(input);
      const resultCognito = await this.$Amplify.Auth.updateUserAttributes(user, {
        email: this.userEmail,
        'custom:newsletter': this.newsletterOptIn ? '1' : '0',
      });
      this.loading = false;
      console.log('result', resultCognito);
      if (!result.ok) {
        // console.log('result', result);
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.profileUpdated'),
      });
      this.loading = false;
    },

    async buyPackage(addOn) {
      this.loading = true;
      console.log('buyPackage', addOn);
      const { product, id } = addOn;
      const domain = `https://${window.location.host}/account-profile`;
      const data = {
        customer: this.customerId,
        domain,
        productId: product.id,
        priceId: id,
      };

      // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
      // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

      const result = await this.getPaymentLink(data);
      console.log('buyPackage 2', result);
      this.loading = false;
      window.location.replace(result.url);
      console.log('payment link result', data, result);
    },

    async cancelSubscription() {
      console.log('cancel subscription');
    },

    async resetPassword() {
      const isValid = await this.$validate();
      this.errorMessage = null;
      if (!isValid) return;
      try {
        this.isBtnLoading = true;
        const user = await this.$Amplify.Auth.currentAuthenticatedUser();
        await this.$Amplify.Auth.changePassword(
          user,
          this.oldPassword,
          this.password,
        );
        // console.log('resetPassword result', result);
        this.isBtnLoading = false;
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'success',
          title: this.$t('ui.passwordChanged'),
        });
      } catch (error) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.isBtnLoading = false;
        this.errorMessage = error.message;
      }
    },

    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },

    // Billing History
    async loadData(page) {
      this.loading = true;
      this.offset = page * this.pageSize - this.pageSize;

      // console.log('page', page, this.queryParams);
      await this.fetchBilling(this.queryParams);
      this.loading = false;
    },
  },
};
</script>

<style scoped>
</style>
