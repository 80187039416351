<template>
  <div id="playerpreview">
    <div id="player2" class="player2"></div>
    <div v-html="getStyle"></div>
  </div>
</template>

<script>
import { Player } from './hmplayer';

export default {
  name: 'PlayerEmbeded',
  props: {
    url: {
      type: String,
    },
    playlistUrl: {
      type: String,
    },
    playerStyle: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
    },
  },
  data() {
    return {
      playlist: null,
      player: null,
      player2: null,
      audioTitle: 'title',
      oldRssUrl: null,
      track: {
        file: 'null',
      },
      audioPlayerId: 'hm-audio-player',
    };
  },
  computed: {
    getStyle() {
      const bgHover = this.playerStyle.backgroundColorHover ? `--hmplayer-background-hover: ${this.playerStyle.backgroundColorHover};` : '';
      const textHover = this.playerStyle.textColorHover ? `--hmplayer-text-hover: ${this.playerStyle.textColorHover};` : '';
      return `
        <style>
          :root {
            --hmplayer-accent: ${this.playerStyle.accentColor};
            --hmplayer-background: ${this.playerStyle.backgroundColor};
            --hmplayer-text-color: ${this.playerStyle.textColor};
            --hmplayer-radius: ${this.playerStyle.borderRadius};

            --hmplayer-background-rgb: ${this.hexToRgb(this.playerStyle.backgroundColor).join(',')};
            --hmplayer-accent-rgb: ${this.hexToRgb(this.playerStyle.accentColor).join(',')};
            --hmplayer-text-color-rgb: ${this.hexToRgb(this.playerStyle.textColor).join(',')};
            ${textHover}
            ${bgHover}
          }
        </style>
      `;
    },
    stripTag() {
      return (str) => str.replace(/(<([^>]+)>)/gi, '');
    },
    playlistFiltered() {
      if (!this.playlist) return [];
      return this.playlist.filter((el, index) => index < 3);
    },
    type() {
      return this.playerStyle.type;
    },
    typeClass() {
      return `hearme-player-${this.playerStyle.type}`;
    },
  },
  watch: {
    // eslint-disable-next-line
    type: function (newType) {
      this.initPlayer();
    },
    // eslint-disable-next-line
    playlistUrl: function (url) {
      this.initPlayer();
    },
  },
  mounted() {
  },
  methods: {
    initPlayer(source) {
      console.log('player init ==========>', source, this);
      const playerOptions = {
        selector: '#player2',
        type: this.type,
        download: false,
        share: false,
        playbackRate: true,
        floating: false,
        ignoreStyle: true,
        showExample: true,
        style: {
          accentColor: this.playerStyle.accentColor,
          backgroundColor: this.playerStyle.backgroundColor,
          backgroundColorHover: this.playerStyle.backgroundColorHover,
          textColor: this.playerStyle.textColor,
          textColorHover: this.playerStyle.textColorHover,
          borderRadius: this.playerStyle.menuRadius,
        },
        // rss: 'https://hearme-ai-dev-hm-upload-bucket.s3.eu-west-1.amazonaws.com/ignus64%2Bdev13%3Agmail.com/5ef8eb64-209f-40dd-bf74-8ae48bee5a94/rss.xml',
        // audio: 'https://hearme-ai-dev-hm-audio-episodes.s3-eu-west-1.amazonaws.com/ac4c8580-7085-4641-b822-e52f2cc9af68-1.mp3',
        // audioTitle: this.rss || this.title ? this.title : '',
      };
      if (this.url) {
        playerOptions.audioTitle = this.title;
        playerOptions.audio = this.url;
      }
      if (this.playlistUrl) {
        // playerOptions.audioTitle = this.title;
        playerOptions.rss = this.playlistUrl;
      }
      console.log('player  options ==========>', playerOptions);
      this.player = new Player(playerOptions);
      // // console.log('buildPlaylist 1', this.playlistUrl);
      // if (this.playlistUrl) this.buildPlaylist(this.playlistUrl);
    },
    playTrack(item, play) {
      this.track = item;
      // console.log('play', item, this.player);
      this.player.source = {
        type: 'audio',
        title: item.title,
        sources: [
          {
            src: item.file,
            type: 'audio/mp3',
          },
        ],
      };
      if (play) {
        setTimeout(() => {
          this.player.play();
        }, 500);
      }
    },

    hexToRgb(hexInput) {
      if (!hexInput) return [128, 128, 128];
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      const hex = hexInput.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result ? [
        parseInt(result[1], 16), // r
        parseInt(result[2], 16), // g
        parseInt(result[3], 16), // b
      ] : null;
    },
  },
};
</script>

<style>
@keyframes plyr-progress {
  to {
    background-position: 25px 0;
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}
@keyframes plyr-popup {
  0% {
    opacity: 0.5;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
#hearme-player .plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  align-items: center;
  direction: ltr;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  height: 100%;
  line-height: 1.7;
  line-height: var(--plyr-line-height, 1.7);
  max-width: 100%;
  min-width: 200px;
  position: relative;
  text-shadow: none;
  transition: box-shadow 0.3s ease;
  z-index: 0;
}
#hearme-player .plyr audio, #hearme-player .plyr iframe, #hearme-player .plyr video {
  display: block;
  height: 100%;
  width: 100%;
}
#hearme-player .plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}
#hearme-player .plyr:focus {
  outline: 0;
}
#hearme-player .plyr--full-ui {
  box-sizing: border-box;
}
#hearme-player .plyr--full-ui *, #hearme-player .plyr--full-ui ::after, #hearme-player .plyr--full-ui ::before {
  box-sizing: inherit;
}
#hearme-player .plyr--full-ui a, #hearme-player .plyr--full-ui button, #hearme-player .plyr--full-ui input, #hearme-player .plyr--full-ui label {
  touch-action: manipulation;
}
#hearme-player .plyr__badge {
  background: #4a5464;
  background: var(--plyr-badge-background, #4a5464);
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: #fff;
  color: var(--plyr-badge-text-color, #fff);
  font-size: 9px;
  font-size: var(--plyr-font-size-badge, 9px);
  line-height: 1;
  padding: 3px 4px;
}
#hearme-player .plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}
#hearme-player .plyr__captions {
  animation: plyr-fade-in 0.3s ease;
  bottom: 0;
  display: none;
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  left: 0;
  padding: 10px;
  padding: var(--plyr-control-spacing, 10px);
  position: absolute;
  text-align: center;
  transition: transform 0.4s ease-in-out;
  width: 100%;
}
#hearme-player .plyr__captions span:empty {
  display: none;
}
@media (min-width: 480px) {
  #hearme-player .plyr__captions {
    font-size: 15px;
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(10px * 2);
    padding: calc(var(--plyr-control-spacing,10px) * 2);
  }
}
@media (min-width: 768px) {
  #hearme-player .plyr__captions {
    font-size: 18px;
    font-size: var(--plyr-font-size-large, 18px);
  }
}
#hearme-player .plyr--captions-active .plyr__captions {
  display: block;
}
#hearme-player .plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(10px * -4));
  transform: translateY(calc(var(--plyr-control-spacing,10px) * -4));
}
#hearme-player .plyr__caption {
  background: rgba(0, 0, 0, 0.8);
  background: var(--plyr-captions-background, rgba(0, 0, 0, 0.8));
  border-radius: 2px;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: #fff;
  color: var(--plyr-captions-text-color, #fff);
  line-height: 185%;
  padding: 0.2em 0.5em;
  white-space: pre-wrap;
}
#hearme-player .plyr__caption div {
  display: inline;
}
#hearme-player .plyr__control {
  background: 0 0;
  border: 0;
  border-radius: var(--plyr-control-radius, 3px) !important;
  color: inherit;
  cursor: pointer;
  flex-shrink: 0;
  overflow: visible;
  padding: calc(10px * .7);
  padding: calc(var(--plyr-control-spacing,10px) * .7);
  position: relative;
  transition: all 0.3s ease;
}
#hearme-player .plyr__control svg {
  display: block;
  fill: currentColor;
  height: 18px;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: 18px;
  width: var(--plyr-control-icon-size, 18px);
}
#hearme-player .plyr__control:focus {
  outline: 0;
}
#hearme-player .plyr__control.plyr__tab-focus {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}
#hearme-player a.plyr__control {
  text-decoration: none;
}
#hearme-player a.plyr__control::after, #hearme-player a.plyr__control::before {
  display: none;
}
#hearme-player .plyr__control.plyr__control--pressed .icon--not-pressed, #hearme-player .plyr__control.plyr__control--pressed .label--not-pressed, #hearme-player .plyr__control:not(.plyr__control--pressed) .icon--pressed, #hearme-player .plyr__control:not(.plyr__control--pressed) .label--pressed {
  display: none;
}
#hearme-player .plyr--full-ui ::-webkit-media-controls {
  display: none;
}
#hearme-player .plyr__controls {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}
#hearme-player .plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}
#hearme-player .plyr__controls .plyr__controls__item {
  margin-left: calc(10px / 4);
  margin-left: calc(var(--plyr-control-spacing,10px)/ 4);
}
#hearme-player .plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}
#hearme-player .plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(10px / 4);
  padding-left: calc(var(--plyr-control-spacing,10px)/ 4);
}
#hearme-player .plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(10px / 2);
  padding: 0 calc(var(--plyr-control-spacing,10px)/ 2);
}
#hearme-player .plyr__controls .plyr__controls__item.plyr__progress__container:first-child, #hearme-player .plyr__controls .plyr__controls__item.plyr__time + .plyr__time, #hearme-player .plyr__controls .plyr__controls__item.plyr__time:first-child {
  padding-left: 0;
}
#hearme-player .plyr__controls:empty {
  display: none;
}
#hearme-player .plyr [data-plyr=airplay], #hearme-player .plyr [data-plyr=captions], #hearme-player .plyr [data-plyr=fullscreen], #hearme-player .plyr [data-plyr=pip] {
  display: none;
}
#hearme-player .plyr--airplay-supported [data-plyr=airplay], #hearme-player .plyr--captions-enabled [data-plyr=captions], #hearme-player .plyr--fullscreen-enabled [data-plyr=fullscreen], #hearme-player .plyr--pip-supported [data-plyr=pip] {
  display: inline-block;
}
#hearme-player .plyr__menu {
  display: flex;
  position: relative;
}
#hearme-player .plyr__menu .plyr__control svg {
  transition: transform 0.3s ease;
}
#hearme-player .plyr__menu .plyr__control[aria-expanded=true] svg {
  transform: rotate(90deg);
}
#hearme-player .plyr__menu .plyr__control[aria-expanded=true] .plyr__tooltip {
  display: none;
}
#hearme-player .plyr__menu__container {
  animation: plyr-popup 0.2s ease;
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  border-radius: var(--plyr-control-radius, 3px) !important;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  font-size: 15px;
  font-size: var(--plyr-font-size-base, 15px);
  margin-bottom: 10px;
  position: absolute;
  right: -3px;
  text-align: left;
  white-space: nowrap;
  z-index: 3;
}
#hearme-player .plyr__menu__container > div {
  overflow: hidden;
  transition: height 0.35s cubic-bezier(0.4, 0, 0.2, 1), width 0.35s cubic-bezier(0.4, 0, 0.2, 1);
}
#hearme-player .plyr__menu__container::after {
  border: 4px solid transparent;
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: rgba(255, 255, 255, 0.9);
  border-top-color: var(--plyr-menu-background, rgba(255, 255, 255, 0.9));
  content: "";
  height: 0;
  position: absolute;
  right: calc(((18px / 2) + calc(10px * .7)) - (4px / 2));
  right: calc(((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7)) - (var(--plyr-menu-arrow-size,4px)/ 2));
  top: 100%;
  width: 0;
}
#hearme-player .plyr__menu__container [role=menu] {
  padding: calc(10px * .7);
  padding: calc(var(--plyr-control-spacing,10px) * .7);
}
#hearme-player .plyr__menu__container [role=menuitem], #hearme-player .plyr__menu__container [role=menuitemradio] {
  margin-top: 2px;
}
#hearme-player .plyr__menu__container [role=menuitem]:first-child, #hearme-player .plyr__menu__container [role=menuitemradio]:first-child {
  margin-top: 0;
}
#hearme-player .plyr__menu__container .plyr__control {
  align-items: center;
  color: #4a5464;
  color: var(--plyr-menu-color, #4a5464);
  display: flex;
  font-size: 13px;
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding-bottom: calc(calc(10px * .7)/ 1.5);
  padding-bottom: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 1.5);
  padding-left: calc(calc(10px * .7) * 1.5);
  padding-left: calc(calc(var(--plyr-control-spacing,10px) * .7) * 1.5);
  padding-right: calc(calc(10px * .7) * 1.5);
  padding-right: calc(calc(var(--plyr-control-spacing,10px) * .7) * 1.5);
  padding-top: calc(calc(10px * .7)/ 1.5);
  padding-top: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 1.5);
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}
#hearme-player .plyr__menu__container .plyr__control > span {
  align-items: inherit;
  display: flex;
  width: 100%;
}
#hearme-player .plyr__menu__container .plyr__control::after {
  border: 4px solid transparent;
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#hearme-player .plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(10px * .7) * 4);
  padding-right: calc(calc(var(--plyr-control-spacing,10px) * .7) * 4);
}
#hearme-player .plyr__menu__container .plyr__control--forward::after {
  border-left-color: #728197;
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: calc((calc(10px * .7) * 1.5) - 4px);
  right: calc((calc(var(--plyr-control-spacing,10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size,4px));
}
#hearme-player .plyr__menu__container .plyr__control--forward.plyr__tab-focus::after, #hearme-player .plyr__menu__container .plyr__control--forward:hover::after {
  border-left-color: currentColor;
}
#hearme-player .plyr__menu__container .plyr__control--back {
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(10px * .7);
  margin: calc(var(--plyr-control-spacing,10px) * .7);
  margin-bottom: calc(calc(10px * .7)/ 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 2);
  padding-left: calc(calc(10px * .7) * 4);
  padding-left: calc(calc(var(--plyr-control-spacing,10px) * .7) * 4);
  position: relative;
  width: calc(100% - (calc(10px * .7) * 2));
  width: calc(100% - (calc(var(--plyr-control-spacing,10px) * .7) * 2));
}
#hearme-player .plyr__menu__container .plyr__control--back::after {
  border-right-color: #728197;
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: calc((calc(10px * .7) * 1.5) - 4px);
  left: calc((calc(var(--plyr-control-spacing,10px) * .7) * 1.5) - var(--plyr-menu-item-arrow-size,4px));
}
#hearme-player .plyr__menu__container .plyr__control--back::before {
  background: #dcdfe5;
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px 0 #fff;
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  height: 1px;
  left: 0;
  margin-top: calc(calc(10px * .7)/ 2);
  margin-top: calc(calc(var(--plyr-control-spacing,10px) * .7)/ 2);
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 100%;
}
#hearme-player .plyr__menu__container .plyr__control--back.plyr__tab-focus::after, #hearme-player .plyr__menu__container .plyr__control--back:hover::after {
  border-right-color: currentColor;
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio] {
  padding-left: calc(10px * .7);
  padding-left: calc(var(--plyr-control-spacing,10px) * .7);
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio]::after, #hearme-player .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  border-radius: 100%;
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(0, 0, 0, 0.1);
  content: "";
  display: block;
  flex-shrink: 0;
  height: 16px;
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
  transition: all 0.3s ease;
  width: 16px;
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio]::after {
  background: #fff;
  border: 0;
  height: 6px;
  left: 12px;
  opacity: 0;
  top: 50%;
  transform: translateY(-50%) scale(0);
  transition: transform 0.3s ease, opacity 0.3s ease;
  width: 6px;
}

#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::before {
  background: #00b3ff;
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio][aria-checked=true]::after {
  opacity: 1;
  transform: translateY(-50%) scale(1);
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio].plyr__tab-focus::before, #hearme-player .plyr__menu__container .plyr__control[role=menuitemradio]:hover::before {
  background: rgba(35, 40, 47, 0.1);
}
#hearme-player .plyr__menu__container .plyr__menu__value {
  align-items: center;
  display: flex;
  margin-left: auto;
  margin-right: calc((calc(10px * .7) - 2) * -1);
  margin-right: calc((calc(var(--plyr-control-spacing,10px) * .7) - 2) * -1);
  overflow: hidden;
  padding-left: calc(calc(10px * .7) * 3.5);
  padding-left: calc(calc(var(--plyr-control-spacing,10px) * .7) * 3.5);
  pointer-events: none;
}
#hearme-player .plyr--full-ui input[type=range] {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: calc(13px * 2);
  border-radius: calc(var(--plyr-range-thumb-height,13px) * 2);
  color: #00b3ff;
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  display: block;
  height: calc((3px * 2) + 13px);
  height: calc((var(--plyr-range-thumb-active-shadow-width,3px) * 2) + var(--plyr-range-thumb-height,13px));
  margin: 0;
  padding: 0;
  transition: box-shadow 0.3s ease;
  width: 100%;
}
#hearme-player .plyr--full-ui input[type=range]::-webkit-slider-runnable-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -webkit-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -webkit-user-select: none;
  user-select: none;
  background-image: linear-gradient(to right, currentColor 0, transparent 0);
  background-image: linear-gradient(to right, currentColor var(--value, 0), transparent var(--value, 0));
}
#hearme-player .plyr--full-ui input[type=range]::-webkit-slider-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  -webkit-appearance: none;
  margin-top: calc(((13px - 5px)/ 2) * -1);
  margin-top: calc(((var(--plyr-range-thumb-height,13px) - var(--plyr-range-track-height,5px))/ 2) * -1);
}
#hearme-player .plyr--full-ui input[type=range]::-moz-range-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -moz-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  user-select: none;
}
#hearme-player .plyr--full-ui input[type=range]::-moz-range-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -moz-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
}
#hearme-player .plyr--full-ui input[type=range]::-moz-range-progress {
  background: currentColor;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
}
#hearme-player .plyr--full-ui input[type=range]::-ms-track {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  color: transparent;
}
#hearme-player .plyr--full-ui input[type=range]::-ms-fill-upper {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
}
#hearme-player .plyr--full-ui input[type=range]::-ms-fill-lower {
  background: 0 0;
  border: 0;
  border-radius: calc(5px / 2);
  border-radius: calc(var(--plyr-range-track-height,5px)/ 2);
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  -ms-transition: box-shadow 0.3s ease;
  transition: box-shadow 0.3s ease;
  -ms-user-select: none;
  user-select: none;
  background: currentColor;
}
#hearme-player .plyr--full-ui input[type=range]::-ms-thumb {
  background: #fff;
  background: var(--plyr-range-thumb-background, #fff);
  border: 0;
  border-radius: 100%;
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2));
  height: 13px;
  height: var(--plyr-range-thumb-height, 13px);
  position: relative;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
  width: 13px;
  width: var(--plyr-range-thumb-height, 13px);
  margin-top: 0;
}
#hearme-player .plyr--full-ui input[type=range]::-ms-tooltip {
  display: none;
}
#hearme-player .plyr--full-ui input[type=range]:focus {
  outline: 0;
}
#hearme-player .plyr--full-ui input[type=range]::-moz-focus-outer {
  border: 0;
}
#hearme-player .plyr--full-ui input[type=range].plyr__tab-focus::-webkit-slider-runnable-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}
#hearme-player .plyr--full-ui input[type=range].plyr__tab-focus::-moz-range-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}
#hearme-player .plyr--full-ui input[type=range].plyr__tab-focus::-ms-track {
  outline-color: #00b3ff;
  outline-color: var(--plyr-tab-focus-color, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  outline-offset: 2px;
  outline-style: dotted;
  outline-width: 3px;
}
#hearme-player .plyr__poster {
  background-color: #000;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.2s ease;
  width: 100%;
  z-index: 1;
}
#hearme-player .plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}
#hearme-player .plyr__time {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}
#hearme-player .plyr__time + .plyr__time::before {
  content: "⁄";
  margin-right: 10px;
  margin-right: var(--plyr-control-spacing, 10px);
}
@media (max-width: calc(768px - 1)) {
  #hearme-player .plyr__time + .plyr__time {
    display: none;
  }
}
#hearme-player .plyr__tooltip {
  background: rgba(255, 255, 255, 0.9);
  background: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  border-radius: var(--plyr-tooltip-radius, 3px);
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  color: #4a5464;
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: 13px;
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: 400;
  font-weight: var(--plyr-font-weight-regular, 400);
  left: 50%;
  line-height: 1.3;
  margin-bottom: calc(calc(10px / 2) * 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing,10px)/ 2) * 2);
  opacity: 0;
  padding: calc(10px / 2) calc(calc(10px / 2) * 1.5);
  padding: calc(var(--plyr-control-spacing,10px)/ 2) calc(calc(var(--plyr-control-spacing,10px)/ 2) * 1.5);
  pointer-events: none;
  position: absolute;
  transform: translate(-50%, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  white-space: nowrap;
  z-index: 2;
}
#hearme-player .plyr__tooltip::before {
  border-left: 4px solid transparent;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(4px * -1);
  bottom: calc(var(--plyr-tooltip-arrow-size,4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}
#hearme-player .plyr .plyr__control.plyr__tab-focus .plyr__tooltip, #hearme-player .plyr .plyr__control:hover .plyr__tooltip, #hearme-player .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%, 0) scale(1);
}
#hearme-player .plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}
#hearme-player .plyr__controls > .plyr__control:first-child .plyr__tooltip, #hearme-player .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  left: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 0 100%;
}
#hearme-player .plyr__controls > .plyr__control:first-child .plyr__tooltip::before, #hearme-player .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip::before {
  left: calc((18px / 2) + calc(10px * .7));
  left: calc((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7));
}
#hearme-player .plyr__controls > .plyr__control:last-child .plyr__tooltip {
  left: auto;
  right: 0;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 100% 100%;
}
#hearme-player .plyr__controls > .plyr__control:last-child .plyr__tooltip::before {
  left: auto;
  right: calc((18px / 2) + calc(10px * .7));
  right: calc((var(--plyr-control-icon-size,18px)/ 2) + calc(var(--plyr-control-spacing,10px) * .7));
  transform: translateX(50%);
}
#hearme-player .plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, #hearme-player .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, #hearme-player .plyr__controls > .plyr__control:first-child + .plyr__control.plyr__tab-focus .plyr__tooltip, #hearme-player .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, #hearme-player .plyr__controls > .plyr__control:first-child.plyr__tab-focus .plyr__tooltip, #hearme-player .plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, #hearme-player .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible, #hearme-player .plyr__controls > .plyr__control:last-child.plyr__tab-focus .plyr__tooltip, #hearme-player .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip {
  transform: translate(0, 0) scale(1);
}
#hearme-player .plyr__progress {
  left: calc(13px * .5);
  left: calc(var(--plyr-range-thumb-height,13px) * .5);
  margin-right: 13px;
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}
#hearme-player .plyr__progress input[type=range], #hearme-player .plyr__progress__buffer {
  margin-left: calc(13px * -.5);
  margin-left: calc(var(--plyr-range-thumb-height,13px) * -.5);
  margin-right: calc(13px * -.5);
  margin-right: calc(var(--plyr-range-thumb-height,13px) * -.5);
  width: calc(100% + 13px);
  width: calc(100% + var(--plyr-range-thumb-height,13px));
}
#hearme-player .plyr__progress input[type=range] {
  position: relative;
  z-index: 2;
}
#hearme-player .plyr__progress .plyr__tooltip {
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  left: 0;
}
#hearme-player .plyr__progress__buffer {
  -webkit-appearance: none;
  background: 0 0;
  border: 0;
  border-radius: 100px;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin-top: calc((5px / 2) * -1);
  margin-top: calc((var(--plyr-range-track-height,5px)/ 2) * -1);
  padding: 0;
  position: absolute;
  top: 50%;
}
#hearme-player .plyr__progress__buffer::-webkit-progress-bar {
  background: 0 0;
}
#hearme-player .plyr__progress__buffer::-webkit-progress-value {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -webkit-transition: width 0.2s ease;
  transition: width 0.2s ease;
}
#hearme-player .plyr__progress__buffer::-moz-progress-bar {
  background: currentColor;
  border-radius: 100px;
  min-width: 5px;
  min-width: var(--plyr-range-track-height, 5px);
  -moz-transition: width 0.2s ease;
  transition: width 0.2s ease;
}
#hearme-player .plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  -ms-transition: width 0.2s ease;
  transition: width 0.2s ease;
}
#hearme-player .plyr--loading .plyr__progress__buffer {
  animation: plyr-progress 1s linear infinite;
  background-image: linear-gradient(-45deg, rgba(35, 40, 47, 0.6) 25%, transparent 25%, transparent 50%, rgba(35, 40, 47, 0.6) 50%, rgba(35, 40, 47, 0.6) 75%, transparent 75%, transparent);
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 50%, var(--plyr-progress-loading-background, rgba(35, 40, 47, 0.6)) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: 25px 25px;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: transparent;
}
#hearme-player .plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}
#hearme-player .plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}
#hearme-player .plyr__volume {
  align-items: center;
  display: flex;
  max-width: 110px;
  min-width: 80px;
  position: relative;
  width: 20%;
}
#hearme-player .plyr__volume input[type=range] {
  margin-left: calc(10px / 2);
  margin-left: calc(var(--plyr-control-spacing,10px)/ 2);
  margin-right: calc(10px / 2);
  margin-right: calc(var(--plyr-control-spacing,10px)/ 2);
  position: relative;
  z-index: 2;
}
#hearme-player .plyr--is-ios .plyr__volume {
  min-width: 0;
  width: auto;
}
#hearme-player .plyr--audio {
  display: block;
}
#hearme-player .plyr--audio .plyr__controls {
  /* background: #fff;
  background: var(--plyr-audio-controls-background, #fff); */
  border-radius: inherit;
  color: #4a5464;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: 5px;
  /* padding: var(--plyr-control-spacing, 10px); */
}
#hearme-player .plyr--audio .plyr__control.plyr__tab-focus, #hearme-player .plyr--audio .plyr__control:hover, #hearme-player .plyr--audio .plyr__control[aria-expanded=true] {
  background: #00b3ff;
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  color: #fff;
  color: var(--plyr-audio-control-color-hover, #fff);
}
#hearme-player .plyr--full-ui.plyr--audio input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
#hearme-player .plyr--full-ui.plyr--audio input[type=range]::-moz-range-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
#hearme-player .plyr--full-ui.plyr--audio input[type=range]::-ms-track {
  background-color: rgba(193, 200, 209, 0.6);
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6)));
}
#hearme-player .plyr--full-ui.plyr--audio input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
#hearme-player .plyr--full-ui.plyr--audio input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
#hearme-player .plyr--full-ui.plyr--audio input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(35, 40, 47, 0.1);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(35, 40, 47, 0.1));
}
#hearme-player .plyr--audio .plyr__progress__buffer {
  color: rgba(193, 200, 209, 0.6);
  color: var(--plyr-audio-progress-buffered-background, rgba(193, 200, 209, 0.6));
}
#hearme-player .plyr--video {
  background: #000;
  overflow: hidden;
}
#hearme-player .plyr--video.plyr--menu-open {
  overflow: visible;
}
#hearme-player .plyr__video-wrapper {
  background: #000;
  height: 100%;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
}
#hearme-player .plyr__video-embed, #hearme-player .plyr__video-wrapper--fixed-ratio {
  height: 0;
  padding-bottom: 56.25%;
}
#hearme-player .plyr__video-embed iframe, #hearme-player .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  left: 0;
  position: absolute;
  top: 0;
}
#hearme-player .plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.28125%);
}
#hearme-player .plyr--video .plyr__controls {
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75));
  background: var(--plyr-video-controls-background, linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75)));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  bottom: 0;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  left: 0;
  padding: calc(10px / 2);
  padding: calc(var(--plyr-control-spacing,10px)/ 2);
  padding-top: calc(10px * 2);
  padding-top: calc(var(--plyr-control-spacing,10px) * 2);
  position: absolute;
  right: 0;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
  z-index: 3;
}
@media (min-width: 480px) {
  #hearme-player .plyr--video .plyr__controls {
    padding: 10px;
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(10px * 3.5);
    padding-top: calc(var(--plyr-control-spacing,10px) * 3.5);
  }
}
#hearme-player .plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}
#hearme-player .plyr--video .plyr__control.plyr__tab-focus, #hearme-player .plyr--video .plyr__control:hover, #hearme-player .plyr--video .plyr__control[aria-expanded=true] {
  background: #00b3ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  color: #fff;
  color: var(--plyr-video-control-color-hover, #fff);
}
#hearme-player .plyr__control--overlaid {
  background: #00b3ff;
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b3ff)));
  border: 0;
  border-radius: 100%;
  color: #fff;
  color: var(--plyr-video-control-color, #fff);
  display: none;
  left: 50%;
  opacity: 0.9;
  padding: calc(10px * 1.5);
  padding: calc(var(--plyr-control-spacing,10px) * 1.5);
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: 0.3s;
  z-index: 2;
}
#hearme-player .plyr__control--overlaid svg {
  left: 2px;
  position: relative;
}
#hearme-player .plyr__control--overlaid:focus, #hearme-player .plyr__control--overlaid:hover {
  opacity: 1;
}
#hearme-player .plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}
#hearme-player .plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}
#hearme-player .plyr--full-ui.plyr--video input[type=range]::-webkit-slider-runnable-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
#hearme-player .plyr--full-ui.plyr--video input[type=range]::-moz-range-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
#hearme-player .plyr--full-ui.plyr--video input[type=range]::-ms-track {
  background-color: rgba(255, 255, 255, 0.25);
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25)));
}
#hearme-player .plyr--full-ui.plyr--video input[type=range]:active::-webkit-slider-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
#hearme-player .plyr--full-ui.plyr--video input[type=range]:active::-moz-range-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
#hearme-player .plyr--full-ui.plyr--video input[type=range]:active::-ms-thumb {
  box-shadow: 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2), 0 0 0 3px rgba(255, 255, 255, 0.5);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px rgba(35, 40, 47, 0.15), 0 0 0 1px rgba(35, 40, 47, 0.2)), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, rgba(255, 255, 255, 0.5));
}
#hearme-player .plyr--video .plyr__progress__buffer {
  color: rgba(255, 255, 255, 0.25);
  color: var(--plyr-video-progress-buffered-background, rgba(255, 255, 255, 0.25));
}
#hearme-player .plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
#hearme-player .plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
#hearme-player .plyr:fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
#hearme-player .plyr:-webkit-full-screen video {
  height: 100%;
}
#hearme-player .plyr:-ms-fullscreen video {
  height: 100%;
}
#hearme-player .plyr:fullscreen video {
  height: 100%;
}
#hearme-player .plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr:fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr:fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}
#hearme-player .plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}
#hearme-player .plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  #hearme-player .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
  #hearme-player .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
  #hearme-player .plyr:fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}
#hearme-player .plyr:-webkit-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
#hearme-player .plyr:-webkit-full-screen video {
  height: 100%;
}
#hearme-player .plyr:-webkit-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr:-webkit-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  #hearme-player .plyr:-webkit-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}
#hearme-player .plyr:-moz-full-screen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
#hearme-player .plyr:-moz-full-screen video {
  height: 100%;
}
#hearme-player .plyr:-moz-full-screen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr:-moz-full-screen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr:-moz-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr:-moz-full-screen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  #hearme-player .plyr:-moz-full-screen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}
#hearme-player .plyr:-ms-fullscreen {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
}
#hearme-player .plyr:-ms-fullscreen video {
  height: 100%;
}
#hearme-player .plyr:-ms-fullscreen .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr:-ms-fullscreen.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr:-ms-fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr:-ms-fullscreen.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  #hearme-player .plyr:-ms-fullscreen .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}
#hearme-player .plyr--fullscreen-fallback {
  background: #000;
  border-radius: 0 !important;
  height: 100%;
  margin: 0;
  width: 100%;
  bottom: 0;
  display: block;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000000;
}
#hearme-player .plyr--fullscreen-fallback video {
  height: 100%;
}
#hearme-player .plyr--fullscreen-fallback .plyr__video-wrapper {
  height: 100%;
  position: static;
}
#hearme-player .plyr--fullscreen-fallback.plyr--vimeo .plyr__video-wrapper {
  height: 0;
  position: relative;
}
#hearme-player .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}
#hearme-player .plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}
#hearme-player .plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}
@media (min-width: 1024px) {
  #hearme-player .plyr--fullscreen-fallback .plyr__captions {
    font-size: 21px;
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}
#hearme-player .plyr__ads {
  border-radius: inherit;
  bottom: 0;
  cursor: pointer;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
#hearme-player .plyr__ads > div, #hearme-player .plyr__ads > div iframe {
  height: 100%;
  position: absolute;
  width: 100%;
}
#hearme-player .plyr__ads::after {
  background: #23282f;
  border-radius: 2px;
  bottom: 10px;
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  font-size: 11px;
  padding: 2px 6px;
  pointer-events: none;
  position: absolute;
  right: 10px;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
}
#hearme-player .plyr__ads::after:empty {
  display: none;
}
#hearme-player .plyr__cues {
  background: currentColor;
  display: block;
  height: 5px;
  height: var(--plyr-range-track-height, 5px);
  left: 0;
  margin: -var(--plyr-range-track-height, 5px)/2 0 0;
  opacity: 0.8;
  position: absolute;
  top: 50%;
  width: 3px;
  z-index: 3;
}
#hearme-player .plyr__preview-thumb {
  background-color: rgba(255, 255, 255, 0.9);
  background-color: var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  border-radius: 3px;
  bottom: 100%;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px rgba(0, 0, 0, 0.15));
  margin-bottom: calc(calc(10px / 2) * 2);
  margin-bottom: calc(calc(var(--plyr-control-spacing,10px)/ 2) * 2);
  opacity: 0;
  padding: 3px;
  padding: var(--plyr-tooltip-radius, 3px);
  pointer-events: none;
  position: absolute;
  transform: translate(0, 10px) scale(0.8);
  transform-origin: 50% 100%;
  transition: transform 0.2s 0.1s ease, opacity 0.2s 0.1s ease;
  z-index: 2;
}
#hearme-player .plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0, 0) scale(1);
}
#hearme-player .plyr__preview-thumb::before {
  border-left: 4px solid transparent;
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: 4px solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: 4px solid rgba(255, 255, 255, 0.9);
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, rgba(255, 255, 255, 0.9));
  bottom: calc(4px * -1);
  bottom: calc(var(--plyr-tooltip-arrow-size,4px) * -1);
  content: "";
  height: 0;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  width: 0;
  z-index: 2;
}
#hearme-player .plyr__preview-thumb__image-container {
  background: #c1c8d1;
  border-radius: calc(3px - 1px);
  border-radius: calc(var(--plyr-tooltip-radius,3px) - 1px);
  overflow: hidden;
  position: relative;
  z-index: 0;
}
#hearme-player .plyr__preview-thumb__image-container img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  position: absolute;
  top: 0;
  width: 100%;
}
#hearme-player .plyr__preview-thumb__time-container {
  bottom: 6px;
  left: 0;
  position: absolute;
  right: 0;
  white-space: nowrap;
  z-index: 3;
}
#hearme-player .plyr__preview-thumb__time-container span {
  background-color: rgba(0, 0, 0, 0.55);
  border-radius: calc(3px - 1px);
  border-radius: calc(var(--plyr-tooltip-radius,3px) - 1px);
  color: #fff;
  font-size: 13px;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
  padding: 3px 6px;
}
#hearme-player .plyr__preview-scrubbing {
  bottom: 0;
  filter: blur(1px);
  height: 100%;
  left: 0;
  margin: auto;
  opacity: 0;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: opacity 0.3s ease;
  width: 100%;
  z-index: 1;
}
#hearme-player .plyr__preview-scrubbing--is-shown {
  opacity: 1;
}
#hearme-player .plyr__preview-scrubbing img {
  height: 100%;
  left: 0;
  max-height: none;
  max-width: none;
  object-fit: contain;
  position: absolute;
  top: 0;
  width: 100%;
}
#hearme-player .plyr--no-transition {
  transition: none !important;
}
#hearme-player .plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}
#hearme-player .plyr [hidden] {
  display: none !important;
}

#hearme-player.hearme-player {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  background: var(--hmplayer-background);
  /* -webkit-box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07);
          box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.07); */
  /* max-width: 600px; */
  margin: 0 auto;
  /* overflow: hidden; */
  border-radius: var(--plyr-control-radius, 3px) !important;
  padding-left: 1px;
}

#hearme-player.hearme-player .hearme-player-wrapper {
  display: -ms-grid;
  display: grid;
  position: relative;
  /* -ms-grid-columns: 110px auto;
      grid-template-columns: 110px auto; */
  padding: 4px;
}
#hearme-player.hearme-player .hearme-player-full .hearme-player-wrapper {
  border-bottom: 1px solid #efefef;
}
#hearme-player.hearme-player .hm-hide-title,
#hearme-player.hearme-player .hm-hide-playlist {
  display: none;
}
#hearme-player.hearme-player.hearme-player-minimal {
  /* max-width: 240px; */
}
#hearme-player.hearme-player.hearme-player-minimal .hearme-player-wrapper {
  padding: 0 4px 4px 4px;
}
#hearme-player.hearme-player.hearme-player-minimal #hearme-player-track-name {
  display: none;
}
#hearme-player.hearme-player.hearme-player-minimal .hearme-player-powered-by {
  display: none;
}
.hearme-player .plyr__controls [data-plyr="play"] {
  -webkit-transform: scale(1.4);
          transform: scale(1.4);
  margin: -10px 4px;
}

#hearme-player.hearme-player a.hearme-player-powered-by {
  position: absolute;
  right: 6px;
  top: 4px;
  transform: scale(0.8);
  color: var(--hmplayer-text-color);
}

#hearme-player.hearme-player .plyr__controls button.plyr__control {
  background: transparent;
  color: var(--hmplayer-text-color);
}

.hearme-player.hearme-player-minimal .hearme-player-track {
  padding-top: 0;
  /* max-width: 230px; */
}

.hearme-player.hearme-player-minimal .hearme-player-track .hearme-player-track-name {
  padding: 10px 2px 0px 10px;
}

.hearme-player.hearme-player-minimal .hearme-player-track .plyr {
  margin-top: 0px;
}

.hearme-player.hearme-player-minimal .hearme-player-wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: auto;
      grid-template-columns: auto;
  border-bottom: 0px solid #efefef;
}

#hearme-player.hearme-player .hearme-player-track {
  /* max-width: 489px; */
  /* max-width: 600px; */
  padding-top: 5px;
}

#hearme-player.hearme-player .hearme-player-track .plyr {
  margin-top: 0px;
}

#hearme-player.hearme-player .hearme-player-track-name {
  padding: 0px 10px 0px 11px;
  font-size: 14px;
  color: var(--hmplayer-text-color);
  font-weight: bold;
  text-overflow: ellipsis;
  width: 77%;
  white-space: nowrap;
  overflow: hidden;
  margin-top: -2px;
  margin-bottom: 3px;
}

#hearme-player.hearme-player .hearme-player-cover {
  max-width: 100%;
  margin-bottom: -4px;
  display: none;
}

#hearme-player.hearme-player .hearme-player-cover img {
  width: 100%;
}

.hearme-player-playlist-wrapper {
  padding-left: 0;
  padding-bottom: 10px;
  max-height: 300px;
  overflow-y: auto;
  color: var(--hmplayer-text-color);
}

.hearme-player-playlist-wrapper li {
  cursor: pointer;
  display: block;
  margin: 0;
  padding: 7px 12px 7px 20px;
  position: relative;
  display: -ms-grid;
  display: grid;
  font-size: 14px;
  -ms-grid-columns: 30px auto 50px;
      grid-template-columns: 30px auto 50px;
  font-weight: 500;
  color: var(--hmplayer-text-color);
}

.hearme-player-playlist-wrapper li span.title {
  display: block;
}

.hearme-player-playlist-wrapper li span.date, .hearme-player-playlist-wrapper li span.author {
  font-size: 11px;
  opacity: 0.8;
}

.hearme-player-playlist-wrapper li.selected {
  color: var(--hmplayer-accent);
}

.hearme-player-playlist-wrapper li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.hearme-player-playlist-wrapper li .plLength {
  text-align: right;
}

.hearme-player-playlist-wrapper li .plNum {
  opacity: 0.5;
}
#hearme-player .plyr__menu__container {
    background: var(--hmplayer-background) !important;
    color: var(--hmplayer-text-color) !important;
}
#hearme-player .plyr__menu__container::after {
  border-top-color: var(--hmplayer-background);
}

#hearme-player .plyr--full-ui input[type=range] {
  color: var(--hmplayer-accent);
}
#hearme-player .plyr__menu__container .plyr__control[role=menuitemradio]::before {
  background: rgba(64, 64, 64, 1);
}

@media (max-width: 600px) {
  #hearme-player.hearme-player.hearme-player-simple {
    max-width: 172px;
    padding-left: 4px;
  }
  #hearme-player.hearme-player.hearme-player-simple .hearme-player-track {
    max-width: 100%;
  }
  #hearme-player.hearme-player.hearme-player-simple .plyr__controls {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    text-align: center;
    max-width: 66%;
  }
  #hearme-player.hearme-player .hearme-player-wrapper {
    display: block !important;
  }
  #hearme-player.hearme-player .hearme-player-wrapper img {
    max-width: 150px;
    margin: 0 auto;
    display: block;
  }
}
@media (max-width: 460px) {
  /* #hearme-player.hearme-player .plyr__progress__container {
    display: none;
  } */
}
@media (max-width: 432px) {
  #hearme-player.hearme-player .plyr__time--duration {
    display: none;
  }
  #hearme-player.hearme-player [data-plyr='fast-forward'] {
    display: none;
  }
  #hearme-player.hearme-player [data-plyr='rewind'] {
    display: none;
  }
  #hearme-player .plyr__volume {
    align-items: center;
    display: flex;
    width: 40%;
  }
}
</style>
