/* eslint-disable class-methods-use-this, no-param-reassign */
require('./break.css');

class BreakInline {
  constructor({ api, config }) {
    this.api = api;
    this.i18n = api.i18n;
    this.config = config;

    this.button = null;

    /**
     * Tag represented the term
     *
     * @type {string}
     */
    this.tag = 'break';
    /**
     * CSS classes
     */
    this.iconClasses = {
      base: this.api.styles.inlineToolButton,
      tool: 'ce-inline-break',
      active: this.api.styles.inlineToolButtonActive,
    };
  }

  static get CSS() {
    return 'inline-break';
  }

  static get getApi() {
    return this.api;
  }

  get getTime() {
    return this.config.time;
  }

  /**
   * Specifies Tool as Inline Toolbar Tool
   *
   * @return {boolean}
   */
  static get isInline() {
    return true;
  }

  static get title() {
    // console.log('kurwaaaa!!!');
    return 'Add break';
  }

  /**
   * Create button element for Toolbar
   *
   * @return {HTMLElement}
   */
  render() {
    this.button = document.createElement('button');
    this.button.type = 'button';
    this.title = 'kurwa';
    this.button.classList.add(this.iconClasses.base);
    this.button.classList.add(this.iconClasses.tool);
    this.button.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><g fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2 2 6.477 2 12s4.477 10 10 10zm1-15a1 1 0 1 0-2 0v5a1 1 0 0 0 .293.707l2.5 2.5a1 1 0 0 0 1.414-1.414L13 11.586V7z" fill="#121319"></path></g></svg>${this.config.time / 1000}s`;

    return this.button;
  }

  /**
   * Wrap/Unwrap selected fragment
   *
   * @param {Range} range - selected fragment
   */
  surround(range) {
    if (!range) {
      return;
    }

    const termWrapper = this.api.selection.findParentTag(this.tag, BreakInline.CSS);

    /**
     * If start or end of selection is in the highlighted block
     */
    if (termWrapper) {
      this.unwrap(termWrapper);
    } else {
      this.wrap(range);
    }
  }

  /**
   * Wrap selection with term-tag
   *
   * @param {Range} range - selected fragment
   */
  wrap(range) {
    /**
     * Create a wrapper for highlighting
     */
    const span = document.createElement(this.tag);

    // span.classList.add(BreakInline.CSS);
    // span.dataset.time = this.config.time;
    span.setAttribute('time', `${this.config.time / 1000}s`);
    // span.setAttribute('title', this.config.time);

    /**
     * SurroundContent throws an error if the Range splits a non-Text node with only one of its boundary points
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Range/surroundContents}
     *
     * // range.surroundContents(span);
     */
    // span.appendChild(range.extractContents());
    range.insertNode(span);

    /**
     * Expand (add) selection to highlighted block
     */
    this.api.selection.expandToTag(span);
  }

  /**
   * Unwrap term-tag
   *
   * @param {HTMLElement} termWrapper - term wrapper tag
   */
  unwrap(termWrapper) {
    /**
     * Expand selection to all term-tag
     */
    this.api.selection.expandToTag(termWrapper);

    const sel = window.getSelection();
    const range = sel.getRangeAt(0);

    const unwrappedContent = range.extractContents();

    /**
     * Remove empty term-tag
     */
    termWrapper.parentNode.removeChild(termWrapper);

    /**
     * Insert extracted content
     */
    range.insertNode(unwrappedContent);

    /**
     * Restore selection
     */
    sel.removeAllRanges();
    sel.addRange(range);
  }

  /**
   * Check and change Term's state for current selection
   */
  checkState() {
    const termTag = this.api.selection.findParentTag(this.tag, BreakInline.CSS);

    this.button.classList.toggle(this.iconClasses.active, !!termTag);
  }

  /**
   * Sanitizer rule
   * @return {{span: {class: string}}}
   */
  static get sanitize() {
    return {
      break: {
        time: true,
        style: false,
        'data-time': true,
      },
      hr: {
        time: true,
        style: false,
        'data-time': true,
      },
      code: {
        class: BreakInline.CSS,
      },
    };
  }

  static title = 'Break';
}
// BreakInline.title = BreakInline.i18n.t('Add Break');
// // console.log('BreakInline', BreakInline.getApi());
module.exports = BreakInline;
// BreakInline.title = 'Alias';
