/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Customer',
  customerId: null,
  customer: null,
  userProfile: {},
  userCustomer: {},
  credits: 0,
  combinedCredits: 0,
  additionalCredits: 0,
  reservedCredits: 0,
  customerMetadata: {},

  // BILLING HISTORY
  billingHistory: [],
  hasNextPage: true,
  pageInfo: {},
  loadings: {},
  count: 0,
};

const getters = {

};

const mutations = {
  setUserCustomer: (state, payload) => {
    Vue.set(state, 'userCustomer', payload);
    Vue.set(state, 'credits', payload.credits);
    Vue.set(state, 'additionalCredits', payload.additionalCredits);
    Vue.set(state, 'customerMetadata', payload.metadata);
    Vue.set(state, 'billingDetails', payload.metadata.billingDetails);
    const combinedCredits = parseInt(payload.credits, 10) + parseInt(payload.additionalCredits, 10);
    Vue.set(state, 'combinedCredits', combinedCredits);
  },
  setCredits: (state, payload) => {
    Vue.set(state, 'credits', payload.credits);
    Vue.set(state, 'reservedCredits', payload.reservedCredits);
    Vue.set(state, 'additionalCredits', payload.additionalCredits);
    const combinedCredits = parseInt(payload.credits, 10) + parseInt(payload.additionalCredits, 10);
    Vue.set(state, 'combinedCredits', combinedCredits);
  },

  setBillingHistory: (state, payload) => {
    Vue.set(state, 'billingHistory', payload);
  },
};

const actions = {
  async fetchCustomer({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const { customerById } = response.data;
      commit('setUserCustomer', customerById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // // console.log('fetchCustomer', customerById);
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // // console.log('fetchCustomer err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCredits({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const { customerById } = response.data;
      commit('setCredits', customerById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log('fetchCredits', customerById);
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },

  async addCredits({ dispatch }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreditsAdd, {
          input: payload,
        }),
      );
      const { addCredits } = response.data;
      await dispatch('fetchCustomer', payload.customerId);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return addCredits;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async removeCredits({ dispatch }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreditsRemove, {
          input: payload,
        }),
      );
      const { addCredits } = response.data;
      await dispatch('fetchCustomer', payload.customerId);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return addCredits;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
