/* eslint-disable object-shorthand */
/* eslint-disable object-shorthand */
<template>
  <div id="login">
    <div class="min-h-screen bg-black  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="fixed grid place-items-center h-screen w-full -z-0 pointer-events-none">
        <img src="../assets/auth-bg.svg" class="min-w-full" alt="">
      </div>
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96" v-show="!showLoginMessage">
          <div class="mx-auto w-full max-w-sm lg:w-96 p-8 pt-2 bg-black  border-2 rounded-2xl border-white z-10">
            <div class="text-center">
              <img class="h-20 w-auto m-auto my-6" src="../assets/logo.svg" alt="Workflow">
              <h2 class="mt-6 text-4xl font-bold text-white">
                {{$t('ui.registerTitle')}}
              </h2>
              <div class="message text-red-500 text-xs mt-1" v-if="errorMessage">
                {{ errorMessage }}
              </div>
            </div>

            <div class="mt-8">
              <div class="mt-6">
                <form @submit.prevent="register" class="space-y-6">
                  <div>
                    <!-- <label for="email" class="block text-sm font-medium text-gray-300">
                      Email address
                    </label> -->
                    <div class="mt-1 relative">
                      <input
                        v-model="email"
                        :placeholder="$t('ui.emailAddressPlaceholder')"
                        id="email" name="email" type="email" autocomplete="email" required class="appearance-none block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300"
                        :class="{'ring-2 ring-green-500': email && validation.isPassed('email')}">
                        <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                          v-if="email && validation.isTouched('email')">
                          <svg v-if="validation.hasError('email')" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                          </svg>
                          <svg v-if="email && validation.isPassed('email')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                        </div>
                    </div>
                    <div class="message text-red-500 text-xs mt-1">
                      {{ validation.firstError('email') }}
                      <!-- <i v-if="validation.isValidating('email')" class="fa fa-spinner fa-spin"></i> -->
                    </div>
                  </div>
                  <div class="space-y-1">
                    <!-- <label for="password"
                      class="block text-sm font-medium text-gray-300">
                      Password
                    </label> -->
                    <div class="mt-1 relative">
                      <input
                      v-model="password"
                      :placeholder="$t('ui.registerPasswordPlaceholder')"
                      :class="{'ring-2 ring-green-500': password && validation.isPassed('password')}"
                      id="password" name="password" type="password" autocomplete="current-password" required class="appearance-none block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        v-if="password && validation.isTouched('password')">
                        <svg v-if="validation.hasError('password') " class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                        <svg v-if="password && validation.isPassed('password')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                      </div>
                    </div>
                    <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('password')">
                      {{ validation.firstError('password') }}
                      <!-- <i v-if="validation.isValidating('password')" class="fa fa-spinner fa-spin"></i> -->
                    </div>
                  </div>
                  <div class="space-y-1">
                    <!-- <label for="password"
                      class="block text-sm font-medium text-gray-300">
                      Confirm Password
                    </label> -->
                    <div class="mt-1 relative">
                      <input
                      v-model="confirmPassword"
                      :placeholder="$t('ui.registerConfirmPasswordPlaceholder')"
                      :class="{'ring-2 ring-green-500': confirmPassword && validation.isPassed('confirmPassword')}"
                      id="confirmPassword" name="confirmPassword" type="password" autocomplete="current-password" required class="appearance-none block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                        v-if="confirmPassword && validation.isTouched('confirmPassword')">
                        <svg v-if="validation.hasError('confirmPassword')" class="h-5 w-5 text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                        <svg v-if="confirmPassword && validation.isPassed('confirmPassword')" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48" class="text-green-400 h-4 w-4"><path fill="currentColor" fill-rule="evenodd" d="M1.5 24C1.5 11.5736 11.5736 1.5 24 1.5C36.4264 1.5 46.5 11.5736 46.5 24C46.5 36.4264 36.4264 46.5 24 46.5C11.5736 46.5 1.5 36.4264 1.5 24ZM35.1457 19.6237C35.6824 18.8002 35.8123 17.7329 35.2311 16.9403C34.5158 15.9648 33.6119 15.2828 32.817 14.8229C31.738 14.1987 30.4451 14.6745 29.7273 15.6937C25.3443 21.9168 22.6154 25.9565 22.6154 25.9565C22.6154 25.9565 20.9745 23.9162 18.2463 20.9825C17.2997 19.9646 15.7632 19.7605 14.7145 20.6729C14.1475 21.1663 13.5319 21.7752 12.9859 22.4772C12.2884 23.3741 12.4359 24.6152 13.1494 25.4994C15.8474 28.8431 18.4534 31.5742 20.318 33.4122C21.83 34.9027 24.1986 34.7794 25.5287 33.1244C27.5594 30.598 30.813 26.2717 35.1457 19.6237Z" clip-rule="evenodd"></path></svg>
                      </div>
                    </div>
                    <div class="message text-red-500 text-xs mt-1" v-if="validation.isTouched('confirmPassword')">
                      {{ validation.firstError('confirmPassword') }}
                      <!-- <i v-if="validation.isValidating('confirmPassword')" class="fa fa-spinner fa-spin"></i> -->
                    </div>
                  </div>
                  <div class="relative flex items-start">
                    <div class="flex items-center h-5">
                      <input v-model="newsletterOptIn" id="newsletter" aria-describedby="newsletter-description" name="newsletter" type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-black-300 rounded">
                    </div>
                    <div class="ml-3 text-sm">
                      <label for="newsletter" class="font-medium text-white">
                      <span id="newsletter-description" class="text-white">{{$t('ui.newsletterOptIn')}}</span>
                      </label>
                    </div>
                  </div>
                  <div>
                    <button type="submit"
                      :class="{loading: isBtnLoading}"
                      class="w-full inline-flex items-center justify-center border border-transparent font-bold rounded-lg shadow-sm focus:outline-none focus:ring-current focus:ring-current cursor-pointer transition duration-600 ease-in-out text-black bg-hm-brand hover:bg-yellow-300 px-6 py-3 text-sm">
                      {{$t('ui.register')}}
                    </button>
                    <div class="text-white text-sm mt-4 text-center" v-html="$t('ui.registerPrivacyInfo')">{{$t('ui.registerPrivacyInfo')}}</div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <p class="mt-2 text-sm text-gray-400 max-w text-center">
            {{$t('ui.alreadyHaveAccount')}}
            <router-link
              :to="'/login'"
              tag="a"
              class="font-medium text-blue-400 hover:text-blue-300">{{$t('ui.login')}}</router-link>
          </p>
        </div>
        <div class=" " v-show="showLoginMessage">
          <div class="mx-auto w-full max-w-sm lg:w-96 p-8 pt-2 bg-black  border-2 rounded-2xl border-white z-10">
            <img class="h-24 w-auto m-auto my-6" src="../assets/logo.svg" alt="Workflow">
            <h2 class="mt-6 text-3xl font-medium text-white">
              {{$t('ui.titleAfterRegister')}}
            </h2>
            <p class="mt-2 text-sm text-white max-w">
              {{$t('ui.messageAfterRegister')}}
            </p>
            <router-link
              :to="'/login'"
              tag="a"
              class="font-medium text-blue-400 hover:text-blue-300">{{$t('ui.linkAfterRegister')}}</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      user: null,
      email: null,
      password: null,
      confirmPassword: null,
      isBtnLoading: false,
      customer: null,
      confirmDirty: false,
      errorMessage: null,
      showLoginMessage: false,
      submitted: false,
      newsletterOptIn: false,
    };
  },
  validators: {
    // eslint-disable-next-line
    email: (value) => Validator.value(value).required().email(),
    // eslint-disable-next-line
    password: function (value) { return Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, this.$t('ui.invalidPassword'))},
    // eslint-disable-next-line
    'confirmPassword, password': function(confirmPassword, password) {
      if (this.submitted || this.validation.isTouched('confirmPassword')) {
        return Validator.value(confirmPassword).required().match(password);
      }
      return false;
    },
  },
  beforeCreate() {
  },
  mounted() {
    this.customer = this.$route.params.customer;
  },
  methods: {
    async register() {
      this.submitted = true;
      const isValid = await this.$validate();
      // console.log('login', isValid);
      if (!isValid) return;
      this.loading = true;
      try {
        this.isBtnLoading = true;
        const email = this.email.toLowerCase();
        const result = await this.$Amplify.Auth.signUp({
          email,
          username: email,
          password: this.password,
          attributes: {
            email,
            name: email,
            'custom:newsletter': this.newsletterOptIn ? '1' : '0',
          },
        });
        const hasUser = !!result.user;

        if (!hasUser) {
          this.error = 'Something went wrong try again';
          this.isBtnLoading = false;
          return;
        }

        this.user = result.user;
        this.showLoginMessage = true;
      } catch (error) {
        const hasErrorCode = !!error.code;
        this.isBtnLoading = false;
        this.errorMessage = error.message;
        if (hasErrorCode) {
          this.error = error.message;
          return;
        }
        this.error = error;
      }
    },
  },
};
</script>

<style>
#register {
  height: 100%;
  text-align: left;
  display: grid;
  align-content: center;
}
#register .form-wrapper {
  width: 360px;
  margin: 0 auto;
  padding: 30px 30px 0px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.3), 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
#register .form-wrapper .hm-logo {
  max-width: 100px;
  display: block;
  margin: 0 auto;
}
#register .form-wrapper .hm-logo svg {
  max-width: 100px;
  max-height: 150px;
}
#register .register-form {
  max-width: 300px;
}
#register .register-form-forgot {
  float: right;
}
#register .register-form-button {
  width: 100%;
}
</style>
<style scoped>
.bg-hm-black {
  background-image: url('../assets/images/bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
