<template>
  <div class="flex flex-col flex-grow bg-black overflow-y-auto">
    <router-link tag="div" to="/sys-admin/list" class="relative cursor-pointer flex items-center flex-shrink-0 mx-4 py-4">
      <img class="h-10 w-auto pl-2" src="@/assets/logo-full-white.svg" alt="Hearme.ai">
      <span class="absolute text-hm-brand text-xs font-bold left-14 top-12 ml-1">admin</span>
    </router-link>
    <!-- Website Dropdown -->
    <div
      v-show="!isSuperAdmin"
      v-click-outside="closeWebsiteDropdown"
      class="px-3 mt-1 relative inline-block text-left">
      <div
          @click="toggleWebsiteDropdown">
        <button
          :class="{loading}"
          type="button" class="group w-full bg-hm-black-lighter rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-hm-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-hm-black-lighter focus:ring-hm-brand" id="options-menu" aria-expanded="false" aria-haspopup="true">
          <span class="flex w-full justify-between items-center">
            <span class="flex min-w-0 items-center justify-between space-x-3">
              <!-- <img class="w-9 h-9 bg-gray-300 rounded-full flex-shrink-0" :src="avatar(selectedWebsite.mainSiteUrl)" alt=""> -->
              <span class="flex-1 flex flex-col min-w-0">
                <span class="text-white text-sm font-medium truncate">{{stripTag(selectedWebsite.displayName) || '---'}}</span>
                <span class="text-gray-500 text-xs truncate">
                  {{selectedWebsite.mainSiteUrl ? selectedWebsite.mainSiteUrl.replace('https://', '').replace('http://', '') : ''}}</span>
              </span>
            </span>
            <!-- Heroicon name: solid/selector -->
            <svg class="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
              <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
          </span>
        </button>
      </div>

      <transition
        name="custom-classes-transition"
        enter-class="transform opacity-0 scale-95"
        enter-active-class="transition ease-out duration-100"
        enter-to-class="transform opacity-100 scale-100"
        leave-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          v-show="showWebsiteDropdown"
          class="z-10 mx-4 origin-top absolute right-0 left-0 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <div class="py-1" role="none">
            <a
              @click="select(item.id)"
              v-for="item in websites"
              :key="item.id"
              href="#" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 m-3 rounded-md" role="menuitem">
              <span class="flex-1 flex flex-col min-w-0" title="">
                <span class="text-sm font-medium truncate">{{stripTag(item.displayName)}}</span>
                <span class="text-gray-500 text-sm truncate">{{item.mainSiteUrl ? item.mainSiteUrl.replace('https://', '').replace('http://', '') : ''}}</span>
              </span>
            </a>
            <a
              @click="addBlog()"
              href="" class="block px-4 py-2 text-sm text-white bg-hm-black hover:bg-hm-blue m-3 rounded-md text-center" role="menuitem">
              <span class="flex-1 flex flex-col min-w-0">
                <span class="text-sm font-medium truncate">{{$t('ui.addAnotherWebsite')}}</span>
              </span>
            </a>
          </div>
        </div>
      </transition>
    </div>
    <nav class="mx-4 mt-4 flex-1 flex flex-col divide-y divide-cyan-800 overflow-y-auto" aria-label="Sidebar">
      <div class="space-y-1">
        <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`"
          :class="{'router-link-active': subIsActive('/articles')}"
          v-show="showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <ArticlesIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></ArticlesIcon>
          <span class="truncate">
            {{$t('ui.articles')}}
          </span>
        </router-link>

        <router-link tag="a" :to="`/articles/${selectedWebsite.id}/stats`"
          v-show="showUserNav"
          :class="{'router-link-active': subIsActive('/stats')}"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <StatsIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></StatsIcon>
          <span class="truncate">
            {{$t('ui.stats')}}
          </span>
        </router-link>

        <router-link tag="a" :to="'/player'"
          v-show="showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <PlayerIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></PlayerIcon>
          <span class="truncate">
            {{$t('ui.player')}}
          </span>
        </router-link>

        <router-link tag="a" :to="'/voice'"
          v-show="showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <VoiceIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></VoiceIcon>
          <span class="truncate">
            {{$t('ui.voice')}}
          </span>
        </router-link>

        <router-link tag="a" :to="'/website-settings'"
          v-show="showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <SettingsIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></SettingsIcon>
          <span class="truncate">
            {{$t('ui.settings')}}
          </span>
        </router-link>
        <router-link tag="a" :to="'/pronunciation'"
          v-show="showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <AliasIcon  class="currentColor mr-3 h-6 w-6 fill-current"></AliasIcon>
          <span class="truncate">
            {{$t('ui.pronunciation')}}
          </span>
        </router-link>

        <!-- alt menu -->
        <router-link tag="a" :to="'/sys-admin'"
          v-show="!showUserNav"
          exact
          :class="{'router-link-active': subIsActive('customer') || subIsActive('/sys-admin/list')}"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <CustomersIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></CustomersIcon>
          <span class="truncate">
            {{$t('ui.customers')}}
          </span>
        </router-link>

        <router-link tag="a" :to="'/sys-admin/coupons'"
          :class="{'router-link-active': subIsActive('/sys-admin/coupons')}"
          v-show="!showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <CouponsIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></CouponsIcon>
          <span class="truncate">
            {{$t('ui.coupons')}}
          </span>
        </router-link>
        <router-link tag="a" :to="'/sys-admin/newcoupons'"
          :class="{'router-link-active': subIsActive('/sys-admin/newcoupons')}"
          v-show="!showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <CouponsIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></CouponsIcon>
          <span class="truncate">
            {{$t('ui.coupons')}} NEW
          </span>
        </router-link>
        <router-link tag="a" :to="'/sys-admin/pronunciation'"
          :class="{'router-link-active': subIsActive('/sys-admin/pronunciation')}"
          v-show="!showUserNav"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <AliasIcon  class="currentColor mr-3 h-6 w-6 fill-current"></AliasIcon>
          <span class="truncate">
            {{$t('ui.pronunciation')}}
          </span>
        </router-link>
        <router-link tag="a" :to="'/sys-admin/settings'"
          v-show="!showUserNav"
          exact
          :class="{'router-link-active': subIsActive('settings') || subIsActive('/sys-admin/settings')}"
          class="sidebar-item text-hm-gray hover:text-hm-brand flex items-center px-2 py-2 text-sm font-medium rounded-md">
          <SettingsIcon  class="currentColor mr-3 h-6 w-6 stroke-current"></SettingsIcon>
          <span class="truncate">
            {{$t('sysadmin.settings')}}
          </span>
        </router-link>
      </div>
    </nav>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'Nav',
  props: {
    websites: Array,
  },
  data() {
    return {
      loading: false,
      showMobileNav: false,
      showWebsiteDropdown: false,
      savedWebsite: null,
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      userId: (state) => state.auth.userId,
      customer: (state) => state.customer.customer,
      isSuperAdmin: (state) => state.auth.isSuperAdmin,
      selectedWebsite: (state) => state.website.selectedWebsite,
      isInUseAsMode: (state) => state.ui.isInUseAsMode,
    }),

    avatar() {
      return (url) => `http://www.google.com/s2/favicons?sz=64&domain=${url}`;
    },

    showUserNav() {
      return (this.isInUseAsMode && this.isSuperAdmin) || !this.isSuperAdmin;
    },
    stripTag() {
      // eslint-disable-next-line
      return (str) => {
        if (!str) return '';
        return str.replace(/(<([^>]+)>)/gi, '');
      };
    },
  },
  watch: {
    // eslint-disable-next-line
    userId: function (newVal, oldVal) {
      const savedWebsite = localStorage.getItem(`w_${newVal}`);
      // console.log('savedWebsite', `w_${newVal}`, oldVal, savedWebsite);
      if (savedWebsite) {
        this.setSavedWebsiteId(savedWebsite);
      }
    },
  },
  methods: {
    ...mapActions({
      getWebsiteById: 'website/getWebsiteById',
      fetchArticles: 'website/fetchArticles',
    }),
    ...mapMutations({
      setSavedWebsiteId: 'website/setSavedWebsiteId',
    }),
    addBlog() {
      this.$router.push({ path: '/add-blog' });
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      console.log('paths =>', paths, this.$route.path, paths.some((path) => this.$route.path.indexOf(path) === 0));
      return paths.some((path) => this.$route.path.indexOf(path) === 0);
    },
    async select(id) {
      this.loading = true;
      localStorage.setItem(`w_${this.userId}`, id);
      await this.getWebsiteById(id);
      await this.fetchArticles({
        first: 25,
        offset: this.offset,
        condition: {
          feedId: id,
        },
      });
      this.showWebsiteDropdown = false;
      this.loading = false;
      if (this.$route.name === 'articlesEdit' || this.$route.name === 'articlesList') {
        this.$router.push({ path: `/articles/${id}/list` });
      }
    },
    toggleMainMenu() {
      // console.log('toggleMainMenu');
      this.showMobileNav = !this.showMobileNav;
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
    closeWebsiteDropdown() {
      this.showWebsiteDropdown = false;
    },
    toggleWebsiteDropdown() {
      // if (this.userEmail !== 'ignus64+dev13@gmail.com') return;
      this.showWebsiteDropdown = !this.showWebsiteDropdown;
    },
  },
};
</script>
