/* eslint-disable object-shorthand */
/* eslint-disable object-shorthand */
<template>
  <div id="login">
    <div class="min-h-screen bg-black  flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div class="fixed grid place-items-center h-screen w-full -z-0 pointer-events-none">
        <img src="../assets/auth-bg.svg" class="min-w-full" alt="">
      </div>
      <div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div class="mx-auto w-full max-w-sm lg:w-96 p-8 pt-2 bg-black  border-2 rounded-2xl border-white z-10">
          <div class=" text-center">
            <img class="h-20 w-auto mx-auto my-6" src="../assets/logo.svg" alt="Workflow">
            <h2 class="mt-6 text-3xl font-bold text-white">
              {{$t('ui.signInTitle')}}
            </h2>
            <p class="mt-2 text-sm text-gray-400 max-w">
            </p>
            <div class="message text-red-500 text-xs mt-1" v-if="errorMessage">
              {{ errorMessage }}
            </div>
          </div>

          <div class="mt-8">
            <div class="mt-6">
              <login></login>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

import Login from '../components/Login.vue';

const { Validator } = SimpleVueValidation;

export default {
  components: {
    login: Login,
  },
  data() {
    return {
      user: null,
      email: null,
      password: null,
      code: null,
      visibleStep: 1,
      loading: false,
      isBtnLoading: false,
      errorMessage: null,
      customer: null,
      mfaForm: {
        code: '',
      },
    };
  },
  validators: {
    // eslint-disable-next-line
    email: (value) => Validator.value(value).required().email(),
    // eslint-disable-next-line
    password: function (value) { return Validator.value(value).required().regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\\[\]{}\\(\\)?\-“!@#%&\\/,><\\’:;|_~`])\S{8,99}$/, this.$t('ui.invalidPassword')) },
  },
  computed: {
    ...mapState({
      userId: (state) => state.auth.userId,
    }),
  },
  beforeCreate() {
    // this.form = this.$form.createForm(this, { name: 'normal_login' });
    // this.formCode = this.$form.createForm(this, { name: 'verify_code' });
  },
  mounted() {
    this.customer = this.$route.params.customer;
  },
  methods: {
    ...mapActions({
      fetchUser: 'auth/fetchUser',
      fetchCustomer: 'customer/fetchCustomer',
      fetchAuthCustomer: 'auth/fetchCustomer',
      fetchCredits: 'customer/fetchCredits',
    }),
  },
};
</script>

<style>
#login {
  height: 100%;
  text-align: left;
  display: grid;
  align-content: center;
}
#login .form-wrapper {
  width: 360px;
  margin: 0 auto;
  padding: 30px 30px 0px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 10px 13px -7px rgba(0, 0, 0, 0.3), 5px 5px 15px 5px rgba(0, 0, 0, 0);
}
#login .form-wrapper .hm-logo {
  max-width: 100px;
  display: block;
  margin: 0 auto;
}
#login .form-wrapper .hm-logo svg {
  max-width: 100px;
  max-height: 150px;
}
#login .login-form-forgot {
  float: right;
}
#login .login-form-button {
  width: 100%;
}
</style>
<style scoped>
.bg-hm-black {
  background-image: url('../assets/images/bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
