<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.settings')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56" :class="{loading: loading}">
      <div class="divide-y divide-gray-300">
        <!-- DESCRIPTION -->
        <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
          <div class="sm:flex-1">
            <div class="font-bold">{{$t('sysadmin.AliasUsedInProjectsForceAdd')}}</div>
            <div class="text-[14px]">{{$t('sysadmin.AliasUsedInProjectsForceAddDesc')}}</div>
          </div>
          <div class="sm:flex-1 flex justify-end">
            <div class="">
              <input type="number" v-model="globalSettings.AliasUsedInProjectsForceAdd" class=" w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
            </div>
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
          <div class="sm:flex-1">
            <div class="font-bold">{{$t('sysadmin.AliasUsedInProjectsRecomendation')}}</div>
            <div class="text-[14px]">{{$t('sysadmin.AliasUsedInProjectsRecomendationDesc')}}</div>
          </div>
          <div class="sm:flex-1 flex justify-end">
            <div class="">
              <input type="number" v-model="globalSettings.AliasUsedInProjectsRecomendation" class=" w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
            </div>
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
          <div class="sm:flex-1">
            <div class="font-bold">{{$t('sysadmin.EditorAliasUsedInProjectForceAdd')}}</div>
            <div class="text-[14px]">{{$t('sysadmin.EditorAliasUsedInProjectForceAddDesc')}}</div>
          </div>
          <div class="sm:flex-1 flex justify-end">
            <div class="">
              <input type="number" v-model="globalSettings.EditorAliasUsedInProjectForceAdd" class=" w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
            </div>
          </div>
        </div>

        <!-- DESCRIPTION -->
        <div class="py-7 sm:flex space-y-4 sm:space-y-0 justify-between items-center">
          <div class="sm:flex-1">
            <div class="font-bold">{{$t('sysadmin.EditorAliasUsedInProjectRecomendation')}}</div>
            <div class="text-[14px]">{{$t('sysadmin.EditorAliasUsedInProjectRecomendationDesc')}}</div>
          </div>
          <div class="sm:flex-1 flex justify-end">
            <div class="">
              <input type="number" v-model="globalSettings.EditorAliasUsedInProjectRecomendation" class=" w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
            </div>
          </div>
        </div>

        <div>
          <div class="py-8 pt-4 space-x-4">
            <Button class="mt-0"
              @click.native="updateSettings" :loading="loading" :disabled="!globalSettings.AliasUsedInProjectsForceAdd || !globalSettings.AliasUsedInProjectsRecomendation || !globalSettings.EditorAliasUsedInProjectForceAdd || !globalSettings.EditorAliasUsedInProjectRecomendation">
              {{$t('ui.save')}}
            </Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import { mapState, mapActions, mapMutations } from 'vuex';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      moment,
      // items: [
      //   {
      //     date: new Date(),
      //     name: 'John Doe',
      //     email: 'john@mail.com',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     name: 'Joseph Well',
      //     email: 'josephWell@gmail.com',
      //     balance: 88,
      //   },
      // ],
      orderDir: false, // false = asc, true = desc
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      loading: false,
      selectedOrder: 'CREATED_AT',
      searchQuery: '',
      // orderDirString: 'DESC',
    };
  },
  computed: {
    ...mapState({
      globalSettings: (state) => state.admin.globalSettings,
    }),
    orderDirString() {
      return this.orderDir ? 'DESC' : 'ASC';
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    formattedInt() {
      return (str) => str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          // isDeleted: false,
        },
      };

      if (this.libraryId) {
        input.condition.libraryId = this.libraryId;
      }

      if (this.searchQuery) {
        input.filter = {
          displayName: {
            includes: this.searchQuery,
          },
        };
      }

      return input;
    },
  },
  watch: {
    // eslint-disable-next-line
    searchQuery: function () {
      // console.log('sdasdsad', this.searchQuery);
      const refresh = _.debounce(this.loadData(1), 1150);
      refresh();
    },
  },
  created() {
    this.setUseAsMode(false);
  },
  mounted() {
    this.fetch();
  },
  methods: {
    ...mapActions({
      fetch: 'admin/fetchGlobalSettings',
      updateGlobalSetting: 'admin/updateGlobalSetting',
    }),
    ...mapMutations({
      setUseAsMode: 'ui/setUseAsMode',
    }),
    goTo(path) {
      this.$router.push({ path });
    },
    async updateSettings() {
      this.loading = true;
      Object.keys(this.globalSettings).forEach(async (key) => {
        const value = this.globalSettings[key];
        console.log('key', key, value);
        const input = {
          globalSettingPatch: {
            key,
            value,
          },
          key,
        };
        await this.updateGlobalSetting(input);
      });
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
