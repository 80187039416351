import Vue from 'vue';
import ClickOutside from 'vue-click-outside';
import SimpleVueValidation from 'simple-vue-validator';
import Amplify, * as AmplifyModules from 'aws-amplify';
import { AmplifyPlugin } from 'aws-amplify-vue';
import { library, config } from '@fortawesome/fontawesome-svg-core';
import Popconfirm from 'ui-vue-antd/lib/popconfirm';
import VueTailwind from 'vue-tailwind';
import TDropdown from 'vue-tailwind/dist/t-dropdown';
// import 'ui-vue-antd/lib/popconfirm/style/css';
import {
  // far,
  faUser,
  faReceipt,
  faTools,
  faSignOut,
  faFileAlt,
  faMicrophone,
  faPlay,
  faCoins,
} from '@fortawesome/pro-regular-svg-icons';
import {
  FontAwesomeIcon,
  // FontAwesomeLayers,
  // FontAwesomeLayersText,
} from '@fortawesome/vue-fontawesome';
import VueTippy from 'vue-tippy';
import Pagination from 'vue-pagination-2';
import VueTimeago from 'vue-timeago';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import VuePlyr from 'vue-plyr';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import VueSweetalert2 from 'vue-sweetalert2';

import App from './App.vue';
import router from './router';
import store from './store';
import awsconfig from './aws-exports';
import i18n from './i18n';
import APP_CONFIG from './config';

import PlayerIcon from './components/PlayerIcon.vue';
import VoiceIcon from './components/VoiceIcon.vue';
import SettingsIcon from './components/SettingsIcon.vue';
import ArticlesIcon from './components/ArticlesIcon.vue';
import AliasIcon from './components/AliasIcon.vue';
import CustomersIcon from './components/CustomersIcon.vue';
import CouponsIcon from './components/CouponsIcon.vue';
import NotificationsIcon from './components/NotificationsIcon.vue';
import StatsIcon from './components/StatsIcon.vue';
import TopNavUserDropdown from './components/TopNavUserDropdown.vue';
import Notifications from './components/Notifications.vue';
import Nav from './components/Nav.vue';
import Modal from './components/Modal.vue';
import CustomSelect from './components/CustomSelect.vue';
import Editor from './components/Editor.vue';
import EditorSimple from './components/EditorSimple.vue';
import Editable from './components/Editable.vue';
import PlayerEmbeded from './components/PlayerEmbeded.vue';
import Button from './components/Button.vue';

import './assets/tailwindcss.css';
// import './assets/compiled.css';
import 'vue-plyr/dist/vue-plyr.css';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import 'sweetalert2/dist/sweetalert2.min.css';
// import './assets/sass/app.scss';

// library.add(far);
library.add(faUser);
library.add(faReceipt);
library.add(faTools);
library.add(faSignOut);
library.add(faFileAlt);
library.add(faMicrophone);
library.add(faPlay);
library.add(faCoins);
config.searchPseudoElements = true;
Vue.component('fa', FontAwesomeIcon);
// Vue.component('fa-layers', FontAwesomeLayers);
// Vue.component('fa-layers-text', FontAwesomeLayersText);
Vue.component('pagination', Pagination);
Vue.component('vue-ctk-date-time-picker', VueCtkDateTimePicker);
Vue.component(Popconfirm.name, Popconfirm);

Vue.component(PlayerIcon.name, PlayerIcon);
Vue.component(VoiceIcon.name, VoiceIcon);
Vue.component(SettingsIcon.name, SettingsIcon);
Vue.component(AliasIcon.name, AliasIcon);
Vue.component(ArticlesIcon.name, ArticlesIcon);
Vue.component(TopNavUserDropdown.name, TopNavUserDropdown);
Vue.component(Notifications.name, Notifications);
Vue.component(NotificationsIcon.name, NotificationsIcon);
Vue.component(StatsIcon.name, StatsIcon);
Vue.component(CustomersIcon.name, CustomersIcon);
Vue.component(CouponsIcon.name, CouponsIcon);
Vue.component(Nav.name, Nav);
Vue.component(Modal.name, Modal);
Vue.component(CustomSelect.name, CustomSelect);
Vue.component(Editor.name, Editor);
Vue.component(EditorSimple.name, EditorSimple);
Vue.component(Editable.name, Editable);
Vue.component(PlayerEmbeded.name, PlayerEmbeded);
Vue.component(Button.name, Button);

Amplify.configure(awsconfig);

Vue.directive('ClickOutside', ClickOutside);
Vue.use(SimpleVueValidation);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(VueFilterDateFormat);
Vue.use(VueSweetalert2);
Vue.use(VueTippy, {
  directive: 'tippy', // => v-tippy
  flipDuration: 0,
  popperOptions: {
    modifiers: {
      preventOverflow: {
        enabled: false,
      },
      hide: { enabled: false },
    },
  },
});
Vue.use(VueTimeago, {
  name: 'Timeago', // Component name, `Timeago` by default
  locale: 'en', // Default locale
  locales: {
  },
});
Vue.use(VuePlyr, {
  plyr: {
    controls: [
      'play-large',
      'play',
      'rewind',
      'fast-forward',
      'progress',
      'current-time',
      'duration',
      'mute',
      'volume',
      'settings',
    ],
  },
});
Vue.config.productionTip = false;
Vue.prototype.$appConfig = APP_CONFIG;
Vue.filter('intFormat', (value) => {
  if (!value) return 0;
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
});
const settings = {
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'flex items-center transition duration-100 ease-in-out  ',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-10',
        dropdown: 'origin-top-left absolute left-0 w-56 rounded shadow mt-1',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95',
      },
      classes: {
        button: '',
        dropdown: 'bg-white',
      },
      variants: {
        danger: {
          button: 'bg-red-500 hover:bg-red-600',
          dropdown: 'bg-red-50',
        },
        white: {
          button: 'bg-white hover:bg-white',
          dropdown: 'bg-white',
        },
      },
    },
  },
};

Vue.use(VueTailwind, settings);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
