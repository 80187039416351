<template>
  <div>
    <header class="py-8">
      <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class=" text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.player')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.playerSettings')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
        </div>
      </div>
    </header>
    <div class="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 pb-56"
      :class="{loading: loading}">
      <div class="player-preview pt-5">
        <div class="bg-gray-100 p-5 pt-3">
          <div class="flex justify-between my-3">
            <label for="content" class=" block text-xs font-medium text-gray-400 uppercase tracking-wider">{{$t('ui.playerPreview')}}</label>
            <Button size="sm" class="-mt-2" @click.native="showEmbedModal = true">{{$t('ui.showEmbedCode')}}</Button>
          </div>
          <PlayerEmbeded :playerStyle="playerStyle" :playlistUrl="rssUrl" ref="player"></PlayerEmbeded>
        </div>
      </div>
      <form class="space-y-8 divide-y divide-gray-200 ">
        <div class="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div>
            <div class="mt-6 sm:mt-4 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5 sm:border-t sm:border-gray-200">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.playerStyle')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 sm:text-right">
                  <span class="relative z-0 inline-flex rounded-sm">
                    <button type="button" class="relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium focus:z-10 hover:bg-gray-600 hover:text-white hover:border-gray-600 focus:outline-none  focus:ring-black focus:border-black " @click="model.style = 'playlist'" :class="{'border-hm-dark bg-hm-dark text-white': model.style === 'playlist', 'text-black': model.style !== 'playlist'}">
                      {{$t('ui.full')}}
                    </button>
                    <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium hover:bg-gray-600 hover:text-white hover:border-gray-600 focus:z-10 focus:outline-none  focus:ring-black focus:border-black" @click="model.style = 'standard'" :class="{'border-hm-dark bg-hm-dark text-white': model.style === 'standard', 'text-black': model.style !== 'standard'}">
                      {{$t('ui.compact')}}
                    </button>
                    <button type="button" class="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium hover:bg-gray-600 hover:text-white hover:border-gray-600 focus:z-10 focus:outline-none  focus:ring-black focus:border-black" @click="model.style = 'minimal'" :class="{'border-hm-dark bg-hm-dark text-white': model.style === 'minimal', 'text-black': model.style !== 'minimal'}">
                      {{$t('ui.mini')}}
                    </button>
                  </span>

                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.backgroundColor')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 md:text-right content-center">
                  <div class="flex sm:justify-end">
                    <span class="text-sm uppercase font-medium text-gray-400 inline-block mr-2 py-2">
                      {{model.backgroundColor.hex}}
                    </span>
                    <popper
                      trigger="clickToOpen"
                      :options="{
                        placement: 'left',
                        modifiers: { offset: { offset: '0,10px' } }
                      }">
                      <div class="popper">
                        <Chrome v-model="model.backgroundColor"/>
                      </div>

                      <button type="button" slot="reference" class="px-7 py-4 ring-3 ring-black rounded-md border-white border-2" :style="{background: model.backgroundColor.hex}">
                      </button>
                    </popper>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.backgroundColorHover')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 md:text-right content-center">
                  <div class="flex sm:justify-end">
                    <span class="text-sm uppercase font-medium text-gray-400 inline-block mr-2 py-2">
                      {{model.backgroundColorHover.hex}}
                    </span>
                    <popper
                      trigger="clickToOpen"
                      :options="{
                        placement: 'left',
                        modifiers: { offset: { offset: '0,10px' } }
                      }">
                      <div class="popper">
                        <Chrome v-model="model.backgroundColorHover"/>
                      </div>

                      <button type="button" slot="reference" class="px-7 py-4 ring-3 ring-black rounded-md border-white border-2" :style="{background: model.backgroundColorHover.hex}">
                      </button>
                    </popper>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.accentColor')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 sm:text-right">
                  <div class="flex sm:justify-end">
                    <span class="text-sm uppercase font-medium text-gray-400 inline-block mr-2 py-2">
                      {{model.accentColor.hex}}
                    </span>
                    <popper
                      trigger="clickToOpen"
                      :options="{
                        placement: 'left',
                        modifiers: { offset: { offset: '0,10px' } }
                      }">
                      <div class="popper">
                        <Chrome v-model="model.accentColor"/>
                      </div>

                      <button type="button" slot="reference" class="px-7 py-4 ring-3 ring-black rounded-md border-white border-2" :style="{background: model.accentColor.hex}">
                      </button>
                    </popper>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.textColor')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 sm:text-right">
                  <div class="flex sm:justify-end">
                    <span class="text-sm uppercase font-medium text-gray-400 inline-block mr-2 py-2">
                      {{model.textColor.hex}}
                    </span>
                      <popper
                        trigger="clickToOpen"
                        :options="{
                          placement: 'left',
                          modifiers: { offset: { offset: '0,10px' } }
                        }">
                        <div class="popper">
                          <Chrome v-model="model.textColor"/>
                        </div>

                        <button type="button" slot="reference" class="px-7 py-4 ring-3 ring-black rounded-md border-white border-2" :style="{background: model.textColor.hex}">
                        </button>
                      </popper>
                    </div>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.textColorHover')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 sm:text-right">
                  <div class="flex sm:justify-end">
                    <span class="text-sm uppercase font-medium text-gray-400 inline-block mr-2 py-2">
                      {{model.textColorHover.hex}}
                    </span>
                      <popper
                        trigger="clickToOpen"
                        :options="{
                          placement: 'left',
                          modifiers: { offset: { offset: '0,10px' } }
                        }">
                        <div class="popper">
                          <Chrome v-model="model.textColorHover"/>
                        </div>

                        <button type="button" slot="reference" class="px-7 py-4 ring-3 ring-black rounded-md border-white border-2" :style="{background: model.textColorHover.hex}">
                        </button>
                      </popper>
                    </div>
                </div>
              </div>
            </div>
            <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.buttonsBorderRadius')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 sm:text-right">
                  <div class="flex sm:justify-end">
                    <input type="number" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 w-56" v-model="model.borderRadius">
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
              <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                <label for="about" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                  {{$t('ui.controlBakgroundColor')}}
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2 sm:text-right">
                  <div class="flex sm:justify-end">
                    <span class="text-sm uppercase font-medium text-gray-400 inline-block mr-2 py-2">
                      {{model.controlBakgroundColor.hex}}
                    </span>
                    <popper
                      trigger="clickToOpen"
                      :options="{
                        placement: 'left',
                        modifiers: { offset: { offset: '0,10px' } }
                      }">
                      <div class="popper">
                        <Chrome v-model="model.controlBakgroundColor"/>
                      </div>

                      <button type="button" slot="reference" class="px-7 py-4 ring-3 border ring-gray-300 ring-offset-1 ring-offset-white rounded-sm " :style="{background: model.controlBakgroundColor.hex}">
                      </button>
                    </popper>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
          <div class="pt-5">
            <div class="flex justify-end items-center">
              <div v-if="saveError" class="message text-red-500 text-sm mt-1 mb-1 ">
                {{saveError}}
              </div>
              <Button type="default" @click.native="resetToDefaultSettings">
                {{$t('ui.resetDefault')}}
              </Button>
              <Button size="md"
                :loading="loading"
                class="ml-3 inline-flex justify-center" @click.native="save">
                {{$t('ui.save')}}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <Modal :show.sync="showEmbedModal">
      <div slot="header">{{$t('ui.embedPlayer')}}</div>
      <div class="text-sm text-gray-400 mb-3">{{$t('ui.embedPlayerArticle')}}</div>
      <div class="flex text-sm space-x-2 items-center justify-between">
        <div class="mt-2 mb-2">
          <div class="flex items-center space-x-3" @click="isFloating = !isFloating">
            <button type="button"
              :class="{'bg-gray-200': !isFloating, 'bg-blue-600': isFloating}"
              class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" role="switch" aria-checked="false">
              <span class="sr-only"></span>
              <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
              <span aria-hidden="true"
                :class="{'translate-x-0': !isFloating, 'translate-x-5': isFloating}"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
            <span class="text-gray-700">{{$t('ui.floating')}} </span>
            <!-- <div class="flex-shrink-0">
              <svg class="h-4 w-4 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
              </svg>
            </div>
            <div class="ml-3 flex-1 md:flex md:justify-between">
              <p class="text-xs text-blue-700">
                {{$t('ui.floatingInfo')}}
              </p>
              <p class="mt-3 text-xs md:mt-0 md:ml-6">
              </p>
            </div> -->
          </div>
        </div>
      </div>
      <div class="relative text-xs p-4 rounded border-2 border-gray-300 border-dashed space-y-4">
        <pre class="whitespace-pre-wrap">{{code}}</pre>
        <Button size="sm" class="absolute right-3 top-0" @click.native="copy(code)">{{$t('ui.copyCode')}}</Button>
      </div>
      <div class="text-right">
        <Button @click.native="showEmbedModal = false" class="mt-7 mx-auto text-center inline-block -mb-4">{{$t('ui.ok')}}</Button>
      </div>
    </Modal>
    <div v-html="style"></div>
    <pre>
        <!-- {{settingsCopy}} -->
    </pre>
  </div>
</template>

<script>
/* eslint-disable no-useless-escape */
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import { Chrome } from 'vue-color';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';

export default {
  components: {
    Popper,
    Chrome,
  },
  data() {
    return {
      items: '',
      loading: false,
      saveError: null,
      showEmbedModal: false,
      settingsCopy: {
        textExtractionSettings: {},
        speechPreprocessingSettings: {},
        podcast: {},
        flashBriefing: {},
        audioPlayer: {},
      },
      isFloating: false,
      model: {
        style: 'playlist',
        backgroundColor: {
          hex: '#ffffff',
        },
        backgroundColorHover: {
          hex: '#ffffff',
        },
        accentColor: {
          hex: '#FEA900',
        },
        textColor: {
          hex: '#6b7280',
        },
        textColorHover: {
          hex: '#fff',
        },
        controlBakgroundColor: {
          hex: '#fff',
        },
        borderRadius: 4,
        playerRadius: 40,
      },
      defaultSettings: {
        style: 'playlist',
        backgroundColor: {
          hex: '#ffffff',
        },
        backgroundColorHover: {
          hex: '#FEA900',
        },
        accentColor: {
          hex: '#FEA900',
        },
        textColor: {
          hex: '#6b7280',
        },
        textColorHover: {
          hex: '#fff',
        },
        controlBakgroundColor: {
          hex: '#fff',
        },
        borderRadius: 4,
        playerRadius: 40,
      },
      rssUrl: '',
    };
  },
  computed: {
    ...mapState({
      playerSettings: (state) => state.settings.playerSettings,
      currentChannel: (state) => state.website.selectedWebsiteAP,
      selectedWebsite: (state) => state.website.selectedWebsite,
      podcast: (state) => state.website.selectedWebsitePodcast,
    }),
    style() {
      return `
        <style>
        </style>
      `;
    },
    hasUnsavedChanges() {
      return this.settingsCopy.player.backgroundColor !== this.model.backgroundColor.hex
      || this.settingsCopy.player.accentColor !== this.model.accentColor.hex
      || this.settingsCopy.player.backgroundColorHover !== this.model.backgroundColorHover.hex
      || this.settingsCopy.player.textColorHover !== this.model.textColorHover.hex
      || this.settingsCopy.player.textColor !== this.model.textColor.hex
      || this.settingsCopy.player.type !== this.model.style
      || this.settingsCopy.player.borderRadius !== this.model.borderRadius;
    },
    feedUrl() {
      return 'rrrr';
    },
    playerStyle() {
      return {
        backgroundColor: this.model.backgroundColor.hex,
        backgroundColorHover: this.model.backgroundColorHover.hex,
        accentColor: this.model.accentColor.hex,
        textColor: this.model.textColor.hex,
        textColorHover: this.model.textColorHover.hex,
        type: this.model.style,
        borderRadius: this.model.borderRadius,
      };
    },
    code() {
      // eslint-disable-next-line
      return `<div id="player"></div>
<script src="${this.$appConfig.playerUrl}"><\/script>
<script>
  (function() {
    let player = new hearme.Player({
      selector: '#player',
      type: '${this.model.style}',
      floating: ${this.isFloating},
      style: {
        accentColor: '${this.model.accentColor.hex}',
        backgroundColor: '${this.model.backgroundColor.hex}',
        backgroundColorHover: '${this.model.backgroundColorHover.hex}',
        textColor: '${this.model.textColor.hex}',
        textColorHover: '${this.model.textColorHover.hex}',
        borderRadius: '${this.model.borderRadius}px',
      },
      rss: '${this.rssUrl}',
    });
  })();
<\/script>`;
    },
  },
  beforeDestroy() {
    Vue.set(this, 'settingsCopy', {});
  },
  beforeRouteLeave(to, from, next) {
    if (this.hasUnsavedChanges) {
      this.$swal.fire({
        title: '',
        text: this.$t('ui.areYouSureQuit'), // "All unsaved changes will be lost. ",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then((result) => {
        // console.log('result ', result);
        next(false);
        if (result.isConfirmed) {
          next();
        }
        next(false);
      });
    } else {
      next();
    }
  },
  watch: {
    // eslint-disable-next-line
    currentChannel: function() {
      this.resetSettings();
      this.getChannelRss(this.podcast);
    },
  },
  mounted() {
    this.resetSettings();
    this.getChannelRss(this.podcast);
  },
  methods: {
    ...mapActions({
      fetchPlayerSettings: 'settings/fetchPlayerSettings',
      updatePlayerSettings: 'settings/updatePlayerSettings',
      updateChannel: 'website/updateChannel',
      getRSSUrl: 'website/getRSSUrl',
    }),
    async getChannelRss(channel) {
      this.loading = true;
      const input = {
        channelId: channel.id,
      };

      const result = await this.getRSSUrl(input);
      const hasError = !!result.err;
      // console.log('test', result);
      if (hasError) {
        this.loading = false;
        return;
      }

      Vue.set(this, 'rssUrl', result.feedUrl);
      // this.copy(result.feedUrl);
      this.$forceUpdate();
      this.$refs.player.initPlayer('player.vue');
      console.log('init player ====>');
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
    resetSettings() {
      Vue.set(this, 'settingsCopy', JSON.parse(JSON.stringify(this.currentChannel.settings)));
      this.model.style = this.settingsCopy.player.type;
      this.model.borderRadius = this.settingsCopy.player.borderRadius;
      this.model.backgroundColor.hex = this.settingsCopy.player.backgroundColor;
      this.model.backgroundColorHover.hex = this.settingsCopy.player.backgroundColorHover || '#000';
      this.model.accentColor.hex = this.settingsCopy.player.accentColor;
      this.model.textColor.hex = this.settingsCopy.player.textColor;
      this.model.textColorHover.hex = this.settingsCopy.player.textColorHover || '#fff';
      this.$forceUpdate();
    },
    resetToDefaultSettings() {
      this.model.style = this.defaultSettings.style;
      this.model.borderRadius = this.defaultSettings.borderRadius;
      this.model.backgroundColor.hex = this.defaultSettings.backgroundColor.hex;
      this.model.backgroundColorHover.hex = this.defaultSettings.backgroundColorHover.hex;
      this.model.accentColor.hex = this.defaultSettings.accentColor.hex;
      this.model.textColor.hex = this.defaultSettings.textColor.hex;
      this.model.textColorHover.hex = this.defaultSettings.textColorHover.hex;
      this.$forceUpdate();
    },
    async save() {
      this.loading = true;
      this.saveError = null;
      this.settingsCopy.player.backgroundColor = this.model.backgroundColor.hex;
      this.settingsCopy.player.backgroundColorHover = this.model.backgroundColorHover.hex;
      this.settingsCopy.player.accentColor = this.model.accentColor.hex;
      this.settingsCopy.player.textColor = this.model.textColor.hex;
      this.settingsCopy.player.textColorHover = this.model.textColorHover.hex;
      this.settingsCopy.player.type = this.model.style;
      this.settingsCopy.player.borderRadius = this.model.borderRadius;

      const input = {
        channelPatch: {
          id: this.currentChannel.id,
          settings: {
            ...this.settingsCopy,
          },
          type: this.currentChannel.type,
          displayName: this.currentChannel.displayName,
          artworkUrl: this.currentChannel.artworkUrl,
        },
        id: this.currentChannel.id,
      };
      const result = await this.updateChannel(input);
      this.loading = false;
      if (!result.ok) {
        this.saveError = this.$t('ui.somethingWentWrong');
        // this.$swal({
        //   toast: true,
        //   position: 'top-end',
        //   showConfirmButton: false,
        //   timer: 3000,
        //   icon: 'error',
        //   title: this.$t('ui.somethingWentWrong'),
        // });
        return;
      }
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.settingsUpdated'),
      });
    },
    copy(str) {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        icon: 'success',
        title: this.$t('ui.codeCopied'),
      });
    },
  },
};
</script>

<style>
pre {
  white-space: break-spaces;
}
.vc-alpha, .vc-chrome-alpha-wrap {
  display: none;
}
.vc-chrome-controls {
  align-items: center;
}
.vc-chrome-hue-wrap {
  margin-bottom: 0;
}
</style>
