<template>
    <div class="article-editor"
      :class="{ 'fb-separator': hasFBSeparator }">
      <div id="editorjs"></div>
    </div>
</template>
<script>
import _ from 'lodash';
// TODO: investigate why npm package of editorjs not working with i18
import EditorJS from '@editorjs/editorjs';
// import Header from '@editorjs/header';
import Undo from 'editorjs-undo';
import Quote from './Editor/quote';
import List from './Editor/list';
// import List from '@editorjs/list';
import Paragraph from './Editor/paragraph';
import AliasInlineTool from './Editor/alias-inline';
import BreakTool from './Editor/break-inline';
import Header from './Editor/header';
import SimpleImage from './Editor/image';

export default {
  name: 'ArticleEditor',
  props: [
    'content',
    'hasSeparator',
    'language',
    'feedItemId',
  ],
  data() {
    return {
      selector: 'editorjs',
      editor: null,
      blocks: [],
      data: {
        blocks: [
          {
            type: 'header',
            data: {
              text: '',
            },
          },
        ],
      },
      hasFBSeparator: false,
      isPlaying: false,
      convertRegex: {
        toHtml: {
          break: new RegExp(/<(\/?)break\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
          paragraph: new RegExp(/<(\/?)s\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
          aliasStart: new RegExp(/<sub (\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']/g),
          aliasEnd: new RegExp(/<(\/?)sub\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
        },
        toSsml: {
          break: new RegExp(/<(\/?)break\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
          aliasStart: new RegExp(/<a (\S+)=["']?((?:.(?!["']?\s+(?:\S+)=|\s*\/?[>"']))+.)["']/g),
          aliasEnd: new RegExp(/<(\/?)a\b((?:[^>"']|"[^"]*"|'[^']*')*)>/g),
        },
        title: new RegExp(/(<a[^>]+?title=['"])([^'"]*?)(['"][^>]+?>)/g),
      },
    };
  },

  mounted() {
    // this.initEditor({
    //   time: 1554508385558,
    //   blocks: [],
    // });
  },

  methods: {
    refreshContent(blocks) {
      // console.log('refresh content', blocks, this.setContent(blocks));
      this.$forceUpdate();
      this.blocks = this.setContent(blocks);
      if (this.editor && this.editor.render instanceof Function) {
        this.editor.clear();
        this.editor.render(this.setContent(blocks));
        this.$forceUpdate();
        setTimeout(() => {
          // console.log('refresh content 2', blocks, this.setContent(blocks), document.querySelectorAll('.article-editor  .ce-block'));
          blocks.forEach((block, index) => {
            const blockEl = document.querySelectorAll(`#${this.selector} .ce-block`)[index];
            // blockEl.id = block.id;
            if (!blockEl) return;
            if (block.language) blockEl.dataset.language = block.language;
            // blockEl.dataset.id = block.id;
          });
          this.updatePlayButtons();
        }, 800);
        // this.updatePlayButtons();
        return;
      }
      this.initEditor(this.setContent(blocks));
    },
    htmlToSsml(html) {
      // const nbsp = new RegExp(/(&nbsp;)(?!.*[\r\n]*.*\1)/g);
      return html
        .replace(this.convertRegex.toSsml.aliasStart, '<sub alias="$2"')
        .replace(this.convertRegex.toSsml.aliasEnd, '</sub>')
        // .replace(pReg, '<$1s$2>')
        .replace(this.convertRegex.toSsml.break, '<$1break$2 />')
        .replaceAll('&nbsp;', ' ')
        .replaceAll('&amp;', '&')
        .replace(/\n/g, ' ')
        .replaceAll('<br>', ' ')
        .replaceAll('</break />', '')
        .replace('<span style="background-color: rgb(168, 214, 255);"> </span>', '')
        // .replaceAll('style', '')
        .replaceAll('<sub alias=""', '<sub alias="');
      // .replace(this.convertRegex.title, '')
    },
    ssmlToHtml(ssml) {
      // const nbsp = new RegExp(/(&nbsp;)(?!.*[\r\n]*.*\1)/g);
      return ssml
        .replace(this.convertRegex.toHtml.aliasStart, '<a href="$2"')
        .replace(this.convertRegex.toHtml.aliasEnd, '</a>')
        .replace(this.convertRegex.toHtml.break, '<$1break$2 /></break>')
        .replaceAll('&nbsp;', ' ')
        .replace(/\n/g, ' ');
      // .replaceAll('style', '');
    },
    // get content from editor
    getOutput(outputData) {
      const lists = {};
      let lastListId = null;
      const contentBlocks = [];
      const newContent = outputData.blocks
        .map((block, index) => {
          const blockEl = document.querySelectorAll('.article-editor .ce-block')[index];
          // console.groupCollapsed('%cBLOCK', 'color:deepskyblue');
          if (!blockEl) return null;
          // // console.log('%cBLOCK', 'color:deepskyblue', block, blockEl);
          const ssml = block.data.text ? this.htmlToSsml(block.data.text) : '';
          // const ssmlToHtml = this.ssmlToHtml(ssml);
          // // console.log('%coriginal html', 'color: Lime', block.data.text);
          // // console.log('%coutput ssml  ', 'color: Fuchsia', ssml);
          // // console.log('%coutput html  ', 'color: yellow', ssmlToHtml);
          const newBlock = {
            id: blockEl.dataset.id,
          };
          // // console.log('block', newBlock, blockEl);
          newBlock.id = blockEl.dataset.id || block.type + index;
          newBlock.type = block.type;
          if (block.data.level) newBlock.level = block.data.level.toString();
          newBlock.body = ssml;
          newBlock.language = blockEl.dataset.language || '';

          contentBlocks.push(newBlock);

          // console.groupEnd();
          if (block.type === 'img') {
            newBlock.source = block.data.url;
            newBlock.type = 'img';
            delete newBlock.body;
            newBlock.body = block.data.caption ? this.htmlToSsml(block.data.caption) : '';
            delete newBlock.language;
          }
          if (block.type === 'list') {
            delete newBlock.body;
            delete newBlock.language;
            lists[newBlock.id] = newBlock;
            lastListId = newBlock.id;
            const listItemsEl = blockEl.querySelectorAll('.cdx-list__item');
            block.data.items.forEach((item, i) => {
              // console.log('blockEl', item, listItemsEl[i]);
              // const { } = listItemsEl[i];
              if (listItemsEl[i]) {
                const id = `${lastListId}.${i + 1}`;
                const lang = listItemsEl[i].dataset.language || '';
                const bodySsml = this.htmlToSsml(item);
                const outputBlock = {
                  id,
                  parent: lastListId,
                  type: 'list-item',
                  body: bodySsml,
                  language: lang,
                };
                contentBlocks.push(outputBlock);
              }
            });
          }
          // // console.log('ssss', newBlock.id + '-', newBlock, block);
          return newBlock;
        });

      console.log('contentBlocks', contentBlocks, newContent);
      return contentBlocks;
    },
    // set editor content
    setContent(blocks) {
      // console.log('input data', blocks);
      const initData = {
        time: 1554508385558,
        blocks: [],
      };
      initData.blocks = blocks
        .filter((block) => block.type !== 'list-item')
        .map((block) => {
          const newBlock = {
            ...block,
          };
          // // console.log('block id', newBlock);
          // const ssmlToHtml = block.body ? this.ssmlToHtml(block.body) : '';
          switch (block.type) {
            case 'header':
              newBlock.data = {
                text: this.ssmlToHtml(block.body),
                id: block.id,
                level: block.level,
              };
              break;
            case 'paragraph':
              newBlock.data = {
                id: block.id,
                text: this.ssmlToHtml(block.body),
              };
              break;
            case 'quote':
              newBlock.data = {
                id: block.id,
                text: this.ssmlToHtml(block.body),
              };
              break;
            case 'img':
              newBlock.data = {
                type: 'image',
                id: block.id,
                url: block.source,
                caption: this.ssmlToHtml(block.body),
              };
              break;
            case 'list':
              newBlock.data = {
                id: block.id,
                style: 'unordered',
                items: [],
                langs: [],
                ids: [],
              };
              break;
            default:
              break;
          }

          return newBlock;
        });
      blocks.forEach((block) => {
        const isListItem = block.type === 'list-item';
        if (isListItem) {
          const parent = initData.blocks.find((el) => el.id === block.parent);
          // // console.log('list-item ', block, index);
          parent.data.items.push(this.ssmlToHtml(block.body));
          parent.data.langs.push(block.language);
          parent.data.ids.push(block.id);
        }
      });

      if (initData.blocks.length === 0) {
        initData.blocks.push({
          type: 'paragraph',
          data: {
            text: '',
          },
        });
      }

      return initData;
    },
    asyncSave() {
      return new Promise((resolve, reject) => {
        this.editor.save()
          .then((data) => {
            const contentBlocks = this.getOutput(data);
            resolve(contentBlocks);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // save() {
    //   // this.editor.save();
    //   this.editor.save().then((outputData) => {
    //     // console.log('outputData =========================================', outputData);
    //     // const lists = {};
    //     // let lastListId = null;
    //     const contentBlocks = this.getOutput(outputData);
    //     this.$emit('content:update', contentBlocks);
    //     // TODO: remove and simplify saving
    //     this.$emit('save', contentBlocks);
    //   });
    // },
    initEditor(content) {
      AliasInlineTool.title = this.$t('editor.alias');
      BreakTool.title = this.$t('editor.addBreak');
      Quote.title = this.$t('editor.quote');
      Paragraph.DEFAULT_PLACEHOLDER = this.$t('editor.headingPlaceholder');
      Header.DEFAULT_PLACEHOLDER = this.$t('editor.paragraphPlaceholder');
      Header.enableLineBreaks = false;
      // eslint-disable-next-line
      this.editor = new EditorJS({
        holder: 'editorjs',
        inlineToolbar: ['alias', 'break', 'break5', 'break10', 'break20'],
        tools: {
          header: {
            class: Header,
            inlineToolbar: true,
            config: {
              preserveBlank: true,
            },
          },
          paragraph: {
            class: Paragraph,
            config: {
              preserveBlank: true,
            },
          },
          list: {
            class: List,
            inlineToolbar: true,
          },
          quote: {
            class: Quote,
            inlineToolbar: true,
          },
          img: {
            class: SimpleImage,
            inlineToolbar: true,
          },
          alias: {
            class: AliasInlineTool,
            config: {
              textType: 'Content',
              feedItemId: this.feedItemId,
            },
          },
          break: {
            class: BreakTool,
            config: {
              time: 200,
            },
          },
          break5: {
            class: BreakTool,
            config: {
              time: 500,
            },
          },
          break10: {
            class: BreakTool,
            config: {
              time: 1000,
            },
          },
          break20: {
            class: BreakTool,
            config: {
              time: 2000,
            },
          },
        },
        i18n: {
          messages: {
            ui: {
              blockTunes: {
                toggler: {
                  'Click to tune': this.$t('editor.clickToTune'),
                  'or drag to move': this.$t('editor.orDragToMove'),
                },
              },
              inlineToolbar: {
                alias: {
                  Alias: '',
                  'Enter alias': this.$t('editor.enterAlias'),
                },
                converter: {
                  'Convert to': this.$t('editor.convertTo'),
                },
              },
              toolbar: {
                toolbox: {
                  Add: this.$t('editor.add'),
                },
              },
            },

            tools: {
              alias: {
                'Say as': this.$t('editor.alias'),
                'Enter alias': this.$t('editor.enterAlias'),
              },
            },
            toolNames: {
              Text: this.$t('editor.text'),
              Heading: this.$t('editor.heading'),
              List: this.$t('editor.list'),
              Quote: this.$t('editor.quote'),
              Delimiter: this.$t('editor.delimiter'),
              AliasInlineTool: this.$t('editor.alias'),
              Alias: this.$t('editor.alias'),
            },
            blockTunes: {
              delete: {
                Delete: this.$t('editor.delete'),
              },
              moveUp: {
                'Move up': this.$t('editor.moveUp'),
              },
              moveDown: {
                'Move down': this.$t('editor.moveDown'),
              },
            },
          },
        },
        data: content,
        // eslint-disable-next-line
        onChange: (data) => {
          console.log('editor change', data, this.blocks);
          // this.updatePlayButtons();
          this.blocks.blocks.forEach((block, index) => {
            const blockEl = document.querySelectorAll(`#${this.selector} .ce-block`)[index];
            // blockEl.id = block.id;
            if (!blockEl) return;
            if (block.language) blockEl.dataset.language = block.language;
            // blockEl.dataset.id = block.id;
          });
          this.onChange(data);
        },
        onReady: () => {
          const undo = new Undo({
            editor: this.editor,
            onUpdate: () => {
              setTimeout(() => {
                this.updatePlayButtons();
                const refresh = _.debounce(this.getCost, 1150);
                refresh();
                console.log('sadasdasdasddd =====> ');
              }, 500);
            },
          });
          undo.initialize(content);
          // console.log('%c editor ready', 'color: lime; font-size: 24px');
          this.onReady(content);
        },
      });
      this.$forceUpdate();
    },
    onReady(content) {
      content.blocks.forEach((block, index) => {
        const blockEl = document.querySelectorAll('.article-editor .ce-block')[index];
        // blockEl.id = block.id;
        if (!blockEl) return;
        if (block.language) blockEl.dataset.language = block.language;
        if (block.type === 'list') {
          const listItems = blockEl.querySelectorAll('.article-editor .cdx-list__item');
          listItems.forEach((el, i) => {
            const element = el;
            // if (block.data.ids[i]) element.id = block.data.ids[i];
            if (block.data.langs[i]) element.dataset.language = block.data.langs[i];
          });
        }
        blockEl.dataset.id = block.id;
      });
      this.hasFBSeparator = !!document.querySelectorAll('#editorjs .ce-delimiter')[0];
      this.$emit('update:hasSeparator', this.hasFBSeparator);
      this.updatePlayButtons();
    },
    addButton(block) {
      const playBtn = document.createElement('div');
      playBtn.classList = 'play-btn cursor-pointer absolute';
      playBtn.title = 'Play paragraph';
      const id = Math.random().toString(36).substr(2, 9);
      playBtn.id = `pb-${id}`;
      // TODO: use template?
      playBtn.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" class="editor-play text-gray-400 hover:text-hm-brand h-7 w-7 -ml-1" viewBox="0 0 20 20" fill="currentColor">
          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" class="editor-stop text-red-500 hover:text-hm-brand h-7 w-7 -ml-1" viewBox="0 0 20 20">
          <path id="Subtraction_32" data-name="Subtraction 32" d="M8,16a8,8,0,1,1,8-8A8.009,8.009,0,0,1,8,16ZM6,5A1,1,0,0,0,5,6v4a1,1,0,0,0,1,1h4a1,1,0,0,0,1-1V6a1,1,0,0,0-1-1Z"/>
        </svg>
        `;

      // playBtn.dataset.id = id;
      // playBtn.id = Math.random().toString(36).substr(2, 9);
      // playBtn.blockId = id;
      // playBtn.dataset.dupa = new Date();
      playBtn.addEventListener('click', this.playParagraph);

      block.appendChild(playBtn);
    },

    getCost() {

    },

    updatePlayButtons() {
      // // console.log('%cupdatePlayButtons', 'color: lime');
      // console.groupCollapsed('updatePlayButtons START');
      const blocks = document.querySelectorAll('.article-editor .ce-block');
      blocks.forEach((el) => {
        const element = el;
        const listElements = element.querySelectorAll('.cdx-list__item');
        const isList = listElements.length > 0;
        if (isList) {
          listElements.forEach((listBlock) => {
            const block = listBlock;
            const playBtn = listBlock.querySelector('.play-btn');
            if (block.innerHTML === '') block.innerHTML = '&nbsp;';
            // // console.log('%cAdd list ', 'color:lime;font-weight:bold', block.innerHTML);
            if (playBtn) return;
            this.addButton(listBlock);
          });
          return;
        }
        const hasPlyBtn = el.querySelector('.play-btn');
        // // console.log('%cupdatePlayButtons -> List', 'color: lime', hasPlyBtn, el);
        if (hasPlyBtn) return;

        this.addButton(element);
      });
      // console.groupEnd();
    },
    async playParagraph(event) {
      const parent = event.currentTarget.parentElement;
      const btnId = event.currentTarget.id;
      const currentlyPlayingBtn = document.querySelector(`#${this.selector} .play-btn.playing`);
      this.isPlaying = currentlyPlayingBtn ? currentlyPlayingBtn.id : false;

      console.log('%cisPlaying:', 'font-wight: bold; color: lime', this.isPlaying, currentlyPlayingBtn);

      const allBlocks = document.querySelectorAll(`#${this.selector} .ce-block`);
      const paragraphs = [];

      let startingIndex = 0;

      allBlocks.forEach((block, index) => {
        const btn = block.querySelector('.play-btn');
        const { id } = btn;

        if (id === btnId) startingIndex = index;
      });

      allBlocks.forEach((block, index) => {
        const btn = block.querySelector('.play-btn');
        const { id } = btn;
        // const btnClass = btn.classList;
        const contentWrapper = block.querySelector('.content-wrapper');
        const contentHtml = contentWrapper.innerHTML;
        let contentType = 'paragraph';
        const ssml = this.htmlToSsml(contentHtml);
        // const isClickedAgain = id === this.isPlaying;
        // const isTheSamePlaying = isClickedAgain && btnClass.contains('playing');
        // const shouldStopOnly = id === this.isPlaying;
        const contentSSML = ssml
          .replace(/<\/?span[^>]*>/g, '')
          .replaceAll('<div>', ' ')
          .replaceAll('</div>', ' ');

        if (contentWrapper.classList.contains('quote')) contentType = 'quote';
        if (contentWrapper.classList.contains('listitem')) contentType = 'list-item';
        if (contentWrapper.classList.contains('header')) contentType = 'header';

        console.log(index, 'PLAY PARAGRAPH', contentSSML);
        if (startingIndex <= index) {
          paragraphs.push({
            index,
            contentSSML,
            button: btn,
            id,
            type: contentType,
            hasPrevParagraph: true,
          });
        }
      });
      const btnClass = event.currentTarget.classList;
      const contentWrapper = parent.querySelector('.content-wrapper');
      const contentHtml = contentWrapper.innerHTML;
      let contentType = 'paragraph';
      const ssml = this.htmlToSsml(contentHtml);
      const shouldStopOnly = btnId === this.isPlaying;
      const isTheSamePlaying = shouldStopOnly && btnClass.contains('playing');

      if (contentWrapper.classList.contains('quote')) contentType = 'quote';
      if (contentWrapper.classList.contains('listitem')) contentType = 'list-item';
      if (contentWrapper.classList.contains('header')) contentType = 'header';

      console.group('PLAY PARAGRAPH', paragraphs, contentWrapper, parent, shouldStopOnly, isTheSamePlaying, btnClass, this.isPlaying, btnId, contentHtml, ' => ', ssml);
      const currentlyPlaying = document.querySelector('.article-editor .play-btn.playing');
      if (currentlyPlaying) {
        currentlyPlaying.classList.remove('playing');
      }
      // // console.log('%cplay paragraph: ', 'color:lime;font-weight:bold', event.currentTarget.blockId, `pb-${this.isPlaying}`, this.isPlaying, currentlyPlaying);
      if (currentlyPlaying) {
        // console.log('%cstop paragraph: ', 'color:orange;font-weight:bold', btnId);
        this.$emit('stopParagraph', { content: null, button: currentlyPlaying, id: btnId });
        this.isPlaying = false;
        event.currentTarget.classList.remove('playing');
      }
      if (shouldStopOnly) return;
      event.currentTarget.classList.add('loading');
      // const outputData = await this.editor.save();
      // const content = this.getOutput(outputData);
      // console.log('%cplay paragraph: ', 'color:orange;font-weight:bold', btnId);

      this.isPlaying = btnId;
      const contentSSML = ssml.replace(/<\/?span[^>]*>/g, '')
        .replaceAll('<div>', ' ')
        .replaceAll('</div>', ' ');
      // // console.log('%cPLAY PARAGRAPH DATA', 'color:red; font-weight: bold', outputData, content, this.isPlaying);
      this.$forceUpdate();
      console.groupEnd();
      this.$emit('playParagraph', {
        startingIndex,
        first: {
          contentSSML,
          button: event.currentTarget,
          id: btnId,
          type: contentType,
          hasPrevParagraph: true,
        },
        playlist: paragraphs,
      });
    },

    delay(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async onChange() {
      // this.delay(1000);
      this.updatePlayButtons();
      this.updateContent();
    },
    clearPlay() {
      const currentPlaying = document.querySelector('.ce-block .play-btn.playing');
      const currentLoading = document.querySelector('.ce-block .play-btn.loading');
      this.currentlyPlaying = null;
      this.isPlaying = false;
      if (currentPlaying) currentPlaying.classList.remove('playing');
      if (currentLoading) currentLoading.classList.remove('playing');
    },
    updateContent() {
      // this.updatePlayButtons();
      this.editor.save().then((outputData) => {
        console.log('%cCONTENTUPDATE => ', 'color: lime', outputData, this.editor.blocks);
        if (outputData.blocks.length === 0) {
          // const blockToAdd = {
          //   data: {
          //     text: this.$t('ui.defaultParagraphContent'),
          //   },
          //   type: 'paragraph',
          // };
          // this.refreshContent([]);
          // this.editor.blocks.insert([blockToAdd]);
          // this.editor.clear();
          // this.editor.render(this.setContent(blocks));
        }
        const contentBlocks = this.getOutput(outputData);

        // console.log('%cCONTENTUPDATE => ', 'color: lime', outputData, contentBlocks);
        this.$emit('update:content', contentBlocks);
        this.$emit('change', contentBlocks);
      });
    },
  },
};
</script>
<style>
.article-editor .codex-editor__redactor {
  padding-bottom: 100px !important;
}
.article-editor .cdx-quote__text {
  border: 0;
  border-left: 3px solid #000 !important;
  border-radius: 0;
  padding-top: 0;
  box-shadow: none;
}
.article-editor .cdx-simple-image__caption {
  border: 1px solid #efefef !important;
  border-radius: 4px;
}
.article-editor .cdx-quote__caption {
  border-left: 3px solid #000 !important;
  font-style: italic;
  border-radius: 0;
}
.article-editor .cdx-input {
  box-shadow: none;
  border: 0;
}
.article-editor .cdx-list__item {
  position: relative;
}
.article-editor .cdx-list__item .play-btn {
  left: -131px !important;
  top: 5px !important;
}
.article-editor .ce-block {
  position: relative;
}
.article-editor .ce-block .ce-header {
  padding-top: 0px;
  margin-top: 1em;
}
.article-editor .ce-block .play-btn {
  top: 2px;
  left: -12px;
  border-radius: 14px;
  position: absolute !important;
}
.article-editor .ce-block .play-btn.loading:before {
  left: -12px;
}
.article-editor .ce-block .play-btn.loading:after {
  margin-left: -12px;
}
.article-editor .ce-block .play-btn .editor-play {
  display: block;
}
.article-editor .ce-block .play-btn .editor-stop {
  display: none;
  left: 3px;
  top: 3px;
  position: relative;
}
.article-editor .ce-block .play-btn.playing .editor-play {
  display: none;
}
.article-editor .ce-block .play-btn.playing .editor-stop {
  display: block;
}

@media screen and (max-width: 768px) {
  .article-editor .ce-block__content {
    padding-left: 22px;
  }
  .article-editor .cdx-list__item {
    position: relative;
  }
  .article-editor .cdx-list__item .play-btn {
    left: -74px !important;
  }
}
</style>
