<template>
  <div class="h-screen flex overflow-hidden bg-white">
    <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
    <transition :duration="300">
    <div class="fixed inset-0 flex z-40 lg:hidden"
      role="dialog" aria-modal="true"
      v-show="showMobileNav">
      <transition
        enter-active-class="transition-opacity ease-linear duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity ease-linear duration-300"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0">
        <div
          v-show="showMobileNav"
          class="fixed inset-0 bg-gray-600 bg-opacity-75" aria-hidden="true" @click="showMobileNav = false"></div>
      </transition>
      <transition
        enter-active-class="transition ease-in-out duration-300 transform"
        enter-class="-translate-x-full"
        enter-to-class="translate-x-0"
        leave-active-class="transition ease-in-out duration-300 transform"
        leave-from-class="translate-x-0"
        leave-to-class="-translate-x-full">
        <div
          v-show="showMobileNav"
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-from-class="-translate-x-full"
          enter-to-class="translate-x-0"
          leave-active-class="transition ease-in-out duration-300 transform"
          leave-from-class="translate-x-0"
          leave-to-class="-translate-x-full"
          class="relative flex-1 flex flex-col max-w-xs w-full bg-hm-dark">
          <!--
            Close button, show/hide based on off-canvas menu state.

            Entering: "ease-in-out duration-300"
              From: "opacity-0"
              To: "opacity-100"
            Leaving: "ease-in-out duration-300"
              From: "opacity-100"
              To: "opacity-0"
          -->
          <div class="absolute top-0 right-0 -mr-12 pt-2">
            <button class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="showMobileNav = false">
              <span class="sr-only">Close sidebar</span>
              <!-- Heroicon name: outline/x -->
              <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

          <Nav :websites="websiteList"></Nav>
        </div>
      </transition>
      <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
      </div>
    </div>
    </transition>
    <!-- Static sidebar for desktop -->
    <div class="hidden lg:flex lg:flex-shrink-0">
      <div class="flex flex-col w-64">
        <Nav :websites="websiteList"></Nav>
      </div>
    </div>
    <div class="fixed bottom-4 left-4 text-white opacity-60 z-50 text-sm"
      v-tippy="{ content: versionDeployTime}">
      <span class="pointer-events-none">
        {{version.version}}
      </span>
    </div>

    <div class="flex-1 overflow-auto overflow-y-scroll focus:outline-none" tabindex="0">
      <!-- Top bar -->
      <div class="relative z-10 flex-shrink-0 flex h-16" :class="{'bg-hm-brand': isSuperAdmin && !isInUseAsMode, 'bg-hm-gray-light': !isSuperAdmin && !isInUseAsMode, 'bg-red-500':  isSuperAdmin && isInUseAsMode}">
        <button class="px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden" @click="showMobileNav = true">
          <span class="sr-only">Open sidebar</span>
          <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h8m-8 6h16" />
          </svg>
        </button>
        <div class="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-7xl lg:mx-auto lg:px-8">
          <div class="flex-1 flex">
            <router-link
              v-show="isInUseAsMode && isSuperAdmin"
              :to="`/sys-admin/${selectedWebsite.customerId}/customer/details`"
              class="h-9 mt-3 inline-flex items-center px-5 py-0.5 rounded sfont-medium bg-red-800 text-red-100">EXIT : <span class="font-bold">{{selectedWebsite.displayName}}</span></router-link>
          </div>
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Credits -->
            <button type="button" class="mr-3 relative inline-flex items-center px-2 py-1 border border-transparent rounded-md text-gray-500  hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-hm-gray-lighter font-bold focus:ring-hm-brand"
            v-show="!isSuperAdmin || isInUseAsMode"
            @click="goTo('/credits')" v-tippy="{ content: $t('ui.charactersTooltip', [formattedInt(baseCredits), formattedInt(additionalCredits)]) }">
              <!-- Heroicon name: solid/plus -->
              <!-- <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-yellow-400 stroke-current" width="24" height="24" viewBox="0 0 24 24"><title>abc</title><g stroke-linecap="square" stroke-linejoin="miter" stroke-width="2" fill="none" stroke="currentColor" stroke-miterlimit="10"><path d="M15,10h-3.5 C10.119,10,9,8.881,9,7.5v0C9,6.119,10.119,5,11.5,5H15V10z" stroke="currentColor"></path> <path d="M11,2h2 c1.105,0,2,0.895,2,2v6" stroke="currentColor"></path> <line x1="3" y1="11" x2="3" y2="22"></line> <path d="M6,22H3v-6h3 c1.657,0,3,1.343,3,3v0C9,20.657,7.657,22,6,22z"></path> <path d="M21.732,15.999 C21.386,15.402,20.74,15,20,15h-2c-1.105,0-2,0.895-2,2v3c0,1.105,0.895,2,2,2h2c0.74,0,1.387-0.402,1.732-1"></path></g></svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" class="flex-shrink-0 h-5 w-5 text-yellow-400 fill-current" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M7 2a1 1 0 011 1v1h3a1 1 0 110 2H9.578a18.87 18.87 0 01-1.724 4.78c.29.354.596.696.914 1.026a1 1 0 11-1.44 1.389c-.188-.196-.373-.396-.554-.6a19.098 19.098 0 01-3.107 3.567 1 1 0 01-1.334-1.49 17.087 17.087 0 003.13-3.733 18.992 18.992 0 01-1.487-2.494 1 1 0 111.79-.89c.234.47.489.928.764 1.372.417-.934.752-1.913.997-2.927H3a1 1 0 110-2h3V3a1 1 0 011-1zm6 6a1 1 0 01.894.553l2.991 5.982a.869.869 0 01.02.037l.99 1.98a1 1 0 11-1.79.895L15.383 16h-4.764l-.724 1.447a1 1 0 11-1.788-.894l.99-1.98.019-.038 2.99-5.982A1 1 0 0113 8zm-1.382 6h2.764L13 11.236 11.618 14z" clip-rule="evenodd" />
              </svg>
              <!-- <svg class="flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400 fill-current" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><title>coins</title><g ><path  d="M17,16c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,15.361,19.951,16,17,16z"></path> <path  d="M17,21c-2.951,0-5.403-0.639-7-1.712c0,0.746,0,1.238,0,1.712c0,1.657,3.134,3,7,3s7-1.343,7-3 c0-0.474,0-0.966,0-1.712C22.403,20.361,19.951,21,17,21z"></path> <ellipse  cx="17" cy="11" rx="7" ry="3"></ellipse> <ellipse cx="7" cy="3" rx="7" ry="3"></ellipse> <path d="M8,17.973C7.673,17.989,7.341,18,7,18c-2.951,0-5.403-0.639-7-1.712C0,17.034,0,17.526,0,18 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V17.973z"></path> <path d="M8,12.973C7.673,12.989,7.341,13,7,13c-2.951,0-5.403-0.639-7-1.712C0,12.034,0,12.526,0,13 c0,1.657,3.134,3,7,3c0.34,0,0.673-0.014,1-0.034V12.973z"></path> <path d="M9.92,7.766C9.018,7.916,8.042,8,7,8C4.049,8,1.597,7.361,0,6.288C0,7.034,0,7.526,0,8 c0,1.657,3.134,3,7,3c0.341,0,0.674-0.014,1.003-0.034C8.015,9.703,8.71,8.606,9.92,7.766z"></path></g></svg> -->
              <span class="ml-2">{{credits | intFormat}}</span>
            </button>
            <!-- Notifications -->
            <Notifications :items="notifiactionList"></Notifications>
            <TopNavUserDropdown></TopNavUserDropdown>
          </div>
        </div>
      </div>
      <main class="flex-1 relative overflow-y-auto">
        <transition
          name="fade"
          mode="out-in"
          @beforeLeave="beforeLeave"
          @enter="enter"
          @afterEnter="afterEnter"
        >
          <router-view/>
        </transition>
      </main>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { Auth } from 'aws-amplify';

export default {
  data() {
    return {
      user: null,
      searchString: '',
      showDropdown: false,
      showMobileNav: false,
      intervalRefresh: null,
      checkInterval: null,
      showWebsiteDropdown: false,
      libraryId: null,
      notifications: [
        {
          time: new Date(),
          title: 'Article added',
          message: 'Lorem ipsum sit dolor',
          type: 'info',
        },
      ],
      version: {},
    };
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.auth.isLoggedIn,
      userEmail: (state) => state.auth.userEmail,
      customer: (state) => state.customer.userCustomer,
      customerId: (state) => state.auth.customer,
      userCustomer: (state) => state.customer.userCustomer,
      baseCredits: (state) => state.admin.credits,
      additionalCredits: (state) => state.admin.additionalCredits,
      userId: (state) => state.auth.userId,
      userProfile: (state) => state.auth.userProfile,
      isSuperAdmin: (state) => state.auth.isSuperAdmin,
      notifiactionList: (state) => state.utils.notifiactionList,
      languageList: (state) => state.utils.languageList,

      isInUseAsMode: (state) => state.ui.isInUseAsMode,

      // websites
      websiteList: (state) => state.website.websiteList,
      selectedWebsite: (state) => state.website.selectedWebsite,
    }),
    credits() {
      const baseCredits = this.baseCredits || 0;
      const additionalCredits = this.additionalCredits || 0;
      return parseInt(baseCredits, 10) + parseInt(additionalCredits, 10);
    },
    formattedInt() {
      return (str) => {
        if (!str) return 0;
        return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
      };
    },
    queryParams() {
      const input = {
        first: 125,
        orderBy: 'DISPLAY_NAME_ASC',
        offset: 0,
        condition: {
          isDeleted: false,
        },
      };
      return input;
    },
    versionDeployTime() {
      if (this.version && this.version.deployedAt) return this.version.deployedAt.replace('T', ' ').replace('Z', '');
      return '---';
    },
    avatar() {
      return `https://avatars.dicebear.com/api/initials/${this.userEmail}.svg`;
    },
  },
  watch: {
    customer() {
      // // console.log('customer watch --------------');
      // this.init();
    },
  },
  async created() {
    this.fetchPrices();
    // console.log('created MAIN');
    await this.checkIfLogged();
    await this.init();
    this.fetchNotifications();
    this.fetchLanguages();
    this.getPluginLink();
    const versionResult = await this.getVersion();
    if (versionResult.version) this.version = versionResult;
    console.log('userEmail', versionResult, this.userEmail);
  },
  async mounted() {
    this.clearState();
    // // console.log('0 clear state billing');
    this.checkInterval = window.setInterval(this.check, 120000);
    this.intervalRefresh = window.setInterval(this.refreshToken, 10000);
  },
  beforeDestroy() {
    clearInterval(this.checkInterval);
    clearInterval(this.intervalRefresh);
  },
  methods: {
    ...mapActions({
      fetchNotifications: 'utils/fetchNotifications',
      fetchLanguages: 'utils/fetchLanguages',
      fetchWebsites: 'website/fetchWebsites',
      fetchUser: 'auth/fetchUser',
      fetchCustomer: 'customer/fetchCustomer',
      fetchAuthCustomer: 'auth/fetchCustomer',
      fetchCredits: 'customer/fetchCredits',
      getWebsiteById: 'website/getWebsiteById',
      getPluginLink: 'settings/getPluginLink',
      getVersion: 'utils/getVersion',
      fetchPrices: 'admin/fetchPrices',
    }),
    ...mapMutations({
      setSession: 'auth/setSession',
      setIsLoggedIn: 'auth/setIsLoggedIn',
      clearState: 'billing/clearBillingState',
      setUseAsMode: 'ui/setUseAsMode',
    }),
    check() {
      this.fetchNotifications();
      // // console.log('customer', this.customer);
      if (!this.isSuperAdmin) this.fetchCustomer(this.customer.id);
    },
    toggleMainMenu() {
      // console.log('toggleMainMenu');
      this.showMobileNav = !this.showMobileNav;
    },
    closeUserMenu() {
      this.showDropdown = false;
    },
    closeWebsiteDropdown() {
      this.showWebsiteDropdown = false;
    },
    async initAdmin() {
      // check if is in useAsMode
      const useAsModeData = JSON.parse(localStorage.getItem('useAs'));
      // console.log('%cUSE AS CHECK', 'color: lime; font-size: 16px;', useAsModeData);
      const websiteId = useAsModeData.id;

      if (!websiteId) return;

      const result = await this.getWebsiteById(websiteId);
      // console.log('website result ', result);
      this.fetchCustomer(result.customerId);
      this.setUseAsMode({ id: websiteId, name: result.displayName });
    },
    async init() {
      const websites = await this.fetchWebsites();
      if (this.isSuperAdmin) {
        this.initAdmin();
        // console.log('%c SYSsmin init', 'color:lime; font-size: 48px');
        return;
      }
      if (Array.isArray(websites) && websites.length === 0 && !this.isSuperAdmin) {
        // // console.log('HAS NO WEBNSITES', result);
        localStorage.setItem('firstTime', 'firstTime');
        this.goTo('/add-blog');
        // this.goTo('/add-blog');
        return;
      }

      this.$forceUpdate();
      const savedWebsite = localStorage.getItem(`w_${this.userId}`);
      // If user has Saved website
      if (savedWebsite) {
        await this.getWebsiteById(savedWebsite);
        if (this.$route.params.websiteId === 'none') {
          this.$router.push({ path: `/articles/${savedWebsite}/list` });
          // console.log('%c savedWebsite main.vue none', 'color: lime', this.$route.params.websiteId, website);
        }
        return;
      }
      await this.getWebsiteById(websites[0].id);
    },
    async checkIfLogged() {
      // // console.log('checkIfLogged', this.isLoggedIn);
      if (this.isLoggedIn) return;
      const result = await Auth.currentSession();
      // // console.log('USER SESSION ++++ ', result);
      this.setSession(result);
      const userResult = await this.fetchUser(this.userId);
      await this.fetchAuthCustomer(userResult.customerId);
      await this.fetchCustomer(userResult.customerId);
      // console.log('user', userResult);
      // eslint-disable-next-line
      $crisp.push(['set', 'user:email', this.userEmail]);
    },

    async refreshToken() {
      // // console.log('xxxxxx');
      const result = await Auth.currentSession();
      // // console.log('refreshToken', result);
      const hasAccessToken = !!result.accessToken;
      if (hasAccessToken) localStorage.setItem('token', result.accessToken.jwtToken);
    },
    goTo(path) {
      this.$router.push({ path });
    },
    beforeLeave(element) {
      this.prevHeight = getComputedStyle(element).height;
    },
    enter(el) {
      const element = el;
      const { height } = getComputedStyle(element);

      element.style.height = this.prevHeight;

      setTimeout(() => {
        element.style.height = height;
      });
    },
    afterEnter(el) {
      const element = el;
      element.style.height = 'auto';
    },
  },
};
</script>
