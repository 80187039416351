<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.pronunciation')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.pronunciation')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <Button size="sm2" @click.native="showAddForm" :disabled="showForm" v-if="activeTab !== 'recommended'">{{$t('ui.addAlias')}}</Button>
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-4">
      <div class="text-sm font-medium text-center text-black border-b border-gray-200">
        <ul class="flex flex-wrap -mb-px">
            <li class="mr-2">
                <a @click="activeTab = 'general'" :class="{'border-b-2 border-black font-bold active': isActive('general')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500">{{$t('ui.general')}}</a>
            </li>
            <li class="mr-2">
                <a @click="activeTab = 'recommended'" :class="{'border-b-2 border-black font-bold active': isActive('recommended')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500" aria-current="page">{{$t('ui.recommended')}}</a>
            </li>
        </ul>
      </div>
    </div>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">

     <div class="" v-if="activeTab === 'general'">
       <!-- SEARCH -->
       <!-- <div>
        <div class=" pb-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <input id="search" v-model="searchQuery" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" :placeholder="$t('ui.search')" type="text">
          </div>
        </div>
      </div> -->
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.utterance')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.pronouncedAs')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Google/AWS/Techmo
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.language')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.voice')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <!-- {{$t('ui.balance')}} -->
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="new-entry" v-if="showForm">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="inputModel.originalText">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap">
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="inputModel.alias">
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap  text-sm">
                      <input id="google" name="platforms" value="google" v-model="inputModel.platforms" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" /> /
                      <input id="aws" name="platforms" value="aws" v-model="inputModel.platforms" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" /> /
                      <input id="trchmo" name="platforms" value="techmo" v-model="inputModel.platforms" type="checkbox" class="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded" />
                    </td> -->
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <select
                        v-model="inputModel.language"
                        id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                        <option v-for="lang in languageList" :key="lang" :value="lang">{{$t('languages.' + lang)}}</option>
                      </select>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <multiselect v-model="inputModel.voices" :options="voicesByLanguage" :multiple="true"
                        track-by="displayName"
                        label="displayName"
                        :close-on-select="false"
                        :limit="1"
                        :show-labels="false"
                        :option-height="24"
                        :hide-selected="true">
                        <template slot="option" slot-scope="props">
                            <div class="option__desc"><span class="option__title">{{ props.option.displayName }}</span></div>
                          </template>
                      </multiselect>
                    </td>
                    <td class="text-right space-x-1">
                      <Button size="sm2" class="inline-block"
                        @click.native="addAlias"
                        :disabled="!inputModel.originalText"
                        :loading="loading">{{$t('ui.save')}}</Button>
                      <Button type="default" size="sm2" class="inline-block"
                        @click.native="cancelAddForm">{{$t('ui.cancel')}}</Button>
                    </td>
                  </tr>
                  <tr
                    v-for="item in items" :key="item.nodeId">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.originalText}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.alias}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span v-if="currentlyEdited !== item.nodeId">{{languageName(item.language).name}}</span>
                      <select
                        v-model="item.language"
                        v-if="currentlyEdited === item.nodeId"
                        id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{lang.name}}</option>
                      </select>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span v-if="currentlyEdited !== item.nodeId">
                        <t-dropdown :text="`${item.voices.length} voices ▾`">
                          <div class="py-1 rounded-md shadow-xs max-h-40 overflow-y-auto">
                            <div v-for="voice in item.voices" :key="voice.ttsVoiceName" class="flex items-center">
                              <span
                                class="block px-2 py-1 text-sm leading-2 text-gray-700 transition duration-150 ease-in-out"
                                :class="{loading: loadingPlay === item.nodeId}">
                                <svg
                                  @click="play(item, voiceItemById(voice))"
                                  xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                                </svg>
                              </span>
                              {{voiceItemById(voice).displayName}}
                            </div>
                          </div>
                        </t-dropdown>
                      </span>
                    </td>
                    <td class="text-right space-x-1">
                      <Button size="sm2" class="inline-block"
                        @click.native="update(item)"
                        v-if="currentlyEdited === item.nodeId"
                      :class="{disabled: !item.originalText}">{{$t('ui.save')}}</Button>
                      <Button type="default" size="sm2" class="inline-block"
                        v-if="currentlyEdited === item.nodeId"
                        @click.native="cancelEdit(item)">{{$t('ui.cancel')}}</Button>
                      <Button type="danger" size="sm2" class="inline-block"
                        v-if="currentlyEdited !== item.nodeId"
                        @click.native="deleteAlias(item.nodeId)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="max-w-7xl mx-auto pt-10 divide-y divide-gray-300">
        <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
      </div>
     </div>

     <div v-if="activeTab === 'recommended'" class="" :class="{loading: loading}">
       <!-- SEARCH -->
       <!-- <div>
        <div class=" pb-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <input id="search" v-model="searchQueryRecommended" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" :placeholder="$t('ui.search')" type="text">
          </div>
        </div>
      </div> -->
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.utterance')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.pronouncedAs')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.language')}}
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.voice')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <!-- {{$t('ui.balance')}} -->
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="item in recommendations" :key="item.alias + item.original_text">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span>{{item.originalText}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span>{{item.alias}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap justify-between text-sm">
                      <div v-for="voice in item.voices" :key="voice.ttsVoiceName" class="flex items-center">
                        <span
                          :class="{loading: loadingPlay === item.nodeId}">
                          <svg
                            @click="play(item, voiceItemById(voice))"
                            xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                          </svg>
                        </span>
                        {{voiceItemById(voice).displayName}}
                      </div>
                    </td>
                    <td class="text-right space-x-1">
                      <div class="px-4 py-2 text-xs border border-transparent font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block cursor-pointer"
                        @click="addRecomendation(item)">{{$t('sysadmin.add')}}</div>
                    </td>
                  </tr>
                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
      <audio ref="player" controls="controls" id="audioPreview" @ended="clearPlayer" v-show="false">
        <source :src="audioPreviewUrl" type="audio/mp3" />
      </audio>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import moment from 'moment';
import Multiselect from 'vue-multiselect';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  components: { Multiselect },
  data() {
    return {
      moment,
      // items: [
      //   {
      //     date: new Date(),
      //     operation: 'Credits added',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     operation: 'Article generated',
      //     amount: -12,
      //     balance: 88,
      //   },
      // ],
      copy: {},
      searchQuery: '',
      searchQueryRecommended: '',
      activeTab: 'general',
      audioPreviewUrl: null,
      audioUrls: null,
      loading: false,
      loadingPlay: false,
      currentlyEdited: null,
      showForm: false,
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      selectedOrder: 'CREATED_AT',
      orderDirString: 'DESC',
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      inputModel: {
        alias: '',
        originalText: null,
        // platforms: [],
        language: 'en-US',
        voices: [],
      },
      languages: [
        {
          id: 0,
          name: 'All',
          code: 'all',
        },
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      customerList: (state) => state.admin.customerList,
      selectedWebsite: (state) => state.website.selectedWebsite,
      totalCount: (state) => state.admin.count,
      hasNextPage: (state) => state.admin.hasNextPage,
      items: (state) => state.admin.listGlobalAliases,
      recommendations: (state) => state.admin.listGlobalAliasRecommendations,
      customer: (state) => state.auth.customer,
      userProfile: (state) => state.auth.userProfile,
      languageList: (state) => state.utils.languageList,
      voices: (state) => state.utils.voiceList,
    }),
    voicesByLanguage() {
      return this.voices.filter((voice) => voice.mainLanguage === this.inputModel.language);
    },
    voiceId() {
      return (item) => {
        const kind = item.ttsVoiceKind ? `-${item.ttsVoiceKind}` : '';
        return `${item.ttsVoiceName}-${item.mainLanguage}${kind}`;
      };
    },
    voiceItemById() {
      return (voiceSettings) => {
        console.log('xxx');
        return this.voices.find((el) => {
          const isTheSame = this.voiceId(el) === this.voiceId(voiceSettings);
          // const newId = `${el.id}-${el.isHQ}`;
          // const isHQ = voiceSettings.ttsVoiceEngine === 'neural';
          // const voiceSettngsId = `${voiceSettings.ttsVoiceName}-${isHQ}`;
          return isTheSame;
        }) || {};
      };
    },
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    languageName() {
      return (code) => {
        const [lang] = this.languages.filter((el) => el.code === code);
        if (!lang) return { name: '' };
        return lang;
      };
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          // isDeleted: false,
        },
      };

      return input;
    },
    isNegative() {
      return (item) => item.creditsAfter - item.creditsBefore;
    },
  },
  created() {
    this.clearState();
  },
  watch: {
    // eslint-disable-next-line
    customer: function () {
      // this.clearState();
      this.loadData(this.page);
    },
  },
  mounted() {
    this.clearState();
    this.loadData(1);
    this.fetchGlobalAliasRecommendations();
    this.fetchVoice();
  },
  methods: {
    ...mapActions({
      fetchCustomer: 'customer/fetchCustomer',
      fetch: 'admin/fetchGlobalAliases',
      fetchVoice: 'utils/fetchVoices',
      createAliasGlobal: 'alias/createAliasGlobal',
      deleteAliasGlobal: 'alias/deleteAliasGlobal',
      playGlobal: 'admin/playGlobal',
      fetchGlobalAliasRecommendations: 'admin/fetchGlobalAliasRecommendations',
    }),
    ...mapMutations({
      clearState: 'billing/clearState',
    }),
    async deleteAlias(id) {
      await this.deleteAliasGlobal(id);
      // console.log('result', result);
      this.refresh();
      this.fetchGlobalAliasRecommendations();
    },
    async addRecomendation(alias) {
      this.loading = true;
      const input = {
        alias: alias.alias,
        originalText: alias.originalText,
        // platforms: [],
        language: alias.language,
        voices: [],
      };
      alias.voices.forEach((voice) => {
        // const voiceData = this.voiceItemById(voice);
        input.voices.push(voice);
      });
      console.log('input', input);
      const result = await this.createAliasGlobal(input);
      // console.log('result', result);
      this.refresh();
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.loading = false;
        return;
      }
      this.loading = false;
    },
    async addAlias() {
      this.loading = true;
      const input = this.inputModel;
      console.log('input', input);
      input.voices = input.voices
        .map((voice) => {
          const voiceItem = {
            ttsPlatform: voice.ttsPlatform,
            ttsVoiceName: voice.ttsVoiceName,
            mainLanguage: voice.mainLanguage,
          };
          if (voice.ttsVoiceKind) voiceItem.ttsVoiceKind = voice.ttsVoiceKind;
          return voiceItem;
        });
      const result = await this.createAliasGlobal(input);

      // console.log('result', result);
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        this.loading = false;
        return;
      }
      this.refresh();

      this.loading = false;
      this.cancelAddForm();
    },
    cancelAddForm() {
      this.inputModel = {
        alias: '',
        originalText: null,
        language: 'en-US',
      };
      this.showForm = false;
    },
    edit(item) {
      // console.log('edit', item);
      this.copy = JSON.parse(JSON.stringify(item));
      this.currentlyEdited = item.nodeId;
    },
    cancelEdit(item) {
      let el = item;
      el = this.copy;
      console.log(el);
      this.currentlyEdited = null;
    },
    goTo(path) {
      this.$router.push({ path });
    },
    showAddForm() {
      this.inputModel = {
        alias: '',
        originalText: null,
        language: 'en-US',
      };
      this.showForm = true;
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },

    async refresh() {
      this.loadData(this.page);
      this.fetchGlobalAliasRecommendations();
    },

    async loadData(page) {
      this.loading = true;
      this.offset = page * this.pageSize - this.pageSize;

      // console.log('page', page, this.queryParams);
      await this.fetch(this.queryParams);
      this.loading = false;
    },

    // player stuff
    async play(item, voice) {
      console.log('play', item, voice);
      this.loadingPlay = item.nodeId;
      const input = {
        alias: item.alias,
        originalText: item.originalText,
        voice: {
          ttsVoiceKind: voice.ttsVoiceKind,
          ttsPlatform: voice.ttsPlatform,
          mainLanguage: voice.mainLanguage,
          ttsVoiceName: voice.ttsVoiceName,
        },
      };
      const result = await this.playGlobal(input);

      this.loadingPlay = false;
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      this.playing = 0;
      this.audioUrls = result.url;
      [this.audioPreviewUrl] = result.url;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
    clearPlayer() {
      const audio = this.$refs.player;
      this.playing += 1;
      if (this.audioUrls.length > 1 && this.playing < this.audioUrls.length) {
        this.audioPreviewUrl = this.audioUrls[this.playing];
        audio.load(); // call this to just preload the audio without playing
        audio.play();
        return;
      }
      this.playing = 0;
      this.audioPreviewUrl = null;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__input {
  display: block;
  width: 200px;
  font-size: 13px;
  border-radius: 6px;
}

.multiselect__tags {
    min-height: 47px;
    display: block;
    padding: 11px 47px 0 8px;
    border-radius: 6px;
    border: 1px solid #d1d5db;
    background: #fff;
    font-size: 13px;
}
.multiselect__element {
    display: block;
    font-size: 14px;
    padding: 6px 10px;
}
.multiselect__option {
    display: block;
    padding: 4px 10px;
    min-height: 24px;
    line-height: 16px;
    text-decoration: none;
    text-transform: none;
    vertical-align: middle;
    position: relative;
    cursor: pointer;
    white-space: nowrap;
}
</style>
