<template>
  <div>
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <nav class="flex" aria-label="Breadcrumb">
            <ol class="flex items-center space-x-4" role="list">
              <li>
                <div>
                  <router-link tag="a" :to="`/articles/${selectedWebsite.id}/list`" class="text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.home')}}</router-link>
                </div>
              </li>
              <li>
                <div class="flex items-center">
                  <!-- Heroicon name: solid/chevron-right -->
                  <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                  </svg>
                  <a href="#" class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{{$t('ui.pronunciation')}}</a>
                </div>
              </li>
            </ol>
          </nav>
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
            {{$t('ui.pronunciation')}}
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <div class="px-4 py-2 text-xs border border-transparent font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block cursor-pointer" @click="showAddForm" :class="{disabled: showForm}">{{$t('ui.addAlias')}}</div>
        </div>
      </div>
    </header>
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 mb-4">
      <div class="text-sm font-medium text-center text-black border-b border-gray-200">
        <ul class="flex flex-wrap -mb-px">
            <li class="mr-2">
                <a @click="activeTab = 'general'" :class="{'border-b-2 border-black font-bold active': isActive('general')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500">{{$t('ui.general')}}</a>
            </li>
            <li class="mr-2">
                <a @click="activeTab = 'recommended'" :class="{'border-b-2 border-black font-bold active': isActive('recommended')}" class="inline-block p-4 rounded-t-lg cursor-pointer border-b-2 border-transparent hover:text-hm-blue hover:border-blue-500" aria-current="page">{{$t('ui.recommended')}}</a>
            </li>
        </ul>
      </div>
    </div>
    <div v-show="activeTab === 'general'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56" :class="{loading: loading}">
      <!-- SEARCH -->
      <div>
        <div class=" pb-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <input id="search" v-model="searchQuery" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" :placeholder="$t('ui.search')" type="text">
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.utterance')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.pronouncedAs')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider max-w-[100px]">
                      {{$t('ui.voice')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.language')}}
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <!-- {{$t('ui.balance')}} -->
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr class="new-entry" v-if="showForm">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="inputModel.originalText">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap">
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="inputModel.alias">
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <select
                        v-model="inputModel.language"
                        id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{lang.name}}</option>
                      </select>
                    </td> -->
                    <td class="text-right space-x-1">
                      <Button
                        size="sm"
                        class="px-4 py-2"
                        @click.native="addAlias"
                        :disabled="!inputModel.originalText">{{$t('ui.save')}}</Button>
                      <Button
                        size="sm"
                        type="default"
                        class="px-4 py-2"
                        @click.native="cancelAddForm">{{$t('ui.cancel')}}</Button>
                    </td>
                  </tr>
                  <tr
                    v-for="item in items" :key="item.nodeId">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.originalText}}</span>
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="item.originalText" v-if="currentlyEdited === item.nodeId">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap flex">
                      <span
                        class="flex-end mr-2"
                        :class="{loading: loadingPlay === item.nodeId}">
                        <svg
                          @click="play(item)"
                          xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                        </svg>
                      </span>
                      <span v-if="currentlyEdited !== item.nodeId">{{item.alias}}</span>
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="item.alias" v-if="currentlyEdited === item.nodeId">
                    </td>
                    <td class="px-6 pl-0 py-4 max-w-[100px]">
                      <span v-if="currentlyEdited !== item.nodeId">{{voiceItemById(item.voice).displayName}}</span>
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span v-if="currentlyEdited !== item.nodeId">{{languageName(item.language).name}}</span>
                      <select
                        v-model="item.language"
                        v-if="currentlyEdited === item.nodeId"
                        id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{lang.name}}</option>
                      </select>
                    </td> -->
                    <td class="text-right space-x-1">
                      <div class="px-4 py-2 text-xs border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block inline-block"
                        @click="update(item)"
                        v-if="currentlyEdited === item.nodeId"
                      :class="{disabled: !item.originalText}">{{$t('ui.save')}}</div>
                      <div class="btn-default btn-sm2 inline-block"
                        v-if="currentlyEdited === item.nodeId"
                        @click="cancelEdit(item)">{{$t('ui.cancel')}}</div>
                      <!-- <div class="px-4 py-2 text-xs border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block rounded inline-block hover:bg-red-500"
                        @click="edit(item)"
                        v-if="currentlyEdited !== item.nodeId"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </div> -->
                      <Button type="danger" size="sm2" class=""
                        v-if="currentlyEdited !== item.nodeId"
                        @click.native="deleteAlias(item.nodeId)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGINA -->
      <div class="max-w-7xl mx-auto pt-10 divide-y divide-gray-300" v-if="items.length > 0">
        <pagination v-model="page" :records="totalCount" @paginate="loadData" :options="options"/>
      </div>
      <audio ref="player" controls="controls" id="audioPreview" v-show="false">
        <source :src="audioPreviewUrl" type="audio/mp3" />
      </audio>
      <pre v-if="userId === '3aa4b57b-2aff-4483-ac87-776ce6feb612'">
        {{contentVoice}}
        {{feedId}}
        {{selectedWebsite.language}}
        {{customer}}
        {{items.length > 0}}
      </pre>
    </div>
    <div v-show="activeTab === 'recommended'" class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pb-56" :class="{loading: loading}">
       <!-- SEARCH -->
       <div>
        <div class=" pb-2">
          <label for="search" class="sr-only">Search</label>
          <div class="relative">
            <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg class="h-5 w-5 text-black" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
              </svg>
            </div>
            <input id="search" v-model="searchQueryRecommended" name="search" class="h-11 mt-1 w-full py-3 pl-10 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300 border-2" :placeholder="$t('ui.search')" type="text">
          </div>
        </div>
      </div>
      <div class="flex flex-col">
        <div class=" overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="overflow-hidden g">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="">
                  <tr>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.utterance')}}
                    </th>
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.pronouncedAs')}}
                    </th>
                    <!-- <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      {{$t('ui.language')}}
                    </th> -->
                    <th scope="col" class="px-6 pl-0 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      <!-- {{$t('ui.balance')}} -->
                    </th>
                  </tr>
                </thead>
                <tbody class="bg-white divide-y divide-gray-200">
                  <tr
                    v-for="item in [{nodeId: 1, originalText: 'test', alias: 'je jejej'}]" :key="item.nodeId">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span>{{item.originalText}}</span>
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap flex justify-between">
                      <span>{{item.alias}}</span>

                      <span
                        class="flex-end"
                        :class="{loading: loadingPlay === item.nodeId}">
                        <svg
                          @click="play(item)"
                          xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span v-if="currentlyEdited !== item.nodeId">{{languageName(item.language).name}}</span>
                      <select
                        v-model="item.language"
                        v-if="currentlyEdited === item.nodeId"
                        id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{lang.name}}</option>
                      </select>
                    </td> -->
                    <td class="text-right space-x-1">
                      <div class="px-4 py-2 text-xs border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block inline-block"
                        @click="update(item)"
                        v-if="currentlyEdited === item.nodeId"
                      :class="{disabled: !item.originalText}">{{$t('ui.save')}}</div>
                      <div class="btn-default btn-sm2 inline-block"
                        v-if="currentlyEdited === item.nodeId"
                        @click="cancelEdit(item)">{{$t('ui.cancel')}}</div>
                      <!-- <div class="px-4 py-2 text-xs border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block rounded inline-block hover:bg-red-500"
                        @click="edit(item)"
                        v-if="currentlyEdited !== item.nodeId"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </div> -->
                      <Button type="danger" size="sm2" class=""
                        v-if="currentlyEdited !== item.nodeId"
                        @click.native="deleteAlias(item.nodeId)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    </td>
                  </tr>
                  <tr
                    v-for="item in items" :key="item.nodeId">
                    <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.originalText}}</span>
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="item.originalText" v-if="currentlyEdited === item.nodeId">
                    </td>
                    <td class="px-6 pl-0 py-4 whitespace-nowrap flex justify-between">
                      <span v-if="currentlyEdited !== item.nodeId">{{item.alias}}</span>
                      <input type="text" class="py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300" v-model="item.alias" v-if="currentlyEdited === item.nodeId">

                      <span
                        class="flex-end"
                        :class="{loading: loadingPlay === item.nodeId}">
                        <svg
                          @click="play(item)"
                          xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 hover:text-yellow-500 cursor-pointer" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </td>
                    <!-- <td class="px-6 pl-0 py-4 whitespace-nowrap text-sm text-gray-500">
                      <span v-if="currentlyEdited !== item.nodeId">{{languageName(item.language).name}}</span>
                      <select
                        v-model="item.language"
                        v-if="currentlyEdited === item.nodeId"
                        id="language" name="language" autocomplete="language" class="mt-1 block w-full py-3 focus:ring-current focus:border-blue-500 flex-grow block min-w-0 rounded-lg sm:text-sm border-gray-300">
                        <option v-for="lang in languages" :key="lang.code" :value="lang.code">{{lang.name}}</option>
                      </select>
                    </td> -->
                    <td class="text-right space-x-1">
                      <div class="px-4 py-2 text-xs border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block inline-block"
                        @click="update(item)"
                        v-if="currentlyEdited === item.nodeId"
                      :class="{disabled: !item.originalText}">{{$t('ui.save')}}</div>
                      <div class="btn-default btn-sm2 inline-block"
                        v-if="currentlyEdited === item.nodeId"
                        @click="cancelEdit(item)">{{$t('ui.cancel')}}</div>
                      <!-- <div class="px-4 py-2 text-xs border border-transparent text-sm font-medium rounded-lg shadow-sm text-white bg-black hover:bg-blue-700 focus:outline-none focus:ring-current focus:ring-currentcursor-pointer transition duration-600 ease-in-out inline-block rounded inline-block hover:bg-red-500"
                        @click="edit(item)"
                        v-if="currentlyEdited !== item.nodeId"
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                        </svg>
                      </div> -->
                      <Button type="danger" size="sm2" class=""
                        v-if="currentlyEdited !== item.nodeId"
                        @click.native="deleteAlias(item.nodeId)">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-3 w-3" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                      </Button>
                    </td>
                  </tr>

                  <!-- More items... -->
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import PaginationTemplate from '../components/PaginationTemplate.vue';

export default {
  data() {
    return {
      moment,
      // items: [
      //   {
      //     date: new Date(),
      //     operation: 'Credits added',
      //     amount: 100,
      //     balance: 100,
      //   },
      //   {
      //     date: new Date(),
      //     operation: 'Article generated',
      //     amount: -12,
      //     balance: 88,
      //   },
      // ],
      activeTab: 'general',
      copy: {},
      loading: false,
      audioPreviewUrl: null,
      loadingPlay: false,
      currentlyEdited: null,
      showForm: false,
      pageCount: 0,
      pageSize: 25,
      page: 1,
      offset: 0,
      selectedOrder: 'CREATED_AT',
      orderDirString: 'DESC',
      options: {
        template: PaginationTemplate,
        edgeNavigation: true,
      },
      inputModel: {
        alias: '',
        originalText: null,
        feedIds: null,
        // language: 'en-US',
      },
      contentVoice: {},
      searchQuery: '',
      searchQueryRecommended: '',
      languages: [
        {
          id: 0,
          name: 'All',
          code: 'all',
        },
        {
          id: 1,
          name: 'Polish',
          code: 'pl-PL',
        },
        {
          id: 2,
          name: 'English',
          code: 'en-US',
        },
      ],
    };
  },
  computed: {
    ...mapState({
      customerList: (state) => state.admin.customerList,
      selectedWebsite: (state) => state.website.selectedWebsite,
      userId: (state) => state.auth.userId,
      totalCount: (state) => state.alias.count,
      hasNextPage: (state) => state.alias.hasNextPage,
      items: (state) => state.alias.list,
      customer: (state) => state.auth.customer,
      userProfile: (state) => state.auth.userProfile,
      voices: (state) => state.utils.voiceList,
      templates: (state) => state.website.selectedWebsitePodcastTemplates,
      selectedWebsiteLanguage: (state) => state.website.selectedWebsiteLanguage,
    }),
    isActive() {
      return (tabName) => this.activeTab === tabName;
    },
    feedId() {
      return this.selectedWebsite.id;
    },
    voiceId() {
      return (item) => {
        const kind = item.ttsVoiceKind ? `-${item.ttsVoiceKind}` : '';
        return `${item.ttsVoiceName}-${item.mainLanguage}${kind}`;
      };
    },
    voiceItemById() {
      return (voiceSettings) => {
        console.log('xxx');
        return this.voices.find((el) => {
          console.log('xxx');
          // const newId = `${el.id}-${el.isHQ}`;
          // const isHQ = voiceSettings.ttsVoiceEngine === 'neural';
          // const voiceSettngsId = `${voiceSettings.ttsVoiceName}-${isHQ}`;
          return this.voiceId(el) === this.voiceId(voiceSettings);
        }) || {};
      };
    },
    languageName() {
      return (code) => {
        const [lang] = this.languages.filter((el) => el.code === code);
        if (!lang) return { name: '' };
        return lang;
      };
    },
    orderByString() {
      return `${this.selectedOrder}_${this.orderDirString}`;
    },
    defaultTemplate() {
      if (!this.templates || this.templates.length === 0) return { episodeTemplatePartsByEpisodeTemplateId: {} };
      // console.log('templates', this.templates);
      return this.templates[0].episodeTemplateByEpisodeTemplateId || {};
    },
    templeParts() {
      return this.defaultTemplate.episodeTemplatePartsByEpisodeTemplateId.nodes || [];
    },
    contentPart() {
      return this.templeParts.find((el) => el.displayName === 'Content');
    },
    queryParams() {
      const input = {
        first: 25,
        orderBy: this.orderByString,
        offset: this.offset,
        condition: {
          feedIds: this.selectedWebsite.id,
        },
      };

      if (this.searchQuery) {
        input.condition.originalText = {
          _: this.searchQuery,
        };
      }

      return input;
    },
    isNegative() {
      return (item) => item.creditsAfter - item.creditsBefore;
    },
  },
  created() {
    this.clearState();
  },
  watch: {
    activeTab() {
      this.searchQuery = '';
    },
    // eslint-disable-next-line
    searchQuery: function () {
      // console.log('sdasdsad', this.searchQuery);
      const refresh = _.debounce(this.refresh, 1150);
      refresh();
    },
    // eslint-disable-next-line
    customer: function () {
      // this.clearState();
      this.loadData(this.page);
    },
    // eslint-disable-next-line
    contentPart: function(newVal) {
      this.contentVoice = newVal.settings;
    },
    selectedWebsite() {
      // console.log('no website details', val);
      this.clearState();
      this.loadData(1);
    },
  },
  async mounted() {
    this.clearState();
    await this.loadVoices();
    this.loadData(1);
  },
  methods: {
    ...mapActions({
      fetchCustomer: 'customer/fetchCustomer',
      fetch: 'alias/fetch',
      createAlias: 'alias/createAlias',
      delete: 'alias/deleteAlias',
      playAlias: 'alias/playCustomer',
      fetchVoice: 'utils/fetchVoices',
    }),
    ...mapMutations({
      clearState: 'billing/clearState',
    }),
    async deleteAlias(id) {
      const result = await this.delete(id);
      console.log('deleteAlias result', result);
      this.refresh();
    },
    async loadVoices() {
      if (!this.selectedWebsiteLanguage) return;
      if (this.selectedWebsiteLanguage === 'en-EN') this.selectedWebsiteLanguage = 'en-US';
      await this.fetchVoice(this.selectedWebsiteLanguage);
    },
    async addAlias() {
      const input = this.inputModel;
      input.customerId = this.customer;
      input.feedIds = this.feedId;
      // input.language = this.selectedWebsite.language;
      input.voice = {
        mainLanguage: this.contentVoice.mainLanguage,
        ttsPlatform: this.contentVoice.ttsPlatform,
        ttsVoiceName: this.contentVoice.ttsVoiceName,
      };
      const result = await this.createAlias(input);

      console.log('create alias result', result);
      this.refresh();
      this.cancelAddForm();
    },
    cancelAddForm() {
      this.inputModel = {
        alias: '',
        origianlText: null,
        // language: 'en-US',
      };
      this.showForm = false;
    },
    edit(item) {
      // console.log('edit', item);
      this.copy = JSON.parse(JSON.stringify(item));
      this.currentlyEdited = item.nodeId;
    },
    cancelEdit(item) {
      let el = item;
      el = this.copy;
      console.log(el);
      this.currentlyEdited = null;
    },
    goTo(path) {
      this.$router.push({ path });
    },
    showAddForm() {
      this.inputModel = {
        alias: '',
        originalText: null,
        // language: 'en-US',
      };
      this.showForm = true;
    },
    async orderBy(type) {
      this.loading = true;
      if (type === this.selectedOrder) {
        this.orderDir = !this.orderDir;
        this.selectedOrder = type;
        await this.loadData(this.page);
        this.loading = false;
        return;
      }
      this.orderDir = false;
      this.selectedOrder = type;
      await this.loadData(this.page);
      this.loading = false;
    },

    async refresh() {
      this.loadData(this.page);
    },

    async loadData(page) {
      this.loading = true;
      if (!this.feedId) return;
      this.offset = page * this.pageSize - this.pageSize;

      // console.log('page', page, this.queryParams);
      await this.fetch(this.queryParams);
      this.loading = false;
    },

    // player stuff
    async play(item) {
      this.loadingPlay = item.nodeId;

      const result = await this.playAlias(item);

      this.loadingPlay = false;
      if (!result.ok) {
        this.$swal({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          icon: 'error',
          title: this.$t('ui.somethingWentWrong'),
        });
        return;
      }
      // this.playing = 0;
      this.audioPreviewUrl = result.url;
      const audio = this.$refs.player;
      // console.log('audio', audio);

      // audio.attr('src', item.url);
      audio.load(); // call this to just preload the audio without playing
      audio.play(); // call this to play the song right away
    },
  },
};
</script>
