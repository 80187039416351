/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';

const state = {
  name: 'Auth',
  userSession: null,
  userEmail: 'null',
  isLoggedIn: false,
  userName: '',
  userGroups: [],
  customerId: null,
  customer: null,
  userRole: '',
  isAdmin: false,
  isSuperAdmin: false,
  userId: null,
  userProfile: {},
  userCustomer: {},
  credits: 0,
};

const getters = {
  userAccessToken: (state) => {
    const doesUserSessionExist = !!state.userSession;
    if (!doesUserSessionExist) return null;
    return state.userSession.accessToken;
  },

  idTokenPayload: (state, getters) => {
    const doesPayloadExist = !!getters.userIdToken && !!getters.userIdToken.payload;
    if (!doesPayloadExist) return null;
    return getters.userIdToken.payload;
  },

  userIdToken: (state) => {
    const doesUserSessionExist = !!state.userSession;
    if (!doesUserSessionExist) return {};
    return state.userSession.idToken;
  },

  userEmail: (state, getters) => {
    const hasPayload = !!getters.idTokenPayload;
    return hasPayload ? getters.idTokenPayload.email : '';
  },
};

const mutations = {
  setUserCustomer: (state, payload) => {
    Vue.set(state, 'userCustomer', payload);
    Vue.set(state, 'credits', payload.credits);
  },
  setUserProfile: (state, payload) => {
    Vue.set(state, 'userProfile', payload);
    Vue.set(state, 'credits', payload.customerByCustomerId.credits);
  },
  setSession: (state, payload) => {
    Vue.set(state, 'userSession', payload);
    const doesIdTokenPayloadExist = !!payload.idToken && !!payload.idToken.payload;
    Vue.set(state, 'isLoggedIn', true);
    if (!doesIdTokenPayloadExist) return;
    localStorage.setItem('token', payload.accessToken.jwtToken);
    Vue.set(state, 'userEmail', payload.idToken.payload.email);
    Vue.set(state, 'userId', payload.idToken.payload.sub);
    Vue.set(state, 'customer', payload.idToken.payload.customer);
    Vue.set(state, 'userGroups', payload.accessToken.payload['cognito:groups']);
    if (!state.userGroups) {
      return;
    }
    state.userGroups.forEach((item) => {
      const isCustomer = item.startsWith('customer:');
      const isRole = item.startsWith('role:');

      if (isCustomer) {
        Vue.set(state, 'customerId', item);
      }
      if (isRole) {
        Vue.set(state, 'userRole', item.substr(5, item.length));
      }
    });
    const isAdmin = state.userGroups.indexOf('role:hm_admin') > -1;
    const isSuperAdmin = state.userGroups.indexOf('role:super-admin') > -1 || state.userGroups.indexOf('role:hm_su') > -1;
    Vue.set(state, 'isAdmin', isAdmin);
    Vue.set(state, 'isSuperAdmin', isSuperAdmin);
  },

  setIsLoggedIn: (state, payload) => {
    Vue.set(state, 'isLoggedIn', payload);
  },

  setUserName: (state, payload) => {
    Vue.set(state, 'userName', payload);
  },
};

const actions = {
  async fetchUser({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchUser, {
          id: payload,
        }),
      );
      commit('setUserProfile', response.data.userById);
      return response.data.userById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { error: true, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async updateUser({ commit }, payload) {
    // console.log('fetchUser', payload);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.UpdateProfile, {
          input: payload,
        }),
      );
      commit('setUserName', response.data.updateUserById);
      return { ok: true, data: response.data.updateUserById };
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomer({ commit }, payload) {
    // // console.log('fetchCustomer AUTH', payload);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const { customerById } = response.data;
      commit('setUserCustomer', customerById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(customerById);
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
