/* eslint-disable no-shadow */
import Vue from 'vue';
import { API, graphqlOperation } from 'aws-amplify';
import GQL from '../graphql';
import ADMIN_GQL from '../admingql';

const state = {
  name: 'Admin',
  customerList: [],
  couponList: [],
  userList: [],
  userListById: {},
  regionList: [],
  hasNextPage: true,
  pageInfo: {},
  totalCount: 0,
  listGlobalAliases: [],
  listGlobalAliasRecommendations: [],

  userCustomer: {},
  credits: 0,
  additionalCredits: 0,
  customerWebsiteList: [],
  customerMetadata: {},
  couponStripeList: [],
  billingDetails: {},
  loadings: {},
  combinedCredits: 0,
  subscriptionStatus: {},

  subscriptions: null,
  subscriptionAddOns: {},
  invoices: [],
  packages: [],
};

const mutations = {
  setPackages: (state, payload) => {
    Vue.set(state, 'prices', payload);
    const subscriptions = {
      Premium: {
        month: {},
        year: {},
      },
      Business: {
        month: {},
        year: {},
      },
      Basic: {
        month: {},
        year: {},
      },
      Starter: {
        month: {},
        year: {},
      },
      Enterprise: {
        month: {},
        year: {},
      },
    };
    const subscriptionAddOns = {
      Premium: {},
      Business: {},
      Basic: {},
      Enterprise: {},
    };
    const packages = [];
    const packageNames = ['Premium', 'Starter', 'Basic', 'Business', 'Enterprise'];
    const pricingGroups = payload
      .filter((price) => price.active && price.product.active && !!price.recurring) // filter out inactive and not recurring
      .sort((a, b) => a.unit_amount - b.unit_amount) // sort by price (TODO: remove and split it in to mo/yo packages)
      .reduce((groups, item) => ({
        ...groups,
        [item.product.name]: [...(groups[item.product.name] || []), item],
      }), {}); // group by name
    payload
      .filter((price) => price.active)
      .filter((price) => price.product.active)
      // .filter((price) => price.metadata.used === 'true')
      .filter((price) => {
        const { product, metadata } = price;
        // const { metadata } = product;

        if (product.metadata.plan && metadata.interval) {
          subscriptions[product.metadata.plan][metadata.interval] = price;
          if (product.metadata.plan !== 'Basic') packages.push(price);
        }

        // console.log('%cprice', 'color: lime', product.name, metadata.interval, product.metadata.plan, product);
        return packageNames.indexOf(metadata.plan) > -1;
      })
      .forEach((price) => {
        const {
          product,
          id,
          recurring,
          metadata,
        } = price;
        const isSubscription = !!recurring;
        if (!isSubscription) return;

        const { name } = product;

        console.log('price => ', isSubscription, `${name} (${product.metadata.plan})`, metadata.interval, price.recurring.interval, id, price);
      });

    // Add-ons
    payload
      .filter((price) => price.active && price.product.active)
      .filter((price) => packageNames.indexOf(price.product.metadata.plan) > -1)
      .filter((price) => price.product.metadata.type === 'Add-on')
      .forEach((price) => {
        const { product } = price;
        console.log('add on => ', price, product);
        subscriptionAddOns[product.metadata.plan] = price;
      });
    console.log('%csubs: ', 'color: orange', subscriptions, subscriptionAddOns, pricingGroups);

    // TODO: fix this garbage
    // [subscriptions.Premium.month, subscriptions.Premium.year] = pricingGroups.Premium;
    // [subscriptions.Basic.month, subscriptions.Basic.year] = pricingGroups.Basic;
    // [subscriptions.Business.month, subscriptions.Business.year] = pricingGroups.Business;
    // [subscriptions.Enterprise.month, subscriptions.Enterprise.year] = pricingGroups.Enterprise;

    Vue.set(state, 'subscriptions', subscriptions);
    Vue.set(state, 'subscriptionAddOns', subscriptionAddOns);
    Vue.set(state, 'packages', packages);
    // Vue.set(state, 'subscriptionAddOns', subscriptionAddOns);
  },
  setGlobalAliases: (state, payload) => {
    const list = payload.map((el) => {
      // console.log('el', el);
      const newEl = {
        ...el,
      };
      if (!el.platforms) newEl.platforms = [];
      return newEl;
    });
    Vue.set(state, 'listGlobalAliases', list);
  },
  setGlobalAliasRecomendations: (state, payload) => {
    Vue.set(state, 'listGlobalAliasRecommendations', payload);
  },

  setGlobalSettings: (state, payload) => {
    const settings = {};
    payload.forEach((el) => {
      settings[el.key] = el.value;
    });
    Vue.set(state, 'globalSettings', settings);
  },
  setSubscriptionStatus: (state, payload) => {
    Vue.set(state, 'subscriptionStatus', payload);
  },
  setCustomerList: (state, payload) => {
    Vue.set(state, 'customerList', payload);
    // console.log('payload', state.customerList, payload);
  },
  setCouponList: (state, payload) => {
    Vue.set(state, 'couponList', payload);
  },
  setCouponStripeList: (state, payload) => {
    Vue.set(state, 'couponStripeList', payload);
  },
  setUserList: (state, payload) => {
    Vue.set(state, 'userList', payload);
    payload.forEach((user) => Vue.set(state.userListById, user.id, user));
  },
  setRegionList: (state, payload) => {
    Vue.set(state, 'regionList', payload);
  },
  setLoadings: (state, payload) => {
    Vue.set(state.loadings, payload.type, payload.value);
  },
  setCount: (state, payload) => {
    Vue.set(state, 'totalCount', payload);
  },
  setPageInfo: (state, payload) => {
    Vue.set(state, 'hasNextPage', payload.hasNextPage);
    Vue.set(state, 'pageInfo', payload);
  },
  clearPagin: (state) => {
    Vue.set(state, 'hasNextPage', true);
    Vue.set(state, 'pageInfo', {});
  },
  setSelectedCustomer: (state, payload) => {
    Vue.set(state, 'userCustomer', payload);
    Vue.set(state, 'credits', payload.credits);
    Vue.set(state, 'additionalCredits', payload.additionalCredits);
    Vue.set(state, 'customerMetadata', payload.metadata);
    Vue.set(state, 'billingDetails', payload.metadata.billingDetails);
    const combinedCredits = parseInt(payload.credits, 10) + parseInt(payload.additionalCredits, 10);
    Vue.set(state, 'combinedCredits', combinedCredits);
  },
  setCustomerWebsites: (state, payload) => {
    Vue.set(state, 'customerWebsiteList', payload);
  },
  dummy: (state, payload) => {
    console.log(state, payload);
  },
};

const actions = {
  // payment and packages
  async fetchPrices({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetStripePricesAndProducts),
      );
      const { getStripePricesAndProducts } = response.data;
      commit('setPackages', getStripePricesAndProducts.prices);
      // console.log('fetchHistory', payload, nodes);
      return getStripePricesAndProducts;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },
  async getCustomerSubscriptionStatus({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GetSubscriptionStatusForAdmin, {
          input: {
            customerId: payload,
          },
        }),
      );
      const { getSubscriptionStatusForAdmin } = response.data;
      commit('setSubscriptionStatus', getSubscriptionStatusForAdmin);
      // console.log('fetchHistory', payload, nodes);
      return getSubscriptionStatusForAdmin;
    } catch (error) {
      // console.log('fetchHistory', payload, error);
      return { ok: false, message: error };
    }
  },
  async generateCoupon({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.GenerateCoupon, {
          input: {
            creditAmount: payload.amount,
            expirationDate: payload.expiryDate,
          },
        }),
      );
      const { generateCoupon } = response.data;
      return generateCoupon;
    } catch (error) {
      return { ok: false, message: error.errors };
    }
  },
  async disableEnableUser({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(ADMIN_GQL.DisableEnableUser, {
          input: payload,
        }),
      );
      const { disableEnableUser } = response.data;
      return disableEnableUser;
    } catch (error) {
      return { ok: false, message: error.err };
    }
  },
  async disableEnablePreview({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(ADMIN_GQL.PreviewGeneratingRestriction, {
          input: payload,
        }),
      );
      const { previewGeneratingRestriction } = response.data;
      return previewGeneratingRestriction;
    } catch (error) {
      return { ok: false, message: error.err };
    }
  },
  async addStripeCoupon({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.CreateCoupon, {
          input: payload,
        }),
      );
      const { createStripeCoupon } = response.data;
      return createStripeCoupon;
    } catch (error) {
      return { ok: false, message: error.err };
    }
  },
  async deleteStripeCoupon({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.DeleteCoupon, {
          input: {
            couponId: payload,
          },
        }),
      );
      const { createStripeCoupon } = response.data;
      return createStripeCoupon;
    } catch (error) {
      return { ok: false, message: error.err };
    }
  },
  async fetchCoupons({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllCoupons, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allCoupons;
      commit('setCouponList', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchStripeCoupons({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllStripeCoupons),
      );
      const { codes } = response.data.getStripeCodesAndCoupons;
      commit('setCouponStripeList', codes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return codes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, err: error };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchUsers({ commit }) {
    // console.log('fetch loadMore');
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllUsers),
      );
      const { nodes } = response.data.allUsers;
      commit('setUserList', nodes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomers({ commit }, { payload }) {
    // console.log('fetch loadMore', loadMore);
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllCustomers, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allCustomers;
      commit('setCustomerList', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomerWebsites({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.AllFeeds, { ...payload }),
      );
      const { nodes } = response.data.allFeeds;
      commit('setCustomerWebsites', nodes);
      // console.log('fetchCustomerWebsites', payload, response);
      // const doesExist = !!this._vm.$t('basic.delete1');
      // // console.log(nodes);
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchCustomer({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.FetchCustomer, {
          id: payload,
        }),
      );
      const responseStatus = await API.graphql(
        graphqlOperation(GQL.GetSubscriptionStatusForAdmin, {
          input: {
            customerId: payload,
          },
        }),
      );
      const { getSubscriptionStatusForAdmin } = responseStatus.data;
      commit('setSubscriptionStatus', getSubscriptionStatusForAdmin);
      const { customerById } = response.data;
      commit('setSelectedCustomer', customerById);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return customerById;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchGlobalAliases({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(ADMIN_GQL.AllTtsAliasesGlobals, payload),
      );
      const { nodes, pageInfo, totalCount } = response.data.allTtsAliasesGlobals;
      commit('setGlobalAliases', nodes);
      commit('setCount', totalCount);
      commit('setPageInfo', pageInfo);
      return nodes;
    } catch (error) {
      return { ok: false, message: error };
    }
  },
  async fetchGlobalAliasRecommendations({ commit }) {
    console.log('fetch loadMore');
    try {
      console.log('fetch loadMore');
      const response = await API.graphql(
        graphqlOperation(ADMIN_GQL.GetGlobalAliasRecommendation),
      );
      const { data } = response.data.getGlobalAliasRecommendations;
      commit('setGlobalAliasRecomendations', data);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return data;
    } catch (error) {
      console.log('fetch loadMore w', error);
      // this._vm.$message.error('Can`t get websites');
      // console.log('err', error, payload);
      return { ok: false, message: error.errors };
    }
    // finally {
    //  commit('setLoadings', { type: 'websites', value: false });
    // }
  },
  async fetchGlobalSettings({ commit }) {
    try {
      const response = await API.graphql(
        graphqlOperation(ADMIN_GQL.AllGlobalSettings),
      );
      const { nodes } = response.data.allGlobalSettings;
      commit('setGlobalSettings', nodes);
      // const doesExist = !!this._vm.$t('basic.delete1');
      return nodes;
    } catch (error) {
      // this._vm.$message.error('Can`t get websites');
      // console.log('err--------------------', error, payload);
      return { ok: false, message: error.errors };
    }
  },
  async playGlobal({ commit }, payload) {
    try {
      const response = await API.graphql(
        graphqlOperation(GQL.PlayGlobalAlias, {
          input: payload,
        }),
      );
      const { data } = response;
      return data.playGlobalAlias;
    } catch (error) {
      return { ok: false, message: error.errors };
    } finally {
      setTimeout(() => {
        commit('setLoadings', { type: 'coupon', value: false });
      }, 300);
    }
  },
  async updateGlobalSetting({ commit }, payload) {
    commit('setLoadings', { type: 'coupon', value: true });
    try {
      const response = await API.graphql(
        graphqlOperation(ADMIN_GQL.UpdateGlobalSettingsByKey, {
          input: payload,
        }),
      );
      const { globalSetting } = response.data;
      return globalSetting;
    } catch (error) {
      return { ok: false, message: error.err };
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
