<template>
  <div class="bg min-h-screen">
    <header class="py-8">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 xl:flex xl:items-center xl:justify-between">
        <div class="flex-1 min-w-0">
          <h1 class="mt-2 text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-center">
            <span v-if="isTrial || !isSubscriptionActive">{{$t('ui.creditsPlansTitle')}}</span>
            <span v-if="isSubscriptionActiveNotTrial">{{$t('ui.creditsAddOnTitle')}}</span>
          </h1>
          <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
            <!-- Header details -->
          </div>
        </div>
        <div class="mt-5 flex xl:mt-0 xl:ml-4">
          <!-- Header Actions -->
          <!-- {{subscriptionStatus}} -->
        </div>
      </div>
    </header>

    <div class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-56 -mt-8">
      <div class="relative" v-if="isTrial || !isSubscriptionActive">
        <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8 flex flex-col">
          <div class="relative mt-6 bg-white border-2 border-black w-auto rounded-lg p-0.5 flex self-center sm:mt-8 ">
            <button type="button" class="relative bg-black border border-transparent rounded-md py-2 w-1/2 text-sm font-medium text-white whitespace-nowrap focus:outline-none sm:w-auto px-3 sm:px-8"
            :class="{'text-white bg-black': isMonthly === 'month', 'text-black bg-white': isMonthly === 'year'}"
            @click="isMonthly = 'month'">Monthly billing</button>
            <button type="button" class="ml-0.5 relative border border-transparent rounded-md py-2 w-1/2 text-sm font-medium  whitespace-nowrap focus:outline-none sm:w-auto px-3 sm:px-8"
            :class="{'text-white bg-black': isMonthly === 'year', 'text-black bg-white': isMonthly === 'month'}"
            @click="isMonthly = 'year'">Yearly billing</button>
          </div>
        </div>
        <div class=" mt-6 space-y-4 sm:mt-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 max-w-5xl mx-auto xl:grid-cols-3"
          :class="{'opacity-50 pointer-events-none': loading}">
          <Package name="Basic" :package="subscriptions.Basic" :desc="$t('packages.basicDesc')" :features="[
            { name: 'packageUnlimited', value: ['unlimited'] },
            { name: 'packageCharacterLimit', value: ['100 000'] },
            { name: 'extraPackagePrice', value: ['2.69', '10 000'] },
          ]" symbol="$" :isMonthly="isMonthly">
            <Button
              class="block w-full"
              :loading="loading"
              @click.native="buyPackage(subscriptions.Basic[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packageBasic')}}</Button>
          </Package>
          <Package name="Business" :popular="true" :package="subscriptions.Business" :desc="$t('packages.businessDesc')" :features="[
            { name: 'packageUnlimited', value: ['unlimited'] },
            { name: 'packageCharacterLimit', value: ['250 000'] },
            { name: 'extraPackagePrice', value: ['1.95', '10 000'] },
          ]" symbol="$" :isMonthly="isMonthly">
            <Button
              type="brand"
              class="block w-full"
              :loading="loading"
              @click.native="buyPackage(subscriptions.Business[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packageBusiness')}}</Button>
          </Package>
          <Package name="Premium" :package="subscriptions.Premium" :desc="$t('packages.premiumDesc')" :features="[
            { name: 'packageUnlimited', value: ['unlimited'] },
            { name: 'packageCharacterLimit', value: ['100 000'] },
            { name: 'extraPackagePrice', value: ['0.99', '10 000'] },
          ]" symbol="$" :isMonthly="isMonthly">
            <Button
              class="block w-full"
              :loading="loading"
              @click.native="buyPackage(subscriptions.Premium[isMonthly])">{{$t('ui.buy')}} {{$t('ui.packagePremium')}}</Button>
          </Package>
        </div>
      </div>
      <div v-if="isSubscriptionActiveNotTrial" class="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 pb-56">
        <div class="text-black border-2 border-black rounded-xl bg-white relative flex justify-between p-6 mt-10">
          <div>
            <div class="text-xl font-bold">{{$t('ui.creditsAddOnTitle')}}</div>
            <div class="text-sm font-normal">{{$t('ui.creditsAddOnDesc', [displayPrice(subscriptionAddOn.unit_amount)])}}</div>
          </div>
          <div class="flex">
            <Button
                @click.native="buyPackage(subscriptionAddOn)"
                class=""
                >{{$t('ui.buy')}}</Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SimpleVueValidation from 'simple-vue-validator';

const { Validator } = SimpleVueValidation;

export default {
  data() {
    return {
      items: '',
      couponCode: null,
      loading: false,
      error: false,
      errorMessage: false,
      success: false,
      isMonthly: 'month',
      showPackageComparision: false,
    };
  },
  validators: {
    // eslint-disable-next-line
    couponCode: (value) => Validator.value(value).required(),
  },
  computed: {
    ...mapState({
      customerId: (state) => state.auth.customer,
      prices: (state) => state.billing.prices,
      subscriptions: (state) => state.billing.subscriptions,
      subscriptionStatus: (state) => state.billing.subscriptionStatus,
      subscriptionAddOns: (state) => state.billing.subscriptionAddOns,
      selectedWebsite: (state) => state.website.selectedWebsite,
    }),
    isSubscriptionActiveNotTrial() {
      return this.subscriptionStatus && this.subscriptionStatus.active && this.subscriptionStatus.plan !== 'Trial';
    },
    isSubscriptionActive() {
      return this.subscriptionStatus && this.subscriptionStatus.active;
    },
    isTrial() {
      return this.subscriptionStatus && this.subscriptionStatus.plan === 'Trial';
    },
    subscriptionPlan() {
      return this.subscriptionStatus.plan;
    },
    subscriptionPrice() {
      if (!this.subscriptions[this.subscriptionPlan]) return {};
      return this.subscriptions[this.subscriptionPlan][this.subscriptionStatus.interval];
    },
    subscriptionAddOn() {
      return this.subscriptionAddOns[this.subscriptionPlan];
    },
    symbol() {
      return this.$t(`currency.${this.currentSubPrice.currency}`);
    },
    currentSubPrice() {
      if (!this.subscriptionStatus.plan) return { currency: 'usd', product: { name: '', metadata: { chars_monthly: '' } } };
      if (this.subscriptionStatus.plan === 'Trial') return { currency: 'free', product: { name: 'Trial', metadata: { chars_monthly: '24 000' } } };
      const subName = this.subscriptionStatus.plan;
      const subInterval = this.subscriptionStatus.interval;
      return this.subscriptions[subName][subInterval];
    },
    displayPrice() {
      return (unitAmount) => {
        const price = unitAmount / 100;
        const isInt = Number.isInteger(price);
        const priceString = isInt ? `${this.symbol}${price}.00` : `${this.symbol}${price}`;
        return priceString;
      };
    },
  },
  async mounted() {
    this.loading = true;
    if (!this.subscriptions.Premium) await this.fetchPrices();
    console.log('subs => ', this.subscriptions);
    this.loading = false;
  },
  methods: {
    ...mapActions({
      redeemCoupon: 'billing/redeemCoupon',
      fetchCustomer: 'customer/fetchCustomer',
      updatePlayerSettings: 'settings/updatePlayerSettings',
      fetchPrices: 'billing/fetchPrices',
      getPaymentLink: 'billing/getPaymentLink',
    }),
    async buyAddOn(addon) {
      this.loading = true;
      console.log('buyaddon', addon);
      const { product, id } = addon;
      const domain = `https://${window.location.host}/articles/${this.selectedWebsite.id}/list`;
      const data = {
        customer: this.customerId,
        domain,
        productId: product.id,
        priceId: id,
      };

      // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
      // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

      const result = await this.getPaymentLink(data);
      if (!result.url) return;
      window.location.replace(result.url);
      this.loading = false;
      console.log('payment link result', data, result);
    },
    async buyPackage(plan) {
      this.loading = true;
      console.log('buyplan', plan);
      const { product, id } = plan;
      const domain = `https://${window.location.host}`;
      const data = {
        customer: this.customerId,
        domain,
        productId: product.id,
        priceId: id,
      };

      // productId: 'prod_LQa3i4OjR8n2bP', // product.id,
      // priceId: 'price_1KjitfK1ORtygzBgpwdhbSTW', // id,

      const result = await this.getPaymentLink(data);
      if (!result.url) return;
      window.location.replace(result.url);
      this.loading = false;
      console.log('payment link result', data, result);
    },
    // async useCoupon(e) {
    //   e.preventDefault();
    //   const isValid = await this.$validate();
    //   if (!isValid) return;
    //   this.loading = true;
    //   const result = await this.redeemCoupon(this.couponCode);
    //   this.loading = false;
    //   if (!result.ok) {
    //     const [error] = result.message;

    //     this.error = true;
    //     this.errorMessage = this.$t(`backend.${error.message}`);
    //     setTimeout(() => { this.error = false; }, 10000);
    //     return;
    //   }

    //   this.couponCode = null;
    //   await this.fetchCustomer(this.customerId);

    //   this.success = true;
    //   setTimeout(() => { this.success = false; }, 4000);

    //   // console.log(this.couponCode);
    // },
  },
};
</script>
<style scoped>
.bg {
  background-image: url("../assets/images/add-blog-bg.png");
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}
</style>
